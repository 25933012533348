import {showAlertAndHideAfterSeconds} from "../components/magic-link/helpers/alert";
import {resendByExpiredMagicLink, sendMagicLink} from "../components/magic-link/helpers/api";
import {getEmailSessionStorage, getMagicTokenSessionStorage} from "../components/magic-link/helpers/session-storage";
import debounce from "just-debounce-it";
import {bugsnagClientNotify} from "@bark/bark-core";

export const LoginLinkSentPage = async () => {

    const email = getEmailSessionStorage();
    if (!email) window.location.href = '/login/send-link/';
    const urlParams = new URLSearchParams(window.location.search);

    //functions
    const debouncedSendMagicLink = debounce(async () => {
        const mgk = urlParams.get('mgk');
        let magicLinkResponse;
        if (mgk) {
            magicLinkResponse = await resendByExpiredMagicLink(mgk);
        } else {
            const magicTokenSessionStorage = getMagicTokenSessionStorage();
            if (magicTokenSessionStorage) {
                magicLinkResponse = await resendByExpiredMagicLink(magicTokenSessionStorage);
            } else {
                magicLinkResponse = await sendMagicLink(email);
            }
        }
        if (magicLinkResponse?.status) {
            showAlertAndHideAfterSeconds('.alert-link-resent', 2);
        } else {
            bugsnagClientNotify('Magic Link resend not able to send');
        }
    }, 1000);

    //listeners
    document.querySelector('.resent-link').addEventListener("click", async (e) => {
        e.preventDefault();
        debouncedSendMagicLink();
    });

    //page setup
    document.querySelector('#email-display').innerHTML = email;

}
