import { changePasswordModal } from './change-password-modal';

export const showChangePasswordModal = () => {
  const userShowPasswordModal = window.Bark?.USER?.showChangePassword;
  const hasDismised = window.Bark.get_cookie('skippedcspmodal') === '1';
  const hasfinished = window.Bark.get_cookie('finishedcspmodal') === '1';
  return userShowPasswordModal && !hasDismised && !hasfinished;
}

export const runPasswordModal = () => {
  const passwordModal = changePasswordModal();
  passwordModal.setState({'loginMethod': 'magic_link', 'trackingGroup': 'Seller - Login'});
  passwordModal.defineInteractiveElements();
  passwordModal.bindClickHandlers();
  passwordModal.bindChangeHandlers();
  passwordModal.showModal();
}

