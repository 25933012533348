export const showLoading = (submitElement) => {
  const btnSubmitElement = submitElement;
  const btnLoadingElement = submitElement.closest('.form-container').find('.btn-loading');
  btnSubmitElement.addClass('d-none');
  btnLoadingElement.removeClass('d-none');
};
export const hideLoading = (submitElement) => {
  const btnSubmitElement = submitElement;
  const btnLoadingElement = submitElement.closest('.form-container').find('.btn-loading');
  btnSubmitElement.removeClass('d-none');
  btnLoadingElement.addClass('d-none');
};