const events = {
	loaded: 'TranslationsReady',
};

export function fireTranslationsReadyEvent() {
	document.dispatchEvent(	new Event(events.loaded, {
        bubbles: true,
        cancelable: true,
        composed: false,
    }));
}
