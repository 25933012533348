export function QueryStringUtil() {
    var instanceVars = {
        original: {},
        current: {},
        _raw: function() {
            return location.search.replace(/\?/, '');
        },
        _dict: function() {
            var dict = {};
            this._raw().split('&').forEach(function(part) {
                var keyValue = part.split('=');
                var key = keyValue[0];
                var value = (keyValue.length > 1) ? keyValue[1] : null;
                dict[key] = value;
            });
            return dict;
        },
        _value: function(key) {
            return this.current[key];
        },
        hasKey: function(key) {
            if (typeof this._value(key) === 'undefined') {
                return false;
            }
            return true;
        },
        getValue: function(key, defaultValue) {
            if (this.hasKey(key)) {
                return this._value(key);
            }
            return defaultValue;
        },
        deleteValue: function(key, updateBrowser) {
            if (typeof updateBrowser === 'undefined') {
                updateBrowser = true;
            }
            if (this.hasKey(key)) {
                delete this.current[key];
                if (updateBrowser) {
                    this.updateBrowserQuerystring();
                }
            }
        },
        setValue: function(key, value, updateBrowser) {
            this.current[key] = value;
            if (typeof updateBrowser === 'undefined') {
                updateBrowser = true;
            }
            if (updateBrowser) {
                this.updateBrowserQuerystring();
            }
        },
        reset: function() {
            var original = this.original;
            this.current = JSON.parse(JSON.stringify(original));
        },
        updateBrowserQuerystring: function() {
            window.history.pushState('', '', '?' + this.toRaw());
        },
        toRaw: function() {
            var current = this.current;
            var rawArray = [];
            Object.keys(current).forEach(function(key){
                var value = current[key];
                if (value === null) {
                    rawArray.push(key);
                } else {
                    rawArray.push(key + '=' + value);
                }
            });
            return rawArray.join('&');
        }
    };

    Object.assign(this, instanceVars);

    this.original = this._dict();
    this.reset();

    return this;
}
