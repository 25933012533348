import i18n from 'i18next';
import i18nextHttpBackend from 'i18next-xhr-backend';
import { fireTranslationsReadyEvent } from '../components/events/translation-events';

export const _l = (url) => url;
export const _t = i18n.t;

export const useTranslation = async (namespace, i18nInstance) => {
	i18nInstance = i18nInstance ?? window.i18n;
	if (namespace.length > 0 && i18nInstance) {
		await i18nInstance.loadNamespaces(namespace);
	}
};

// This function is dirty and must be used after Bark Core Init, otherwise i18n will not exist and it will do nothing
export const useNamespace = async (namespace) => {
	await i18n.loadNamespaces(namespace);
};

export const loadNamespace = async (i18nInstance, key) => {
	const namespace = key.substring(0, key.indexOf(':'));
	if (namespace.length > 0) {
		await i18nInstance.loadNamespaces(namespace);
	}
};

i18n.on('missingKey', (missingKey) => {
	console.error('We are missing the following key: ', [], [missingKey]);
});

export async function initI18n(
	loadPath = '/translations/{{lng}}/{{ns}}.json',
	defaultLanguage = 'en-GB',
) {
	const i18nInstance = i18n.use(i18nextHttpBackend);

	if ('BARK_WEB_ENV_STAGE' !== 'local') {
		loadPath =
			'BARK_WEB_ENV_TRANSLATIONS_CDN' + '/' + 'BARK_WEB_ENV_BUILD_NUMBER' + loadPath;
	}

	window.useTranslation = useTranslation;

	await i18nInstance.init({
		backend: { loadPath },
		fallbackLng: defaultLanguage,
		ns: ['common', 'common_email-validator'],
		lng: `${window.Bark.ENV.language}`,
		load: 'currentOnly',
        debug: false
	});

	exposeI18nAndTranslateFunctionToWindow(i18nInstance);

	return i18nInstance;
}

function exposeI18nAndTranslateFunctionToWindow(i18nInstance) {
	window._t = i18nInstance.t;
	// placeholder for the url translator
	window._l = _l;
	window.i18n = i18nInstance;
	window.useTranslation = useTranslation;
	window.useNamespace = useNamespace;

	fireTranslationsReadyEvent();
}
