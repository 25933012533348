import { tracking } from "../../../analytics";

export default class TenderBrowseExperiment {
    state = {
        isActive: false,
        variant: null,
        variantData: null,
        categoryName: null,
        providerNoun: null,
        preBarkId: null,
        categoryId: null,
    }

    variants = {
        1: {
            handlebarsId: 'tenderbrowse-variant-1',
            displayBottomSection: false,
            // twig: 'perfect-match.twig' //(for reference only)
        },
        2: {
            handlebarsId: 'tenderbrowse-variant-2',
            displayBottomSection: true,
            bottomSectionHandlebarsId: 'tenderbrowse-variant-2-bottomsection',
            // twig: 'most-interested.twig'
        },
        3: {
            handlebarsId: 'tenderbrowse-variant-3',
            displayBottomSection: false,
            // twig: 'comparing-quotes.twig'
        },
    }

    trackedEvents = {}


    getVariantHTML(variant, categoryName = "professionals", providerNoun = "professional", findMeAProString = "Find me a professional", providerNounPlural = "professionals")  {
        // Add new variants in:
        // templates/buyer/tenderbrowse-experiment/all.twig, and in this.variants
        if(variant && this.variants[variant]) {
            let compiled = window.Handlebars.compile($(`#${this.variants[variant].handlebarsId}`).html());
            return compiled({
                categoryName,
                providerNoun,
                findMeAProString,
                providerNounPlural,
            });
        } else {
            console.error(`Asked for a tenderbrowse variant (${variant}) which is unsupported.`)
            return "";
        }
    }

    getBottomSectionHTML(variant) {
        if(variant && this.variants[variant] && this.variants[variant].displayBottomSection) {
            let compiled = window.Handlebars.compile($(`#${this.variants[variant].bottomSectionHandlebarsId}`).html());
            return compiled({});
        } else {
            console.error(`Asked for a tenderbrowse variant bottom section (${variant}) which is unsupported.`)
            return "";
        }
    }

    getVariantData(variant, categoryName = "professionals", providerNoun = "professional", findMeAProString = "Find me a professional", providerNounPlural = "professionals") {
        if(!variant) {
            return false;
        }
        if(!this.variants[variant]) {
            return false;
        }

        let variantData = this.variants[variant];
        variantData.variantHtml = this.getVariantHTML(variant, categoryName, providerNoun, findMeAProString, providerNounPlural);
        if(variantData.displayBottomSection) {
            variantData.bottomSectionHtml = this.getBottomSectionHTML(variant);
        }

        return variantData;
    }

    track(eventName, extraData) {
        let eventGroup = 'Browse-list';
        tracking(eventGroup, `TenderBrowse - ${eventName}`, {
            pre_bark_id: this.state.preBarkId,
            category_id: this.state.categoryId,
            ...extraData
        })
    }

    getNotAvailableHtml() {
        let compiled = window.Handlebars.compile($(`#tenderbrowse-not-available`).html());
        return compiled({});
    }

    getAreYouSureHtml() {
        let compiled = window.Handlebars.compile($(`#tenderbrowse-are-you-sure`).html());
        return compiled({});
    }

    setIsActive(active = true) {
        this.state.isActive = active;
    }

    setPreBarkId(preBarkId) {
        this.state.preBarkId = preBarkId ?? null;
    }

    setCategoryId(categoryId) {
        this.state.categoryId = categoryId ?? null;
    }

    setVariant(variant) {
        this.state.variant = variant;
        this.state.variantData = this.variants[variant];
    }

    getIsActive() {
        return this.state.isActive;
    }

    getShouldShowBottomContainer() {
        return this.state?.variantData?.displayBottomSection ?? false;
    }

}
