export default class Switch {
	constructor(containerId, elementId, hbTemplate, eventEmitter) {
		this.containerId = containerId;
		this.elementId = elementId;
		this.hbTemplate = hbTemplate;
		this.eventEmitter = eventEmitter;
	}

	draw() {
		$(`#${this.containerId}`).empty();
		let switchHtml = this.hbTemplate({ elementId: this.elementId });
		$(`#${this.containerId}`).append(switchHtml);

		this.bindListeners();
	}

	bindListeners() {
		let that = this;
		$(`#${this.elementId}`).change(function () {
			let currentValue = $(this).is(':checked');
			that.eventEmitter.emit(`switch.${that.elementId}.changed`, {
				checked: currentValue,
			});
		});
	}

	isChecked() {
		return $(`#${this.elementId}`).is(':checked');
	}
}
