export function SendAppInitEvent(appType) {
    try {
        let event = document.createEvent('Event');
        event.initEvent('coreInit', true, true);
        event.appType = appType;
        // let older legacy scripts know they can execute
        window.dispatchEvent(event);
    } catch (e) {
        let metaData = {appType: appType};
        try {
            metaData = {
                coreInitEvent: window.coreInitEvent,
                coreInitEventType: typeof window.coreInitEvent,
                onBarkCoreInitType: typeof window.onBarkCoreInit
            }
        } catch (err) {window.bugsnagClient && window.bugsnagClient.notify(err)}
        window.bugsnagClient && window.bugsnagClient.notify(e, {metaData: metaData});
    }
}
