const Bark = (window.Bark = window.Bark || {});
const cb = (Bark.createBark = Bark.createBark || {});
import { tracking, startBark, _t } from '@bark/bark-core';

const getHeroComponent = () => {
	return $('.m-hero-question');
};

export const hasHeroQuestionComponent = () => {
	return getHeroComponent().length > 0;
};

export const initHeroQuestion = () => {
	console.log('Hero question start...');
	const isHeroQuestionComponentOnPage = getHeroComponent();
	setupListeners();
	if (isHeroQuestionComponentOnPage) {
		getHeroComponent()
			.find('.bark-start-journey')
			.on('click', () => {
				startJourneyClicked();
				tracking('Hero Question Click', 'Start Journey from Button Click', {});
			});
		getHeroComponent()
			.find('input.bark-page-question')
			.on('click', () => {
				hideError();
			});
	}
};

function setupListeners() {
	trustpilotListeners();
	inputListeners();
	closeModalListeners();
}

function closeModalListeners() {
	$('#inlineBarkModal').on('hidden.bs.modal', function () {
		inputListeners();
	});
}

function isQuestionValid() {
	const questionChecked =
		getHeroComponent().find('input.bark-page-question:checked').length > 0;

	return questionChecked && isQuestionOtherValid();
}

function isQuestionOtherActive() {
	const questionOtherExist =
		getHeroComponent().find('input.bark-page-other-question').length > 0;
	const questionOtherChecked =
		getHeroComponent().find('input#page-q-other:checked').length > 0;

	return questionOtherExist && questionOtherChecked;
}

function isQuestionOtherValid() {
	const questionOtherHasValue =
		isQuestionOtherActive() &&
		getHeroComponent().find('input.bark-page-other-question').val();

	return isQuestionOtherActive() ? questionOtherHasValue : true;
}

function showError() {
	const errorText = isQuestionOtherValid()
		? _t('directory_contentful-pages:hero-section.errors.not-selected')
		: _t('directory_contentful-pages:hero-section.errors.other-value-not-given');
	const errorElement = getHeroComponent().find('.error');

	errorElement.html(errorText).removeClass('hide');
}

function hideError() {
	getHeroComponent().find('.error').addClass('hide');
}

function startJourneyClicked() {
	if (isQuestionValid()) {
		hideError();
		startBark();
		removeInputListeners();
	} else {
		showError();
	}
}

function trustpilotListeners() {
	const trustpilotButton = getHeroComponent().find('.trustpilot-button');
	if (trustpilotButton.length > 0) {
		trustpilotButton.on('click', function () {
			tracking('Page Click', 'trustpilot - hero', {});
		});
	}
}
function inputListeners() {
	const inputItems = getHeroComponent().find('.inline-bark-q');
	// other
	getHeroComponent()
		.find('input.bark-page-other-question')
		.on('focus', function () {
			getHeroComponent().find('input#page-q-other').prop('checked', true);
		});
	// input area
	if (inputItems.length > 0) {
		inputItems.off('click').on('click', function (e) {
			e.stopPropagation();
			e.preventDefault();
			// dont set for other input box
			if ($(e.target).closest('input#page-q-other').length > 0) {
				return false;
			}
			// toggle input
			const input = $(this).find('input.bark-page-question');
			const inputChecked = input.prop('checked');
			input.prop('checked', !inputChecked);

			if (input.hasClass('bark-click-start-journey')) {
				if (Bark.isMobile()) {
					console.log('On mobile, so ignored');
					return;
				}
				startJourneyClicked();
				tracking('Hero Question Click', 'Start Journey from Question Click', {});
			}
		});
	}
}
function removeInputListeners() {
	const inputItems = getHeroComponent().find('.inline-bark-q');
	if (inputItems.length > 0) {
		inputItems.off('click');
	}
}
