import { barkApi, bugsnagClientNotify } from "@bark/bark-core";

export const sendMagicLink = async (email) => new Promise( resolve => {
  barkApi(
        `auth/get-mgk`,
        {
            email,
        },
        (res) => {
          resolve(res);
        },
        (error) => {
            if(error?.status === 422){
                resolve(false);
            } else {
                console.error(error);
                bugsnagClientNotify('sendMagicLink not successful', error);
                resolve(false);
            }
        },
        'POST'
    );
});

export const resendByExpiredMagicLink = async (magicToken) => new Promise( resolve => {
    barkApi(
        `auth/get-mgk/resend/`,
        {
            mgk: magicToken,
        },
        (res) => {
            resolve(res);
        },
        (error) => {
            console.error(error);
            bugsnagClientNotify('sendMagicLink not successful', error);
            resolve(false);
        },
        'POST'
    );
});
