export const AutofillPostcode = (inputId) => {

    //if seller that hasn't switched, do nothing
    if ($("a[href='/sellers/switch/']")[0]) {
        return null;
    }

    if ($("#postcode-auto").val() && inputId == '#postcode-auto') {
        return null;
    }

    if (window.URLSearchParams) {
        let searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('locid')) {
            return null;
        }
    }

    window.Bark.api(
        "/buyer/last-location",
        {
            coid: window.Bark.ENV.ccid
        },
        function (e) {
            if (e.data && e.status && e.status === true) {
                $("#postcode_id").val(e.data.id);
                $("#postcode_type").val(e.data.type);
                $(inputId).val(e.data.postcode);
                $(inputId).data({selected: e.data});
            }
        },
        function (err) {
            var message =
                "error with autofilling postcode location: ";
            if (err.error && err.error.message)
                message += " - " + err.error.message;
            window.bugsnagClient &&
            window.bugsnagClient.notify(
                new Error(message)
            );
        },
        "POST",
        window.Bark.apiVersionHeader("v1")
    );
}
