export default function () {
    var heightAnimationSpeed = 200;
    var heightAnimationTimeout;

    /**
     * Set the height of the container when going from parent to child to parent
     * @param {boolean} forSecondPage
     */
    function setHeight(forSecondPage) {
        var container = $('nav .js-explore-items-container');
        var newHeight;
        var padding = parseFloat($('nav .js-second-page').css('paddingTop'));

        if (forSecondPage) {
            container.height(container.height());
            newHeight = (padding * 2) + $('nav .js-second-page-inner').height();
        } else {
            newHeight = padding + $('nav .js-first-page')[0].scrollHeight;
        }

        container.height(newHeight);

        if (!forSecondPage) {
            window.clearTimeout(heightAnimationTimeout);
            heightAnimationTimeout = window.setTimeout(function () {
                $('nav .js-explore-items-container').height('auto');
            }, heightAnimationSpeed);
        }
    }

    function closeExplore () {
        var t = $('nav .js-trigger-explore');
        var isHidden;
        var container = $('nav .js-explore-items-container');
        var animationSpeed = 100;
        var windowMobileWidth = 480;

        if (t.hasClass('animating')) {
            return;
        }

        isHidden = container.hasClass('d-none');

        if (isHidden) {
            // Hidden, so show it
            $.post('/api/elo/', {c: 'directory_explore', n: 'open'});

            container.removeClass('d-none')

            if ($(window).width() <= windowMobileWidth) {
                // If mobile width, the mobile menu is being displayed .:. scroll top
                if (window.scrollY) {
                    animationSpeed = 0;
                }
            }

            $('html').addClass('explore-open');

            t.addClass('animating');
            container.css({opacity: 0}).finish().animate({opacity: 1}, animationSpeed, 'linear', function () {
                t.removeClass('animating');
            });

            $('html,body').off('click.exploreClose').on('click.exploreClose', function (e) {
                if ($(e.target).closest('.js-explore-container').length) {
                    return;
                }

                $('nav .js-trigger-explore').click();
            });
        } else {
            // Visible, so hide it
            $.post('/api/elo/', {c: 'directory_explore', n: 'close'});

            $('html').removeClass('explore-open');

            t.addClass('animating');
            container.finish().animate({opacity: 0}, animationSpeed, 'linear', function () {
                t.removeClass('animating');
                container.addClass('d-none').removeClass('highlighting-category');
                $('html,body').off('click.exploreClose')
                $('nav .js-explore-items-container').height('auto');
            });
        }
    }

    $(function () {
        $('nav .js-trigger-explore').off('click.trigger').on('click.trigger', closeExplore);

        $('.js-close-mobile-explore').off('click.close').on('click.close', closeExplore);

        $('nav .js-explore-pseudo-link').off('click.pseudoNav').on('click.pseudoNav', function (e) {
            var categoryNameUrl;

            e.preventDefault();

            categoryNameUrl = $(e.currentTarget).data('category');

            $.post('/api/elo/', {c: 'directory_explore', n: 'show_more', i: categoryNameUrl});

            $('.js-explore-category-container').addClass('d-none');
            $('.js-explore-category-' + categoryNameUrl).removeClass('d-none');
            $('.js-explore-items-container').addClass('highlighting-category');
            setHeight(true);

            return false;
        });

        $('nav .js-explore-back-to-main').off('click.backNav').on('click.backNav', function () {
            $('.js-explore-items-container').removeClass('highlighting-category');
            setHeight();
            $.post('/api/elo/', {c: 'directory_explore', n: 'show_main'});
        });

        $('nav .js-xrt').off('click.xrt').on('click.xrt', function () {
            var data = $(this).data();

            if (!data.n) {
                return;
            }

            $.post('/api/elo/', {c: 'directory_explore', n: data.n, i: data.i || null});
        });

    });
}
