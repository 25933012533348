import {
    getAnswers as getNewRequestFlowAnswers, getNewRequestState,
    isNewRequestFlow
} from "../../new-request-flow/components/create-bark/new-request-state";
import {getCreateBark} from "./general";

export const getPageSkipQuestionsForState = () => {
    let pageSkipQuestions = {};
    if (isNewRequestFlow() || isBrowseResumeFlow()) {
        pageSkipQuestions = getNewRequestFlowAnswers();
    } else {
        pageSkipQuestions = getCreateBark().getPageSkippedQuestions();
    }
    return pageSkipQuestions;
}

const isBrowseResumeFlow = () => {
    const state = getNewRequestState();
    return state.flow === 'browse-resume-flow';
}
