import { isChameleonActive } from './check';
import { tracking as heapTracking } from '../../analytics/bark-tracking';

export const sendChameleonAndHeapTrackEvent = async (name, extraProperties = {}) => {
  if (!isChameleonActive()) return null;

  const trackingProperties = {
    ...extraProperties,
  };

  chmln.track(name, {
    ...trackingProperties,
  });

  heapTracking('Chameleon Event', name, {
    ...trackingProperties,
  });

};