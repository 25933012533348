class TrackingAbstract {
	// eslint-disable-next-line no-unused-vars
	trackEvent(eventGroup, eventName, parameters) {}
}

export class TrackingHeap extends TrackingAbstract {
	trackEvent(eventGroup, eventName, parameters) {
		let heapCustomEventName = `${eventGroup} - ${eventName}`;

		parameters['Event Group'] = eventGroup;
		parameters['Event Name'] = eventName;
		window.heap && window.heap.track(heapCustomEventName, parameters);
	}
}

export class BarkTracking {
	constructor(trackingInterface) {
		this._trackingInterface = trackingInterface;
	}

	logCustomEvent(eventGroup, eventName, parameters) {
		this._trackingInterface.trackEvent(eventGroup, eventName, {
			...parameters,
			besToken: window?.Bark?.ENV?.bes_token,
		});
	}
}

export const tracking = (eventGroup, eventName, parameters) => {
	const tracking = new BarkTracking(new TrackingHeap());

	return tracking.logCustomEvent(eventGroup, eventName, parameters);
};
