import { useNamespace } from '@bark/bark-core';

export default async function SellerPublicProfilePage() {
	// This is some legacy jQuery files atm. By importing them it is telling it to execute because
	await useNamespace([
		'seller_profile',
		'public_seller-profile',
		'seller_public-profile',
		'common_contact-seller-modal',
		'buyer_seller-list_request-quote',
		'buyer_seller-request-quote',
	]);

	const {
		SellerReviewsJs,
		exposeBrowseProjectFunctionsToWindow,
		PriorityNavManagerLib,
		ContactSellerModal,
	} = await import('@bark/bark-core');
	await import('../helpers/external-scripts/lightgallery');

	SellerReviewsJs();
	PriorityNavManagerLib();

	const {
		CheckAvailabilityModal,
		MessageSellerModal,
		TenderUpsellModal,
		SellerProfileJs,
	} = await import('../components/seller-public-profiles');

	const { bindRequestFromSellerProfileEvents, isRequestFromSellerProfileActive } =
		await import('../components/seller-public-profiles/features/request-from-profile');

	exposeBrowseProjectFunctionsToWindow();
	ContactSellerModal({ hasActiveProject: false });
	CheckAvailabilityModal();
	MessageSellerModal();
	TenderUpsellModal();
	await SellerProfileJs();

	if (isRequestFromSellerProfileActive()) {
		bindRequestFromSellerProfileEvents();
	}

	if (window.browseFunctions.getBlid()) {
		const { initLegacyCreateBark } = await import('@bark/bark-core');
		await initLegacyCreateBark();
	}
}
