import { PubSub } from '../../helpers/pubsub';
import { _t, _l } from '../../libs/i18next';
import moment from 'moment';

export function ActivityLog(context, perPage) {
	const { Handlebars, linkifyHtml, bugsnagClient, Bark } = window;

	function TemplateItem(hash) {
		// inherit from Item
		Item.call(this, hash);
		let instanceVars = {
			process: () => {
				let emailViewerType = this.context === 'seller' ? 'sellers' : 'buyers';
				let $link = $('<a>', {
					href:
						'/' +
						emailViewerType +
						_l('/messenger/view-email/') +
						this.getAdditionalDataValue('mail_hash') +
						'/',
					target: '_blank',
				}).text(_t('common_activity-log:view-link'));
				this.content = this.content.replace(
					'[view_sent_email_link]',
					$link.get(0).outerHTML,
				);
			},
		};
		Object.assign(this, instanceVars);
	}

	function FileItem(hash) {
		// inherit from Item
		Item.call(this, hash);
		let instanceVars = {
			process: () => {
				let $link = $('<a>', {
					href: this.getAdditionalDataValue('file_url'),
					target: '_blank',
					download: this.getAdditionalDataValue('file_name'),
					title: this.getAdditionalDataValue('file_name'),
					class: 'download-link text-dark-blue text-underline',
				}).text(this.getAdditionalDataValue('file_name_truncated'));
				let $viewLink = $link
					.clone()
					.removeClass('text-dark-blue')
					.text(_t('common_activity-log:view-link'));
				this.content = this.content.replace(
					'[filename_sent_file_link]',
					$link.get(0).outerHTML,
				);
				this.content = this.content.replace(
					'[view_sent_file_link]',
					$viewLink.get(0).outerHTML,
				);
			},
		};
		Object.assign(this, instanceVars);
	}

	function ProjectReleasedItem(hash) {
		// inherit from Item
		Item.call(this, hash);
		let instanceVars = {
			process: () => {
				let $link = $('<a>', {
					href: '#',
					class: 'show-project-details',
					'data-project-id': this.getAdditionalDataValue('project_id'),
				}).text(_t('common_activity-log:view-details-link'));
				this.content = this.content.replace(
					'[view_details_link]',
					$link.get(0).outerHTML,
				);
			},
		};
		Object.assign(this, instanceVars);
	}

	function SentQuoteItem(hash) {
		// inherit from Item
		Item.call(this, hash);
		let instanceVars = {
			process: () => {
				const quoteLinkPosition = this.content.indexOf('[update_quote_link]');
				if (quoteLinkPosition > 0) {
					// Only included for sellers
					// Wrap the quote amount
					this.content = Bark.sprintf(
						'<span class="formatted-quote"><span class="quote">%s</span></span>%s',
						this.content.substr(0, quoteLinkPosition),
						this.content.substr(quoteLinkPosition),
					);
					// Add the update link
					let $link = $('<a>', {
						href: '#',
						class: 'quote-link text-underline ml-2',
						'data-project-id': this.getAdditionalDataValue('project_id'),
					}).text(_t('common_activity-log:update-link'));
					this.content = this.content.replace(
						'[update_quote_link]',
						$link.get(0).outerHTML,
					);
				}
			},
		};
		Object.assign(this, instanceVars);
	}

    function ReminderItem(hash) {
        // inherit from Item
        Item.call(this, hash);
        let instanceVars = {
            setMessage: (message) => {
                const triggerAt = moment.utc(this.getAdditionalDataValue('trigger_at', 'YYYY-MM-DD HH:mm:SS')).local();
                const triggerAtTime = triggerAt.format('HH:mm');
                let triggerAtDate = null;
                switch (triggerAt.clone().startOf('day').diff(moment().startOf('day'), 'days')) {
                    case -1:
                        triggerAtDate = _t("common_activity-log:reminder.dates.yesterday");
                        break;
                    case 0:
                        triggerAtDate = _t("common_activity-log:reminder.dates.today");
                        break;
                    case 1:
                        triggerAtDate = _t("common_activity-log:reminder.dates.tomorrow");
                        break;
                    default:
                        triggerAtDate = _t("common:date", {value: triggerAt, formatParams: {
                            value: { weekday: 'short', day: 'numeric', month: 'short' }
                        }}).replace(',', '');
                }
                this.message = message.replace('[trigger_at_date]', triggerAtDate).replace('[trigger_at_time]', triggerAtTime);
            },
            process: () => {
                const reminderLinkPosition = this.content.includes('[edit_reminder_link]');
                if (reminderLinkPosition !== -1) {
                    // Add the edit reminder link
                    const $editlink = $('<a>', {
                        href: '#',
                        class: 'edit-reminder text-underline ml-2',
                        'data-reminder-id': this.getAdditionalDataValue('reminder_id'),
                        'data-trigger-at': this.getAdditionalDataValue('trigger_at'),
                    }).text(_t('common_activity-log:edit-reminder-link'));
                    this.content = this.content.replace(
                        '[edit_reminder_link]',
                        $editlink.get(0).outerHTML,
                    );
                }

                const removeReminderLinkPosition = this.content.indexOf('[remove_reminder_link]');
                if (removeReminderLinkPosition !== -1) {
                    // Add the delete reminder link
                    const $deletelink = $('<a>', {
                        href: '#',
                        class: 'remove-reminder text-underline ml-2',
                        'data-reminder-id': this.getAdditionalDataValue('reminder_id'),
                    }).text(_t('common_activity-log:remove-reminder-link'));
                    this.content = this.content.replace(
                        '[remove_reminder_link]',
                        $deletelink.get(0).outerHTML,
                    );
                }
            },
        };
        Object.assign(this, instanceVars);
    }

    function CalledItem(hash) {
        // inherit from Item
        Item.call(this, hash);
        let instanceVars = {
            process: () => {
                const reminderLinkPosition = this.content.includes('[create_reminder_link]');
                if (reminderLinkPosition !== -1) {
                    const createlink = $('<a>', {
                        href: '#',
                        class: 'create-reminder text-underline ml-2',
                        'data-project-response-action-id': this.getAdditionalDataValue('project_response_action_id'),
                    }).text(_t('common_activity-log:create-reminder-link'));
                    this.content = this.content.replace(
                        '[create_reminder_link]',
                        createlink.get(0).outerHTML,
                    );
                }
            },
        };
        Object.assign(this, instanceVars);
    }

	function Item(hash) {
		let instanceVars = {
			hash: null,
			actor: {},
			icon: {},
			date: null,
			message: '',
			content: '',
			additionalData: {},
			context: '',
			init: (hash) => {
				this.hash = hash;
			},
			setActor: (actor) => {
				this.actor = actor;
			},
			setIcon: (icon) => {
				this.icon = icon;
			},
			setDate: (timestamp) => {
				this.date = moment.unix(timestamp).local();
			},
			getStartOfDate: () => this.date.clone().startOf('day'),
			setMessage: (message) => {
				this.message = message;
			},
			setContent: (content) => {
				this.content = content;
			},
			setAdditionalData: (additionalData) => {
				this.additionalData = additionalData;
			},
			setContext: (context) => {
				this.context = context;
			},
			getHash: () => {
				return this.hash;
			},
			getAdditionalDataValue: (key) => {
				if (key in this.additionalData) {
					return this.additionalData[key];
				}
				return null;
			},
			getTemplateData: (index) => ({
				first: index === 0,
				hash: this.hash,
				icon: this.icon,
				actor: this.actor,
				date: this.date.format('HH:mm'),
				message: this.message,
				content: nl2br(this.content),
			}),
			process: () => {
				this.content = linkifyHtml(this.content, {
					format: function (value, type) {
						if (type === 'url') {
							// Use JS internals to parse the URL, and only include the hostname and the path
							var a = document.createElement('a');
							// If the link does not start with https?, add a leading slash
							a.href = value.match(/^https?|ftp/) ? value : '//' + value;
							// If there is a pathname, append it, normalizing for a potentially missing leading slash, and removing any trailing slashes
							value = (
								a.hostname + (a.pathname ? a.pathname.replace(/(^\/?)/, '/') : '')
							).replace(/\/$/, '');
						}
						return value;
					},
				});
			},
		};

		Object.assign(this, instanceVars);
		this.init(hash);
	}

	function BarkConnectScheduledCallItem(hash) {
		// inherit from Item
		Item.call(this, hash);
		let instanceVars = {
			process: () => {
				let $link = $('<a>', {
					href: '#',
					class: 'delete-scheduled-call ml-2',
					html: `<u>${_t('common_activity-log:cancel-link')}</u>`,
					'data-call-hash': this.getAdditionalDataValue('scheduled_call_hash'),
				});
				this.content = this.content.replace(
					'[scheduled_call_delete_link]',
					$link.get(0).outerHTML,
				);
			},
		};
		Object.assign(this, instanceVars);
	}

	function BarkConnectCallItem(hash) {
		Item.call(this, hash);

		let instanceVars = {
			process: () => {
				if (this.content) {
					let $link = $('<span>', {
						class: 'js-reattempt-call pseudo-link ml-2',
						html: `<u>${_t('common_activity-log:call-again-link')}</u>`,
						'data-bark-connect': JSON.stringify(this.additionalData),
					});
					let fullContent = $('<span>', { class: 'bark-connect-call-info' });
					fullContent.html(
						this.content.replace('[call_again_link]', $link.get(0).outerHTML),
					);

					this.content = fullContent[0].outerHTML;
				}
			},
		};
		Object.assign(this, instanceVars);
	}

	function ItemFactory(rawItem, context) {
		let itemObj = null;
		switch (rawItem.type) {
			case 'project_released':
				itemObj = ProjectReleasedItem;
				break;
			case 'sent_template':
				itemObj = TemplateItem;
				break;
			case 'sent_file':
				itemObj = FileItem;
				break;
			case 'sent_quote':
				itemObj = SentQuoteItem;
				break;

			case 'bark_connect_scheduled_call':
				itemObj = BarkConnectScheduledCallItem;
				break;

			case 'bark_connect_call':
				itemObj = BarkConnectCallItem;
				break;
            case 'reminder':
                itemObj = ReminderItem;
                break;
            case 'called':
                itemObj = CalledItem;
                break;
            case 'reminder_completed':
                itemObj = null;
                break;
			default:
				itemObj = Item;
		}
		if (!itemObj) {
			return null;
		}
		let item = new itemObj(rawItem.hash);
		item.setAdditionalData(rawItem.additional_data);
		item.setActor(rawItem.actor);
		item.setIcon(rawItem.icon);
		item.setMessage(rawItem.message);
		item.setDate(rawItem.timestamp);
		item.setContent(rawItem.content);
		item.setContext(context);
		item.process();
		return item;
	}

	let instanceVars = {
		placeholderId: 'activity-log',
		containerId: 'activity-log-container',
		itemContainerId: 'activity-log-items-container',
		logTemplate: 'activity-log-template',
		loadMoreLinkClass: 'load-more-items-link',
		logTemplateData: {
			loadMoreText: _t('common_activity-log:show-more'),
		},
		itemTemplates: {
			seller: 'seller-item',
			buyer: 'buyer-item',
		},
		dateTemplate: 'date-item-activity-log-template',
		responseId: null,
		context: null,
		items: [],
		meta: {
			total: null,
			count: null,
			perPage: 5,
			nextHash: false,
		},
		itemRenderer: null,
		dateRenderer: null,
		fetchActive: false,
        rendererCallback: null,
		init: (context, perPage) => {
			this.context = context;
			this.loadRenderer();
			if (typeof perPage !== 'undefined') {
				this.meta.perPage = perPage;
			}
			this.bindListeners();
		},
		reset: () => {
			if (this.fetchActive) {
				this.fetchActive.abort();
			}
			this.items = [];
			this.setMeta({
				total: null,
				count: null,
				perPage: this.meta.per_page,
				nextHash: false,
			});
			this.lastProcessedDate = null;
			this.fetch(null, false);
		},
		load: (responseId) => {
			this.responseId = responseId;
			this.reset();
		},
        setRendererCallback: (rendererCallback) => {
		    this.rendererCallback = rendererCallback;
        },
		loadRenderer: () => {
			let rendererName = this.itemTemplates[this.context] + '-activity-log-template';
			this.itemRenderer = Handlebars.compile(
				document.getElementById(rendererName).innerHTML,
			);
			this.dateRenderer = Handlebars.compile(
				document.getElementById(this.dateTemplate).innerHTML,
			);
		},
		setMeta: (meta) => {
			this.meta = {
				total: meta.total,
				count: meta.count,
				perPage: meta.per_page,
				nextHash: meta.next_hash,
			};
		},
		fetch: (nextHash, renderItems) => {
			this.startWaiting();
			let params = {
				context: this.context,
				per_page: this.meta.perPage,
			};
			if (typeof nextHash !== 'undefined' && nextHash) {
				params['next_hash'] = nextHash;
			}
			this.fetchActive = window.Bark.api(
				'response/' + this.responseId + '/log/',
				params,
				(data) => {
					this.setMeta(data.meta);
					this.makeItems(data.data);
					if (renderItems) {
						this.renderItems();
					}
					this.finishWaiting();
					this.fetchActive = false;
					this.fireEvent('fetch_complete');
				},
				(error, exception) => {
					if (exception === 'abort') {
						return false;
					}
					bugsnagClient.notify(error);
					this.finishWaiting();
					this.fetchActive = false;
				},
				'GET',
				window.Bark.apiVersionHeader('v2'),
			);
		},
		makeItems: (rawItems) => {
			$.each(rawItems, (index, rawItem) => {
                const item = ItemFactory(rawItem, this.context);
                if (item){
                    this.items.push(item);
                }
			});
		},
		nextPage: () => {
			if (!this.meta.nextHash) {
				return false;
			}
			this.fetch(this.meta.nextHash, true);
			return true;
		},
		getContainer: () => {
			return $('#' + this.containerId);
		},
		getItemContainer: () => {
			return $('#' + this.itemContainerId);
		},
		isRendered: () => {
			return this.getContainer().length > 0;
		},
		render: (responseId) => {
			if (!this.isRendered()) {
				let logRenderer = Handlebars.compile(
					document.getElementById(this.logTemplate).innerHTML,
				);
				let $placeholder = $('#' + this.placeholderId);
				$placeholder.empty();
				$placeholder.html(logRenderer(this.logTemplateData));
				this.bindListeners();
			}
			if (
				typeof responseId !== 'undefined' &&
				parseInt(this.responseId) !== parseInt(responseId)
			) {
				this.load(responseId);
				this.fetch(null, true);
			} else {
				this.renderItems();
			}
		},
		redraw: () => {
			this.reset();
			$('#' + this.placeholderId).empty();
			this.render();
		},
		renderItems: () => {
			if (this.fetchActive) {
				setTimeout(this.renderItems, 100);
				return false;
			}
			let $container = this.getItemContainer();
			let newRenderedItems = [];
			$.each(this.items, (i, item) => {
				if (
					this.lastProcessedDate === null ||
					item.getStartOfDate().isBefore(this.lastProcessedDate)
				) {
					this.lastProcessedDate = item.getStartOfDate();
					newRenderedItems.push(
						this.dateRenderer({
							first: i === 0,
							date: _t("common:date", {value: this.lastProcessedDate, formatParams: {
								value: { weekday: 'short', day: 'numeric', month: 'short' }
							}}).replace(',', '')
						}),
					);
				}
				if ($container.find('[data-hash="' + item.getHash() + '"]').length === 0) {
					let itemHtml = item.getTemplateData(i);
					newRenderedItems.push(this.itemRenderer(itemHtml));
				}
			});
			$container.append(newRenderedItems);
			this.clampItems();
			this.checkMoreItems();
		},
		clampItems: () => {
			$.each($('.activity-log-item .item-content p'), (index, contentEl) => {
				let needClamp = contentEl.scrollHeight > contentEl.clientHeight;
				let $parent = $(contentEl).parent();
				let alreadyExpanded = $parent.hasClass('expanded');
				$parent.removeClass('expanded').removeClass('clamped');
				if (needClamp) {
					if (alreadyExpanded) {
						$parent.addClass('expanded');
					}
					$parent.addClass('clamped');
				}
			});
		},
		checkMoreItems: () => {
			let $link = $('.' + this.loadMoreLinkClass);
			if (this.meta.nextHash) {
				$link.removeClass('d-none');
			} else {
				$link.addClass('d-none');
			}
		},
		startWaiting: () => {
			this.getContainer().addClass('waiting');
		},
		finishWaiting: () => {
			this.getContainer().removeClass('waiting');
		},
		bindListeners: () => {
			this.getContainer().on('click', '.' + this.loadMoreLinkClass, (e) => {
				e.preventDefault();
				this.nextPage();
			});
			this.getContainer().on('click', '.show-more-link', (e) => {
				e.preventDefault();
				$(e.currentTarget).parent().addClass('expanded').removeClass('clamped');
			});
			this.getContainer().on('click', '.show-less-link', (e) => {
				e.preventDefault();
				$(e.currentTarget).parent().addClass('clamped').removeClass('expanded');
			});
			this.getContainer().on('click', '.show-project-details', (e) => {
				e.preventDefault();
				this.fireEvent('show_project_details', $(e.currentTarget).data('project-id'));
			});

			if (window.Bark.BarkConnect) {
				this.getContainer()
					.off('click.retryBarkConnect')
					.on('click.retryBarkConnect', '.js-reattempt-call', (e) => {
						let t = $(e.currentTarget);

						e.preventDefault();

						window.Bark.BarkConnect.init();
						window.Bark.BarkConnect.triggerBarkConnect(t.data('barkConnect'));
					});

				this.getContainer()
					.off('click.deleteScheduledCall')
					.on('click.deleteScheduledCall', '.delete-scheduled-call', (e) => {
						let t = this;

						e.preventDefault();

						Bark.showLoading();
						Bark.api(
							'bark-connect/scheduled-call/' + $(e.currentTarget).data('call-hash'),
							{},
							() => {
								Bark.hideLoading();
								t.redraw();
							},
							() => {
								Bark.hideLoading();
							},
							'DELETE',
						);
					});
			}
            this.rendererCallback && this.rendererCallback();
		},
	};

	Object.assign(this, instanceVars, PubSub);

	this.init(context, perPage);

	return this;
}
