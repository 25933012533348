import {tracking} from "../../../analytics";
import {getCreateBark} from "../../helpers/general";

export function getBrowseIdentifierFromCookie() {
    const blid = $('#bl-id').val();
    return  blid ?? Bark.get_cookie('blid');
}

export function setBrowseCookie(identifier) {
    window.Bark.set_cookie('blid', identifier, 0, '/');
}

export function clearBrowseCookie() {
    window.Bark.clear_cookie('blid', '/');
}

export function getProfessionalsForBrowse(success, failure, payload = {})   {
    const identifier = getBrowseIdentifierFromCookie();
    Bark.api(
        `/customer_requests/${identifier}/suggestions`,
        payload,
        (data) => {
            // data will be the seller list
            success && success(data);
        },
        (error) => {
            if (failure) {
                failure(error);
            } else {
                bugsnagClient.notify(new Error(`Failed to get browse seller list`), {
                    metaData: {
                        error,
                        identifier
                    }
                });
            }
        },
        "GET",
        Bark.apiVersionHeader('v1')
    );
}

export function requestQuoteAndCompleteBark(sellerProfileId)
{
    $('#inlineBarkModal').off('shown.bs.modal.tracking').on('shown.bs.modal.tracking', () => {
        browseTracking('Browse-list - request quote - enter-bark-modal');
    });
    resumeBarkModal((data) => {
        getCreateBark().BrowseProjectExperiment?.setInteractedSellerProfileId(sellerProfileId);
    });
}

export function resumeBarkModal(success, failure)
{
    const identifier = getBrowseIdentifierFromCookie();
    Bark.api(
        `/customer_requests/${identifier}`,
        {},
        (data) => {
            const response = data.data?.attributes;
            const {validateProject, answers} = JSON.parse(response.payload);
            $('#category_id').val(response.category_id);
            $('#postcode_id').val(response.location_id);
            $('#postcode_type').val(response.location_type);
            $('#inlineBarkModal').off('shown.bs.modal.focus').on('shown.bs.modal.focus', () => {
                const input = $('#inlineBarkModal :input:visible:first');
                input.length > 0 && input.focus();
            });
            $('#inlineBarkModal').off('hide.bs.modal.tracking').on('hide.bs.modal.tracking', () => {
                browseTracking('Browse-list - request quote - close');
            });
            getCreateBark().showModal({
                flow: 'browse-resume-flow',
                answers: answers,
                trackStartBark: false,
                data: validateProject,
            });
            success && success(data);
        },
        (error) => {
            if (failure) {
                failure(error);
            } else {
                bugsnagClient.notify(new Error(`Failed to get browse seller list`), {
                    metaData: {
                        error,
                        identifier
                    }
                });
            }
        },
        "GET",
        Bark.apiVersionHeader('v1')
    );
}

export function getProviderNounPlural() {
    return $('#provider-noun-plural').val();
}

export function browseTracking(name, params = {} ) {
    if (getBrowseIdentifierFromCookie()) {
        tracking('Browse-list', name, {browse_list_id: getBrowseIdentifierFromCookie(), ...params})
    }
}

export function exposeBrowseProjectFunctionsToWindow() {
    window.browseFunctions = {};
    window.browseFunctions.getBrowseProfessionalList = getProfessionalsForBrowse;
    window.browseFunctions.requestQuoteFromSeller = requestQuoteAndCompleteBark;
    window.browseFunctions.resumeBarkModal = resumeBarkModal;
    window.browseFunctions.getProviderNounPlural = getProviderNounPlural;
    // i dont think this will ever be required.
    window.browseFunctions.getBlid = getBrowseIdentifierFromCookie;
    window.browseFunctions.tracking = browseTracking;
}
