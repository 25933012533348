import { sendChameleonAndHeapTrackEvent } from './helpers/tracking';

export const trackLeadsPageSignUp = () => {
  const url = window.location.href;
  if(url.indexOf('new=org') > -1) {
    sendChameleonAndHeapTrackEvent('Leads page organic sign up');
  } else if(url.indexOf('new=pros-link') > -1){
    sendChameleonAndHeapTrackEvent('Leads page prospect link sign up');
  } else if(url.indexOf('new=pros-dashboard') > -1){
    sendChameleonAndHeapTrackEvent('Leads page prospect dashboard sign up');
  }
};

export const trackProjectHiredStatusChange = status => {
  sendChameleonAndHeapTrackEvent(`Project response status changed ${status}`);
};
