// bark core
import { init as initCore, initI18n } from "@bark/bark-core";

//router
import { routerUrl, routerDom } from "./router";

// run
export async function main() {
    // core functions
    await initCore();

    // router functions
    routerUrl();
    await routerDom();
}

//run main
main().then(() => {
    console.log("public app start..");
});
