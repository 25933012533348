export default function PaginationManager() {
    var paginationTemplate = false;
    var PAGES_AROUND = 2;
    // calculated as pages around current, plus current, start and end which always show
    var MAX_FULL_LIST_LENGTH = PAGES_AROUND * 2 + 3;
    var instancevars = {
        $pagination_template: $('#pagination-template'),
        generateNumbers: function(curr, total){
            var numbers = [];
            var t = this;
            var shortList = total <= MAX_FULL_LIST_LENGTH;
            if(shortList){
                for(var i=0; i<total; i++){
                    numbers.push({
                        label: '' + (i+1),
                        active: (i+1) === curr,
                        enabled: true
                    });
                }
            } else {
                var to_display = PAGES_AROUND * 2 + 1;
                var start = curr - PAGES_AROUND;
                if(start + to_display - 1 > total){
                    start =  total - to_display + 1;
                }
                start = start < 1 ? 1 : start;
                if(start > 1 ){
                    numbers.push(t.generateOneNumber(curr, total, 1));
                    if(start > 2){
                        numbers.push(t.generateOneNumber(curr, total, '...'));
                    }
                }
                for( var j = start;
                    j < Math.min(total + 1, start + to_display);
                    j++)
                {
                    numbers.push(t.generateOneNumber(curr, total, j));
                }
                // if we've displayed numbers and not reached the total yet
                if( j < total){
                    numbers.push(t.generateOneNumber(curr, total, '...'));
                    // and always show the last page
                    numbers.push(t.generateOneNumber(curr,total, total));
                }
                else if(numbers && numbers.length > 0 && numbers[numbers.length - 1].label !== '' + total){
                    numbers.push(t.generateOneNumber(curr,total, total));
                }
            }
            return numbers;
        },
        generateOneNumber: function (curr, total, number){
            return {
                label: '' + number,
                active: number === curr,
                enabled: number != '...'
            }
        },
        output: function(targetEl, totalPages, currentPage, callback, paginationId){
            if (typeof paginationId === 'undefined') {
                paginationId = false;
            }
            var t = this;
            if(!paginationTemplate){
                paginationTemplate = window.Handlebars.compile(t.$pagination_template.text());
            }
            var nums = t.generateNumbers(currentPage, totalPages);
            if(nums.length < 2){
                return;
            }
            var the_identifier = paginationId ? paginationId :  'pagination-' + $(targetEl).prop('id') + (Math.floor(Math.random() * 10000))
            $(targetEl).html(paginationTemplate(
                {
                    id: the_identifier,
                    prev_active: currentPage > 1,
                    next_active: currentPage !== totalPages,
                    numbers: nums ,
                }
            ));
            $('#' + the_identifier + ' .pagination-button').find('span:not(.inactive)').each(function(i, el) {
                var t = $(this);
                t.off('click').on('click', function (e) {
                    callback(t.hasClass('prev')  ? currentPage - 1 : currentPage + 1);
                });
            });
            $('#' + the_identifier + ' .numbers span.number.enabled').off('click').on('click', function(e){
                var number = $(this).data('number');
                if(number){
                    callback(parseInt(number));
                }
            });
        }
    };
    Object.assign(this, instancevars);
    return this;
}

//This is so legacy code can access this module.
if ($('#pagination-template').length > 0) {
    if (typeof window.paginationManager === "undefined") {
        window.paginationManager = new PaginationManager();
    }
}
