function status(response) {
	if (response.status >= 200 && response.status < 300) {
		return Promise.resolve(response);
	} else {
		return Promise.reject(new Error(response.statusText));
	}
}

const json = (response) => response.json();

export const loadNrfApp = (preValidateResponse) => {
    if (
        preValidateResponse.status &&
        (preValidateResponse.mode == 'nrf' || preValidateResponse?.experiments?.foundational_hygiene?.variant > 0)
    ) {
        let counter = 0;
        const nrfScripts = document.querySelectorAll('script[data-app="nrf"]');
        nrfScripts.forEach((el)=> {
            if (!el.getAttribute('src')) {
                el.setAttribute('src', el.getAttribute('data-src'));
            }
        });

        const secsToMaxWait = 8;  // max 8s
        const recheckInMs = 300;
        return new Promise(function check(resolve) {
            if (window.BarkRequestFlow) {
                return resolve(preValidateResponse);
            }
            counter++;
            if (counter >= secsToMaxWait * Math.floor(1000/recheckInMs) )
                return resolve({ status: false });
            setTimeout(() => check(resolve), recheckInMs);
        });
    }

    return Promise.resolve({ status: false });
}

export const preValidate = (createBarkFormData, categoryId) => {
    window.Bark.showLoading();
    const { locale, lang } = Bark.ENV;

    try {
        return fetch(`/${lang}/${locale}/pre-validate/`, {
            method: 'POST',
            body: createBarkFormData,
            redirect: 'follow',
            credentials: 'same-origin',
            mode: 'same-origin',
        })
            .then(status)
            .then(json)
            .then(loadNrfApp)
            .catch((err) => {
                return Promise.resolve({ status: false });
            });
    } catch (e) {
        return Promise.resolve({ status: false });
    }
};

export const validateProject = (createBarkFormData) => {
	const { locale, lang } = Bark.ENV;

	return fetch(`/${lang}/${locale}/validate-project/`, {
		method: 'POST',
		body: createBarkFormData,
		redirect: 'follow',
		credentials: 'same-origin',
		mode: 'same-origin',
	})
		.then(status)
		.then(json);
};
