import { startLegacyCreateBark } from './legacy-create-bark';
import { tracking } from '../../../analytics';
import { _l } from '../../../../libs/i18next';

// settings
export const entryWidgetSettings = ({ trackingExtraParams = {} }) => ({
	onFinish: (data) => onFinish(data),
	onNextQuestion: () => onNextQuestion(),
	onBackQuestion: () => onBackQuestion(),
	tracking: (group, name, params) =>
		tracking(group, name, {
			...params,
			...extraTrackingParams({ ...trackingExtraParams }),
		}),
	startModal: true,
	hideEntryWidget: true,
	questionModal: true,
});

// actions
export const finishNewRequestFlow = (machineData) => {
	const url = getDashboardUrl(machineData);
	window.location.href = url;
};
const onFinish = (questions) => {};
const onNextQuestion = () => {};
const onBackQuestion = () => {};

const getDashboardUrl = ({ projectData, user }) => {
	const { hashedProjectId, blt, bli } = projectData;

	if (user?.isLoggedIn) {
		return _l(`/buyers/bark/${hashedProjectId}/`);
	}

	return _l(`/buyers/bark/${hashedProjectId}/?blt=${blt}&bli=${bli}`);
};

//tracking
export const extraTrackingParams = ({ category_id = '', pre_bark_id = '' }) => ({
	'app-version': 'new-request-app-v1',
	category_id,
	pre_bark_id,
});

export const openModal = () => {
	document.querySelector('body').classList.add('no-scroll');
};
export const closeModal = () => {
	document.querySelector('body').classList.remove('no-scroll');
};
