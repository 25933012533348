import { default as CsatSellerList } from './displays/csat-seller-list';
import { tracking } from '../../components/analytics';

export default class CsatWidget {
	constructor() {
		this.widgetContainer = $('#csatWidget');
		this.variant = this.getVariant();
		this.sessionId = this.getSessionId();
		this.variantToDisplayMap = {
			5: CsatSellerList,
		};
		this.display = this.getDisplay();
	}

	getVariant() {
		return this.widgetContainer.length && this.widgetContainer.data('variant')
			? this.widgetContainer.data('variant')
			: null;
	}

	getSessionId() {
		return this.widgetContainer.length && this.widgetContainer.data('sessionId')
			? this.widgetContainer.data('sessionId')
			: null;
	}

	getDisplay() {
		if (
			this.variant &&
			this.variantToDisplayMap.hasOwnProperty(this.variant) &&
			typeof this.variantToDisplayMap[this.variant] === 'function'
		) {
			return new this.variantToDisplayMap[this.variant](this.widgetContainer);
		} else {
			return null;
		}
	}

	init() {
		if (this.shouldDisplay()) {
			this.widgetContainer.addClass(this.getDisplayKeyName());
			this.showWidget();
			this.bindWidgetListeners();
		}
	}

	bindWidgetListeners() {
		let that = this;

		this.widgetContainer
			.find('.js-csat-widget-close')
			.off('click')
			.on('click', function (e) {
				e.preventDefault();
				that.hideWidget();
			});

		this.widgetContainer
			.find('.csat-record')
			.off('click')
			.on('click', function (e) {
				e.preventDefault();
				const feedbackValue = $(event.currentTarget).attr('data-val');
				that.sendTrackingEvent(feedbackValue);
				that.hideWidget();
			});
	}

	sendTrackingEvent(feedbackValue) {
		tracking('Customer Satisfaction Feedback', this.getDisplayKeyName(), {
			feedback_score: feedbackValue,
			session_id: this.sessionId,
		});
	}

	shouldDisplay() {
		return this.widgetContainer.length && this.variant && this.display;
	}

	getDisplayKeyName() {
		return this.display?.keyName ? this.display.keyName : 'default';
	}

	showWidget() {
		typeof this.display?.show === 'function'
			? this.display.show()
			: this.widgetContainer.removeClass('d-none');
	}

	hideWidget() {
		typeof this.display?.hide === 'function'
			? this.display.hide()
			: this.widgetContainer.addClass('d-none');
	}
}
