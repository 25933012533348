export default class AutoContinueExperiment {

    constructor(data) {
        this.variant = data.variant;
    }

    bindEvents(elem) {
        elem.find('.auto-continue-wrapper').off('click').on('click', (e) => {

            // dont set for other input box
            if($(e.target).closest("input.inline-bark-q-radio-other").length > 0 ) {
                return false;
            }
            e.stopPropagation();
            e.preventDefault();

            let input = $(e.target).find('input');
            if (input.length === 0) {
                input = $(e.target).parent().find('input');
            }

            // toggle input
            const inputChecked = input.prop("checked");
            input.prop("checked",!inputChecked);
            $('#inlineBarkModalContent').find('.inline-bark-btn-continue').click();
        });
    }
}
