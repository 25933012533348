export function exposeQuickScrollToWindow() {

    window.quickScroll = function (target, offset) {
        var target = $(target);
        if (typeof offset === 'undefined') {
            offset = 0;
        }
        $('html, body').animate({
            scrollTop: target.offset().top + offset
        }, 1000);
        target.focus(); // Setting focus
        if (!target.is(":focus")){ // Checking if the target was focused
            target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            target.focus(); // Setting focus
        };
    };
}