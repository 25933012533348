import { _t } from '../../libs/i18next';

export default function RangeSliderQuestions() {
	/**
	 * The Bark global
	 * @type window.Bark
	 */
	let Bark = (window.Bark = window.Bark || {});

	/**
	 * The Create Bark namespace
	 * @type window.Bark.createBark
	 */
	let cb = (Bark.createBark = Bark.createBark || {});

	/**
	 * @type window.Bark.createBark.rangeSlider
	 */
	let range = (cb.rangeSlider = cb.rangeSlider || {});

	/**
	 * Map a value (from the actual slider) to the grid (what we want the user to see)
	 * @param {Number} value
	 * @param {int} min The min value that the slider can be
	 * @param {int} mid The midpoint of the slider
	 * @param {int} max The max value that the slider can be
	 * @author Lord Searle
	 * @returns {*|string}
	 */
	let valueToGrid = (value, min, mid, max) => {
		let rangeDelta = max - min;
		let gridMid = rangeDelta / 2;
		let valueDelta, sectionDelta, startingPoint, sectionMin, grid;
		let gridPercent = (value - min) / (max - min);
		const decimalDiffCutoff = 20;
		const largeNumberRound = 5;

		if (value <= gridMid + min) {
			// if the value is less than the midpoint, use the lower range
			sectionMin = min;
			valueDelta = value - min;
			sectionDelta = mid - min;
			startingPoint = min;
		} else {
			// use the higher range
			sectionMin = gridMid + min;
			sectionDelta = max - mid;
			startingPoint = mid;
		}

		grid = (value - sectionMin) * (sectionDelta / gridMid) + startingPoint;

		if (min >= 100) {
			return Math.ceil(grid / largeNumberRound) * largeNumberRound;
		}

		if (rangeDelta < decimalDiffCutoff) {
			// The gap is too high
			grid = Math.ceil(grid * 2);
			grid /= 2;
			return grid.toFixed(0);
		}

		return Math.floor(grid).toFixed(0);
	};

	/**
	 * Map a value (from the display value) to the slider (the value that we want)
	 * @param {Number} gridValue
	 * @param {int} min The min value that the slider can be
	 * @param {int} min The midpoint of the slider
	 * @param {int} max The max value that the slider can be
	 * @author Lord Searle
	 * @returns {*}
	 */
	let gridToValue = (gridValue, min, mid, max) => {
		let rangeDelta = max - min;
		let gridMid = rangeDelta / 2;
		let valueDelta, sectionDelta, startingPoint, sectionMin;

		if (gridValue <= mid) {
			// if the value is less than the midpoint, use the lower range
			sectionMin = min;
			sectionDelta = mid - min;
			startingPoint = min;
		} else {
			// use the higher range
			sectionMin = gridMid + min;
			sectionDelta = max - mid;
			startingPoint = mid;
		}

		return (gridValue - startingPoint) / (sectionDelta / gridMid) + sectionMin;
	};

	/**
	 * returns a currency string with 2 decimals if < 100, no decimals if < 100K, and K if over 1000,000. Does not handle millions.
	 * @param decimalAmount a number or string that can be parsed with decimal value
	 * @author Lord Gourarie
	 */
	let formatCurrencyByInterval = (decimalAmount) => {
		let toReturn = ''; // Bark.LOCALE.currency_symbol;

		decimalAmount = parseFloat(decimalAmount);

		if (isNaN(decimalAmount)) {
			return toReturn + 0;
		}

		return (toReturn += decimalAmount.toFixed(0));
	};

	let getFromMax = (midpoint, max) => {
		let diff = max - midpoint;

		if (diff < 100) {
			return max - 2;
		}

		if (diff < 200) {
			return max - 5;
		}

		return max - 10;
	};

	let getToMin = (midpoint, min) => {
		let diff = midpoint - min;

		if (diff < 100) {
			return min + 2;
		}

		if (diff < 200) {
			return min + 5;
		}

		return min + 10;
	};

	/**
	 * Render a numeric range question
	 * @param {object} question The object describing the current question
	 * @author Lord Gourarie
	 * @returns {html}
	 */
	cb.renderRange = function (question) {
		let min = question.range_min;
		let max = question.range_max;
		let defaultMin = question.range_default_min;
		let defaultMax = question.range_default_max;
		let numberPrefix = question.range_is_currency ? Bark.LOCALE.currency_symbol : '';
		let numberLabel = question.range_estimate_type ?? false;
		let enableInput = question.range_allow_override;
		let toTarget = question.name + '-to';
		let fromTarget = question.name + '-from';
		let step = question.range_step_size ?? Math.round((max - min) / 5);
		let midpoint = question.range_midpoint_target || (max - min) / 2;
		let slideropts = {
			name: question.name + '-range',
			'data-type': 'double',
			'data-min': min,
			'data-max': max,
			'data-from': gridToValue(defaultMin, min, midpoint, max),
			'data-to': gridToValue(defaultMax, min, midpoint, max),
			'data-from-max': getFromMax(midpoint, max),
			'data-to-min': getToMin(midpoint, min),
			'data-midpoint': midpoint,
			'data-hide-min-max': true,
			'data-hide-from-to': true,
			'data-prefix': numberPrefix,
			'data-postfix': numberLabel ? ' ' + numberLabel : '',
			'data-max-postfix': '+',
			'data-decorate-both': false,
			'data-force-edges': true,
			'data-skin': 'round',
			'data-enable-input': enableInput,
			'data-to-target-id': toTarget,
			'data-from-target-id': fromTarget,
			'data-min-interval': step,
			'data-is-currency': question.range_is_currency,
		};
		let slider = Bark.getHtml(
			'input',
			null,
			null,
			'js-range-slider ' + question.name + '-range',
			slideropts,
		);
		let inputParams = {
			'data-from': defaultMin,
			'data-to': defaultMax,
			'data-max': max,
			'data-min': min,
			'data-currency': question.range_is_currency && true,
			'data-question-id': question.name,
		};

		if (!enableInput) {
			inputParams.disabled = true;
		}

		inputParams.value = question.range_is_currency
			? formatCurrencyByInterval(defaultMin)
			: defaultMin;
		let inputPrefix = numberPrefix
			? Bark.getHtml('span', numberPrefix, null, null, { style: 'font-size: 16px;' })
			: '';
		let fromInput =
			inputPrefix +
			Bark.getHtml(
				'input',
				null,
				fromTarget,
				'form-control p-1 prevent-ios-zoom js-range-value-from js-ignore-keyboard-events js-no-autofocus bg-white text-truncate col text-left',
				inputParams,
			);
		inputParams.value = question.range_is_currency
			? formatCurrencyByInterval(defaultMax)
			: defaultMax;
		let toInput =
			inputPrefix +
			Bark.getHtml(
				'input',
				null,
				toTarget,
				'form-control p-1 prevent-ios-zoom js-range-value-to js-ignore-keyboard-events js-no-autofocus bg-white text-truncate col text-left',
				inputParams,
			);
		// create all the malarkey around the inputs to style them nicely
		let inputGroupTextClass = 'range-input-label' + (enableInput ? '' : ' disabled');
		let per = numberLabel
			? Bark.getHtml('span', numberLabel, null, inputGroupTextClass + ' font-size-14')
			: '';
		let fromInputGroup = Bark.getHtml(
			'div',
			Bark.getHtml('div', fromInput, null, 'd-flex align-items-center') + per,
			null,
			'range-input-grouping col-5 col-sm-3 px-2',
		);
		let toInputGroup = Bark.getHtml(
			'div',
			Bark.getHtml('div', toInput, null, 'd-flex align-items-center') + per,
			null,
			'range-input-grouping col-5 col-sm-3 px-2',
		);
		let inputsSeparator = Bark.getHtml(
			'div',
			' to ',
			null,
			'text-center col-2 col-sm-1',
			{ style: 'padding: 0 10px; color: #9DA0B6;' },
		);
		let inputRow = Bark.getHtml(
			'div',
			fromInputGroup + inputsSeparator + toInputGroup,
			null,
			'my-3 d-flex justify-content-center align-items-center px-md-3',
			{},
		);

		// put it all together
		let inner = Bark.getHtml('div', inputRow + slider, null, 'bark-range-main-cont pb-3');

		if (cb.isFrontendV2WithBootstrap()) {
			inner += '<br>';
		}

		let output = Bark.getHtml(
			'div',
			inner,
			null,
			'inline-bark-q-range inline-bark-q inline-bark-q-text new-project-field',
		);

		return output;
	};

	/**
	 * Validate a range question
	 * @author Lord Gourarie
	 * @returns {boolean}
	 */
	cb.validateRange = function () {
		let q = cb.questions[cb.pos];
		let rangeVal = cb.getRangeAnswerValue(q);

		if (q.required && !rangeVal.value.length) {
			let scope = $('#bark-question-' + cb.pos);

			$('.inline-bark-modal-errors')
				.text(_t('common_range-slider:errors.invalid-range'))
				.removeClass('hide');
			scope.addClass('new-error');
			if (!cb.isFullyInViewport(document.querySelector('.inline-bark-modal-errors'))) {
				$('#inlineBarkModal').animate({ scrollTop: 0 }, 800);
			}

			cb.tagManager(`bark-modal:range-question:validation:fail`);
			$.post('/api/elo/', {
				c: 'bark-modal-range-question',
				n: 'validation-fail',
				i: 'not_set',
			});

			return false;
		} else {
			if (isNaN(rangeVal.from) || isNaN(rangeVal.to) || rangeVal.to === 0) {
				$('.inline-bark-modal-errors')
					.text(_t('common_range-slider:errors.invalid-range'))
					.removeClass('hide');

				if (!cb.isFullyInViewport(document.querySelector('.inline-bark-modal-errors'))) {
					$('#inlineBarkModal').animate({ scrollTop: 0 }, 800);
				}

				cb.tagManager(`bark-modal:range-question:validation:fail`);
				$.post('/api/elo/', {
					c: 'bark-modal-range-question',
					n: 'validation-fail',
					i: 'malformed',
				});

				return false;
			}

			if (rangeVal.from > rangeVal.to) {
				$('.inline-bark-modal-errors')
					.text(_t('common_range-slider:errors.invalid-range'))
					.removeClass('hide');
				if (!cb.isFullyInViewport(document.querySelector('.inline-bark-modal-errors'))) {
					$('#inlineBarkModal').animate({ scrollTop: 0 }, 800);
				}

				cb.tagManager(`bark-modal:range-question:validation:fail`);
				$.post('/api/elo/', {
					c: 'bark-modal-range-question',
					n: 'validation-fail',
					i: 'from_gt_to',
				});

				return false;
			}
		}

		return true;
	};

	/**
	 * Get the answer for a range question
	 * @param question
	 * @returns {object}
	 */
	cb.getRangeAnswerValue = function (question) {
		let { postfix, isCurrency } = $(`.js-range-slider.${question.name}-range`).data();
		let fromSelect = question.name + '-from';
		let toSelect = question.name + '-to';
		let fromElem = $(`#${fromSelect}`);
		let fromVal = fromElem.val();
		let toElem = $(`#${toSelect}`);
		let toVal = toElem.val();
		let fromValDisplay = isCurrency
			? `${Bark.LOCALE.currency_symbol}${fromVal}`
			: fromVal;
		let toValDisplay = isCurrency ? `${Bark.LOCALE.currency_symbol}${toVal}` : toVal;
		let rangeVal = (
			fromValDisplay +
			(fromVal === toVal ? '' : ' to ' + toValDisplay) +
			(postfix ? ` ${postfix}` : '')
		).trim();

		return {
			type: 'range',
			from: sanitiseValueFromInput(fromElem),
			to: sanitiseValueFromInput(toElem),
			value: rangeVal,
			toString: () => {
				return rangeVal;
			},
		};
	};

	/**
	 * Update the inputs
	 * @param {object} data
	 * @param {string} fromTargetId
	 * @param {string} toTargetId
	 */
	let updateInputs = (data, fromTargetId, toTargetId) => {
		let from = $(`#${fromTargetId}`);
		let to = $(`#${toTargetId}`);
		let { midpoint, isCurrency, max } = $(data.input).data();
		let fromVal = valueToGrid(data.from, data.min, midpoint, data.max);
		let toVal = valueToGrid(data.to, data.min, midpoint, data.max);

		from.val(isCurrency ? formatCurrencyByInterval(fromVal) : fromVal);
		to.val(
			(isCurrency ? formatCurrencyByInterval(toVal) : toVal) +
				(+toVal === +max ? '+' : ''),
		);
	};

	/**
	 * Get a sanitised value from an input
	 * @param {jQuery} input
	 * @returns {*}
	 */
	let sanitiseValueFromInput = (input) => {
		return +input.val().replace(/[^0-9\.]/g, '');
	};

	/**
	 * Add the centre point onto the graph
	 * @param {DOMElement} sliderElem
	 * @param {float} min
	 * @param {float} midpoint
	 * @param {float} max
	 * @param {boolean} isCurrency
	 */
	let addMarkers = (sliderElem, min, midpoint, max, isCurrency) => {
		let symbol = isCurrency ? Bark.LOCALE.currency_symbol : '';
		let minMoney = isCurrency
			? symbol + formatCurrencyByInterval(min).replace('.00', '')
			: min;
		let midMoney = isCurrency ? symbol + formatCurrencyByInterval(midpoint) : midpoint;
		let maxMoney = isCurrency
			? symbol + formatCurrencyByInterval(max).replace('.00', '')
			: max;
		let html;
		let se = $(sliderElem);

		html = `<span class="showcase__mark bark-modal-range-marker left-range-marker font-size-14" style="left: 0%; color: #9DA0B6;">${minMoney}</span>`;
		html += `<span class="showcase__mark bark-modal-range-marker centre-range-marker font-size-14" style="left: 50%; color: #9DA0B6;">${midMoney}</span>`;
		html += `<span class="showcase__mark bark-modal-range-marker right-range-marker font-size-14" style="left: 100%; color: #9DA0B6;">${maxMoney}+</span>`;

		if (!$('.bark-modal-range-marker', se).length) {
			// Add if not present
			se.append(html);
		}
	};

	/**
	 * Bind events
	 */
	range.bindEvents = () => {
		let sliders = $('input.js-range-slider');

		if (!sliders.length) {
			return;
		}

		window.useNamespace('common_range-slider').then(() => {
			sliders.each((_, element) => {
				let t = $(element);
				let data = t.data();
				let setSlider = (sliderData) => {
					let d = $(sliderData.input).data();

					addMarkers(sliderData.slider, d.min, d.midpoint, d.max, d.isCurrency);
					updateInputs(sliderData, d.fromTargetId, d.toTargetId);
				};

				if (data.ionRangeSlider) {
					return;
				}

				t.ionRangeSlider({
					type: 'double',
					grid: false,
					step: 0.1,
					min: data.min,
					max: data.max,
					from: gridToValue(data.from, data.min, data.midpoint, data.max),
					to: gridToValue(data.to, data.min, data.midpoint, data.max),
					to_min: data.toMin,
					from_max: data.fromMax,
					prettify: (value) => {
						return valueToGrid(value, data.min, data.midpoint, data.max);
					},
					snap: true,
					onStart: setSlider,
					onChange: setSlider,
					onFinish: (sliderData) => {
						cb.tagManager('bark-modal:range-question:use-slider');
						setSlider(sliderData);
					},
				});
			});

			$('.js-range-value-from')
				.off('input.listen')
				.on('input.listen', ({ currentTarget }) => {
					let t = $(currentTarget);
					let questionId = t.data('question-id');
					let { ionRangeSlider, min, max, midpoint, toTargetId, isCurrency } = $(
						`.${questionId}-range`,
					).data();
					let sanitised = sanitiseValueFromInput(t);
					let fromVal = Math.max(0, sanitised);
					let toVal = sanitiseValueFromInput($(`#${toTargetId}`));

					currentTarget.value = sanitised;

					ionRangeSlider.update({
						from: gridToValue(fromVal, min, midpoint, max),
						to: gridToValue(toVal, min, midpoint, max),
					});

					addMarkers(ionRangeSlider.result.slider, min, midpoint, max, isCurrency);
				});

			$('.js-range-value-to')
				.off('input.listen')
				.on('input.listen', ({ currentTarget }) => {
					let t = $(currentTarget);
					let questionId = t.data('question-id');
					let { ionRangeSlider, min, max, midpoint, fromTargetId, isCurrency } = $(
						`.${questionId}-range`,
					).data();
					let toVal = sanitiseValueFromInput(t);
					let fromVal = sanitiseValueFromInput($(`#${fromTargetId}`));

					currentTarget.value = toVal;

					ionRangeSlider.update({
						to: gridToValue(toVal, min, midpoint, max),
						from: gridToValue(fromVal, min, midpoint, max),
					});

					addMarkers(ionRangeSlider.result.slider, min, midpoint, max, isCurrency);
				});

			$('.js-range-value-from,.js-range-value-to')
				.off('change.listen')
				.on('change.listen', ({ currentTarget }) => {
					let t = $(currentTarget);
					let questionId = t.data('question-id');
					let { ionRangeSlider, min, midpoint, max, isCurrency } = $(
						`.${questionId}-range`,
					).data();
					let toOrFrom = t.hasClass('js-range-value-from') ? 'from' : 'to';
					let fromElem = $(`#${questionId}-from`);
					let toElem = $(`#${questionId}-to`);
					let from = sanitiseValueFromInput(fromElem);
					let to = sanitiseValueFromInput(toElem);
					let sanitised = sanitiseValueFromInput(t);
					let setMax = false;

					if (sanitised > max) {
						sanitised = max;
					}

					if (sanitised < min) {
						sanitised = min;
					}

					if (toOrFrom === 'to') {
						if (sanitised < from) {
							fromElem.val(sanitised).trigger('input').trigger('change');
						}

						if (sanitised === +max) {
							setMax = true;
						}
					} else {
						if (sanitised > to) {
							toElem.val(sanitised).trigger('input').trigger('change');
						}
					}

					t.val(
						isCurrency
							? formatCurrencyByInterval(sanitised) + (setMax ? '+' : '')
							: sanitised,
					);

					addMarkers(ionRangeSlider.result.slider, min, midpoint, max, isCurrency);

					cb.tagManager(`bark-modal:range-question:set-${toOrFrom}`);
					$.post('/api/elo/', {
						c: 'bark-modal-range-question',
						n: 'manual-input',
						i: toOrFrom,
					});
				});
		});
	};
}
