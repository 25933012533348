export function SellerNotificationsV2() {
	/**
	 * The <b>Bark</b> global
	 * @type Window.Bark
	 */
	var Bark = (window.Bark = window.Bark || {});
	var phsd = Bark.ENV.phsd || $('meta[name="phsd"]').attr('content');

	/**
	 * The notification socket
	 * @type io
	 */
	var notifySocket;

	/**
	 * An object of notification-related functions
	 * @type object
	 */
	var notificationFunctions = {};
	// Ensure the socket.io script has loaded
	window.addEventListener('load', function () {
		try {
			notifySocket = io(window.Bark.consts.WS_URL + '/notif', {
				query: 'phsd=' + phsd,
				reconnectionAttempts: 3,
				reconnectionDelay: 6000,
				transports: ['websocket'],
			});

			notifySocket.on('reconnect_attempt', function () {
				notifySocket.io.opts.transports = ['polling', 'websocket'];
			});

			notifySocket.on('connect', function () {
				notifySocket.emit('subscribe', {});
			});

			notifySocket.on('session ended', function () {
				notifySocket.disconnect();
			});

			notifySocket.on('message', function (action, barkID, data) {
				switch (action) {
					case 'megakick':
						window.location = _l('/sellers/logout/');
						break;

					case 'reset-agency-session':
						Bark.json({
							url: '/agencies/reset-client-session/',
						});
						break;

					default:
						if (action in notificationFunctions) {
							notificationFunctions[action](barkID, data);
							return;
						}
						throw new Bark.ex(Bark.sprintf('Unknown function "%s" in list', action));
				}
			});
		} catch (e) {
			bugsnagClient.notify(e);
		}
	});

	/**
	 * Unknown
	 * @param {unknown} since unknown
	 */
	window.getCombinedUpdates = function (since) {
		$.ajax({
			type: 'POST',
			url: '/sellers/user_combined_ajax/',
			data: {
				since: since,
				dashboard: true,
				notifications: true,
				messages: false,
				skip_poll: true,
			},
			dataType: 'json',
			statusCode: {
				401: function () {
					// seller is logged out
					location.href = '/logout/';
				},
				404: function () {
					location.reload();
				},
			},
			timeout: 30000,
		})
			.done(function (data) {
				since = data.timestamp;
				if (data.notifications.length) {
					data.notifications.reverse();

					$.each(data.notifications, function (key, val) {
						applyNotifications(val);
					});
					updateSellerNotificationCount();
				}
				if ($('body.dashboard').length) {
					Bark.seller.renderNewProjectList();
				} else if ($('body.my-barks').length) {
					Bark.seller.renderMyProjectList();
				}
			})
			.fail(function (jqXHR, status, thrown) {
				console.error(
					Bark.sprintf(
						'An error with code %s has occurred',
						Bark.consts.ERR_COMBINED_UPDATES
					)
				);
				if (jqXHR.status !== 401) {
					// Handled above
					bugsnagClient.notify(new Error(status + ': ' + thrown));
				}
			});
	};

	/**
	 * Update the number of notifications that the seller has
	 * @param {int} responseId
	 */
	function updateSellerNotificationCount(responseId) {
		var notificationCount = $('.js-notification-list').find(
			'li.notification-item',
		).length;
		var obj;
		//$('.bark-notification-count').text(notificationCount);
		if (notificationCount > 0) {
			$(
				'.seller-notification,.bark-notification-count,.notification-count-container',
			).show();
			$('.bark-main-menu-mob .bark-notification-count').css({ display: 'inline-block' });
			$('.notification-count-container .text').text(
				_t("common:notifications.new-notification", {count: notificationCount})
			);
		} else {
			var obj = $(".messenger-leftnav>ul>li[data-response-id='" + responseId + "']").find(
				'.message-new',
			);
			obj.text(obj.text() + 1).show();
		}
	}

	/**
	 * Increment the number of response notifications
	 * @param {int} responseId
	 */
	function incrementResponseNotifications(responseId) {
		var responseNotificationObject = $(
			Bark.sprintf('.seller-dashboard-bark-unread[data-response-id="%s"]', responseId),
		);
		var notificationCount = +(
			responseNotificationObject
				.find('div')
				.addClass('unread-notification-number')
				.text()
				.replace(/^(\d+).*/, '$1') || 0
		);
		notificationCount++;
		responseNotificationObject
			.find('div')
			.text(
				_t("common:notifications.new-notification-with-count", {count: notificationCount})
			);
		responseNotificationObject.show();
	}

	function formatNotificationHtml(projectUrl, iconUrl, title, message, date) {
		var notification = $('.notification-template').clone();

		notification.find('.notification-link').attr('href', projectUrl);
		notification.find('.notification-icon').attr('src', iconUrl);
		notification.find('.title').text(title);
		notification.find('.message').text(message);
		if (date) {
			notification.find('.date').text(date);
		}

		notification.css({ display: 'block' });

		return notification.html();
	}

	/**
	 * Increment the dashboard message count (assumed)
	 */
	function incrementDashMessage() {
		var newCount =
			$('.dashboard_message_wording span.status_message_count:first').text() + 1;
		$('.dashboard_message_wording span.status_message_count').text(newCount);
	}

	function applyNotifications(data) {
		var elem;
		var outputTo;
		var showMore;
		var container = $('.js-notification-list');
		if (!data) {
			throw 'Arrrgh';
		}

		if (!$('.show-more', container).length) {
			// Add in a show more link
			container.append('<li class="show-more dropdown-item">Show more</li>');
		}
		showMore = $('.show-more', container);
		if (!$('.item-consolidation', container).length) {
			$('<ul class="item-consolidation"></ul>').insertBefore(showMore);
		}
		outputTo = $('.item-consolidation', container).data({ pos: 1 });
		//        incrementResponseNotifications(data.response_id);
		switch (data.type) {
			case 'quote':
				elem = $('<li />', {
					class: 'clearfix notification-item dropdown-item',
					'data-message-quote-id': data.message_file_id,
					'data-response-id': data.response_id,
					html: formatNotificationHtml(
						data.project_url,
						'https://d18jakcjgoan9.cloudfront.net/img/barkv2/material-icons/baseline-check_circle-24px.svg',
						_t("common:notifications.new-quote"),
						data.quote,
					),
				});
				break;
			case 'message':
				elem = $('<li />', {
					class: 'clearfix notification-item dropdown-item',
					'data-message-id': data.message_id,
					'data-response-id': data.response_id,
					html: formatNotificationHtml(
						data.project_url,
						'https://d18jakcjgoan9.cloudfront.net/img/barkv2/material-icons/baseline-check_circle-24px.svg',
						data.project_title,
						data.message,
					),
				});
				break;
			case 'response':
				elem = $('<li />', {
					class: 'clearfix notification-item dropdown-item',
					'data-response-id': data.response_id,

					html: formatNotificationHtml(
						data.project_url,
						'https://d18jakcjgoan9.cloudfront.net/img/barkv2/material-icons/baseline-check_circle-24px.svg',
						data.project_title,
						data.message,
					),
				});
				break;
			case 'shortlisted':
				elem = $('<li />', {
					class: 'clearfix notification-item dropdown-item',
					'data-response-id': data.response_id,
					html: formatNotificationHtml(
						data.project_url,
						'https://d18jakcjgoan9.cloudfront.net/img/barkv2/material-icons/baseline-check_circle-24px.svg',
						data.project_title,
						data.buyer_share_name,
					),
				});
				break;
			case 'file':
				elem = $('<li />', {
					class: 'clearfix notification-item dropdown-item',
					'data-message-file-id': data.message_file_id,
					'data-response-id': data.response_id,
					html: formatNotificationHtml(
						data.project_url,
						'https://d18jakcjgoan9.cloudfront.net/img/barkv2/material-icons/baseline-check_circle-24px.svg',
						data.project_title,
						data.filename,
					),
				});
				break;
			case 'alert':
				elem = $('<li />', {
					class: 'clearfix notification-item dropdown-item',
					'data-message-alert-id': data.project_id,
					html: formatNotificationHtml(
						data.project_url,
						'https://d18jakcjgoan9.cloudfront.net/img/barkv2/material-icons/baseline-check_circle-24px.svg',
						_t("common:notifications.new-business"),
						data.project_title,
					),
				});
				break;
			case 'action':
				elem = $('<li />', {
					class: 'clearfix notification-item dropdown-item',
					'data-message-alert-id': data.project_id,

					html: formatNotificationHtml(
						data.project_url,
						'https://d18jakcjgoan9.cloudfront.net/img/barkv2/material-icons/baseline-check_circle-24px.svg',
						_t('common:notifications.new-action'),
						data.action_text,
						data.created_friendly,
					),
				});
				break;
			case 'review':
				elem = $('<li />', {
					class: 'clearfix notification-item dropdown-item',
					'data-message-alert-id': data.message_id,

					html: formatNotificationHtml(
						data.project_url,
						'https://d18jakcjgoan9.cloudfront.net/img/barkv2/material-icons/baseline-check_circle-24px.svg',
						_t('common:notifications.new-review'),
						data.action_text,
					),
				});
				break;
		}
		outputTo.prepend(elem);
		$('.js-notification-list .notification-item').hide();
		showMoreNotifications(5);
		elem.clone(true).insertAfter('.bark-mob-menu-items .back');
		elem.clone(true).appendTo('.header-notifications-mobile .notifications');
		showMore.unbind('click.showmore').on('click.showmore', function (event) {
			var nToShow = 5;
			var newPos = $('.item-consolidation').data('pos') + 1;
			$('.item-consolidation').data({ pos: newPos });
			showMoreNotifications(nToShow * newPos);
			event.stopPropagation();
		});
		$('.js-notification-list')
			.find(
				"li.notification-item[data-response-id='" +
					$('.messenger-container').attr('id') +
					"']",
			)
			.remove();
	}

	/**
	 * Show more of the notifications that a user has
	 * @param {int} max The maximum number of notifications that can be shown
	 */
	function showMoreNotifications(max) {
		var lists = $('.js-notification-list');
		lists.each(function (count, list) {
			var showMore = $('.show-more', list);
			var length = $('.notification-item', list).length;
			for (var i = 0; i < max; i++) {
				$('.notification-item', list).eq(i).show();
				if (i + 1 === length) {
					showMore.hide();
					break;
				} else {
					showMore.text(_t('common:notifications.view-more',{'notifications_count': (length - (i + 1)) }) );
					showMore.show();
				}
			}
		});
	}

	/**
	 * Notify about the accepting of a Bark
	 * @param {int} barkID The id of the Bark
	 */
	notificationFunctions.accept = function (barkID) {
		var classList =
			'status_closed status_new status_finding status_contacting status_live status_rejected';
		$(Bark.sprintf('.bark-summary-3[data-project-id="%s"]', barkID))
			.removeClass(classList)
			.addClass('status_finding');
	};

	/**
	 * Notify about the resetting of a Bark
	 * @param {int} barkID The id of the Bark
	 */
	notificationFunctions.reset = function (barkID) {
		var classList =
			'status_closed status_new status_finding status_contacting status_live status_rejected';
		$(Bark.sprintf('.bark-summary-3[data-project-id="%s"]', barkID))
			.removeClass(classList)
			.addClass('status_new');
	};

	/**
	 * Notify about the releasing of a Bark
	 * @param {int} barkID The id of the Bark
	 */
	notificationFunctions.release = function (barkID) {
		var classList =
			'status_closed status_new status_finding status_contacting status_live status_rejected';
		$(Bark.sprintf('.bark-summary-3[data-project-id="%s"]', barkID))
			.removeClass(classList)
			.addClass('status_contacting');
	};

	notificationFunctions.reopen = notificationFunctions.release;

	/**
	 * Notify about the rejection of a Bark
	 * @param {int} barkID The id of the Bark
	 */
	notificationFunctions.reject = function (barkID) {
		var classList =
			'status_closed status_new status_finding status_contacting status_live status_rejected';
		$(Bark.sprintf('.bark-summary-3[data-project-id="%s"]', barkID))
			.removeClass(classList)
			.addClass('status_rejected');
	};

	/**
	 * Notify about the un-rejecting of a Bark
	 * @param {int} barkID The id of the Bark
	 */
	notificationFunctions.unreject = function (barkID) {
		var classList =
			'status_closed status_new status_finding status_contacting status_live status_rejected';
		$(Bark.sprintf('.bark-summary-3[data-project-id="%s"]', barkID))
			.removeClass(classList)
			.addClass('status_new');
	};

	/**
	 * Notify about the closing of a Bark
	 * @param {int} barkID The id of the Bark
	 */
	notificationFunctions.close = function (barkID) {
		var classList =
			'status_closed status_new status_finding status_contacting status_live status_rejected';
		$(Bark.sprintf('.bark-summary-3[data-project-id="%s"]'), barkID)
			.removeClass(classList)
			.addClass('status_closed');
	};

	/**
	 * Notify about a new quote
	 * @param {int} responseId The if of the response (assumed)
	 * @param {object} data
	 */
	notificationFunctions.quote = function (responseId, data) {
		applyNotifications({
			type: 'quote',
			response_id: responseId,
			quote_type: data.quote_type,
			quote: data.quote,
		});
		updateSellerNotificationCount();
		incrementDashMessage();
	};

	/**
	 * Notify about a new action (assumed)
	 * @param {int} responseId The id of the response (assumed)
	 * @param {object} data
	 */
	notificationFunctions.action = function (responseId, data) {
		if (!$('#seller-messenger').length) {
			applyNotifications({
				type: 'action',
				response_id: responseId,
				action_text: data.action_text,
				project_url: _l('/sellers/messenger/' + data.slug + '/'),
			});
			updateSellerNotificationCount();
			incrementDashMessage();
		}
	};

	/**
	 * Notify about a new message (assumed)
	 * @param {int} responseId The id of the response (assumed)
	 * @param {object} data
	 */
	notificationFunctions.message = function (responseId, data) {
		applyNotifications({
			type: 'message',
			response_id: responseId,
			message: data.note,
			project_title: data.project_title,
			project_url: _l('/sellers/messenger/' + data.slug + '/'),
		});
		updateSellerNotificationCount();
		incrementDashMessage();
	};

	/**
	 * Notify about a new file (assumed)
	 * @param {int} responseId The id of the response
	 * @param {object} data
	 */
	notificationFunctions.file = function (responseId, data) {
		applyNotifications({
			type: 'file',
			response_id: responseId,
			filename: data.filename,
			slug: data.slug,
			project_slug: data.project_slug,
			project_title: data.project_title,
			project_url: _l('/sellers/messenger/' + data.project_slug + '/'),
		});
		updateSellerNotificationCount();
		incrementDashMessage();
	};

	/**
	 * Notify about a response to a Bark (assumed)
	 * @param {int} barkID The id of the Bark (assumed)
	 * @param {object} data unknown
	 */
	notificationFunctions.response = function (barkID, data) {
		var newCount;
		var classList =
			'status_closed status_new status_finding status_contacting status_live status_rejected';
		data = data || false;
		if ($('#seller-messenger').length) {
			addResponse(data);
		} else {
			applyNotifications({
				type: 'response',
				response_id: data.id,
				message: data.response_text,
				project_title: data.project_title,
				project_url: _l('/sellers/messenger/' + data.slug + '/'),
			});
			updateSellerNotificationCount();
			incrementDashMessage();
		}
		newCount = $('.dashboard_response_wording span.num:first').text() + 1;
		$('.dashboard_response_wording span.num').text(newCount);
		$(Bark.sprintf('.bark-summary-3[data-project-id="%s"]', barkID))
			.removeClass(classList)
			.addClass('status_live');
	};

	$(function () {
		window.getCombinedUpdates(0);
	});
}
