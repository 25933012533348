import { tracking } from "./bark-tracking";

export function init() {
    const HeapTracking = getServerSideHeapevents();

    if(HeapTracking?.length > 0){
        HeapTracking.forEach(item => {
            tracking(item.name, item.value, item.params);
        });
    }
}


// functions

const getServerSideHeapevents = () => {
    const HeapTracking = window.Bark.heapTracking = window.Bark.heapTracking || [];
    return HeapTracking;
}
