import {_t} from "../../libs/i18next";

export const FormManager = function () {

	var rowStyle = {
		"background-color": "#FFF",
		"left": "0",
		"width": "100%",
		"box-shadow": "0 6px 20px 0px rgba(0, 0, 0, 0.2)",
		"-webkit-box-shadow":" 0 6px 20px 0 rgba(0, 0, 0, 0.2)",
		"-moz-box-shadow": "0 6px 20px 0px rgba(0, 0, 0, 0.2)",
		"display": "flex",
		"justify-content": "center",
		"align-items": "center",
		"padding" : "0.5em",
	};
	var formContent =
		'<span id="form-manager-cancel" class="text-grey-400 cursor-pointer mr-4 disabled">'+ _t('common_form-manager:cancel-button') +'</span>' +
		'<a id="form-manager-save" class="btn btn-primary settings-submit disabled" data-default-class="btn-primary" data-success-class="btn-success">' +
		'<div class="d-flex align-items-center">' +
		'<span class="submit-spinner spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>' +
		'<span class="submit-text">'+ _t('common_form-manager:save-button') +'</span>' +
		'<span class="submit-success-text"></span>' +
		'</div>' +
		'</a>';

	const SUBMIT_FAIL_MESSAGE =  _t('common_form-manager:errors-on-page');
	const SUBMIT_FAIL_CLASS = 'btn-danger';
	const HEADER_HEIGHT_OFFSET = 100;

	const selfElement = $('#form-manager');
	let targets = [];
	let dirtyListeners = [];
	let hasShown = false;
	let nothingToSaveCallback = null;

	const startSubmit = ($submitElement) => {
		$submitElement.addClass('submit-waiting');
	};

	const endSubmit = ($submitElement, successMessage, extraClass) => {
		if (typeof successMessage === 'undefined') {
			successMessage = _t('common_form-manager:save-confirmation');
		}

		if (typeof extraClass === 'undefined') {
			extraClass = '';
		}

		$submitElement.removeClass('submit-waiting');
		var successClass = $submitElement.data('success-class');


		if (!$('._buyer-settings-page').length) {

			$submitElement.find('.submit-success-text').text(successMessage);
			$submitElement.addClass(successClass)
				.addClass('submit-success')
				.removeClass($submitElement.data('default-class'))
				.trigger('submit_completed', {successMessage: successMessage, extraClass: extraClass, failed: extraClass === SUBMIT_FAIL_CLASS});
			if (extraClass) {
				$submitElement
					.removeClass('btn-success')
					.addClass(extraClass)
			}
		}

		setTimeout(function () {
			$submitElement
				.removeClass(successClass)
				.addClass($submitElement.data('default-class'))
				.removeClass(extraClass)
				.trigger('submit_reset', {
					successClass: successClass,
					extraClass: extraClass,
					failed: extraClass === SUBMIT_FAIL_CLASS,
				});
			if (extraClass) {
				$submitElement.removeClass('submit-success');
			}
			$submitElement.find('.submit-success-text').text(_t('common_form-manager:save-button'));
		}, 2500);
	};

	const show = () => {
		if (!hasShown) {
			hasShown = true;

			if (!$('._buyer-settings-page').length) {
				selfElement.removeClass('d-none');
				selfElement.addClass('gooeycontaineritem');
				selfElement.addClass('gooeycontainer-bottom');
				selfElement.html(formContent);
				selfElement.css(rowStyle);
				$('#form-spacer').addClass('mb-5');
				$('#form-spacer').removeClass('d-none');
				$('#form-manager-cancel').off('click').on('click', function(){
					cancel();
				});
			}

			$('#form-manager-save')
				.off('click')
				.on('click', function () {

					if (!$(this).hasClass('submit-waiting')) {
						startSubmit($(this));
						save(getTargets());
					}
				});
		}
	};

	const register = (name, onSave, onCancel, nothingToSave) => {
		const deferred = $.Deferred();
		targets.push({
			name: name,
			defer: $.Deferred(),
			save: function () {
				onSave(this.defer);
			},
			cancel: function () {
				onCancel();
			},
			dirty: 0,
		});
		nothingToSaveCallback = nothingToSave;
	};

	const dirty = (name, isDirty) => {
		if (typeof isDirty === 'undefined') {
			isDirty = true;
		}
		if (isDirty && !hasDirties()) {
			show();
			window.onbeforeunload = function () {
				return true;
			};
			$('#form-manager-save').removeClass('disabled');
			$('#form-manager-cancel').removeClass('disabled');
			selfElement.fadeIn();
		}
		for (var i = 0; i < targets.length; i++) {
			if (targets[i].name === name) {
				targets[i].dirty = isDirty ? 1 : 0;
			}
		}
		if (!isDirty && !hasDirties()) {

			window.onbeforeunload = null;

			if (!$('._buyer-settings-page').length) {
				$('#form-manager-save').addClass('disabled');
				$('#form-manager-cancel').addClass('disabled');
			}
			selfElement.fadeOut();
		}
		dirtyStateChanged();
	};

	const hasDirties = () => {
		var dirtyExists = false;
		targets.forEach(function (target) {
			dirtyExists = dirtyExists || target.dirty;
		});
		return dirtyExists;
	};

	const dirtyStateChanged = () => {
		const hasDirtiesData = hasDirties();
		dirtyListeners.forEach(function (callback) {
			callback(hasDirtiesData);
		});
	};

	const onDirtyStateChange = (callback) => {
		dirtyListeners.push(callback);
	};

	const getTarget = (name) => {
		try {
			for (var i = 0; i < targets.length; i++) {
				if (targets[i].name === name) {
					return targets[i];
				}
			}
		} catch (err) {
			return false;
		}
	};

	const getTargets = () => {
		return targets;
	};

	const resetDefers = (dirtyIndices) => {
		for (var i = 0; i < dirtyIndices.length; i++) {
			targets[dirtyIndices[i]].defer = $.Deferred();
		}
	};

	const save = () => {
		let dirtyTargets = [];
		let dirtyDefers = [];
		let dirtyIndices = [];
		for (let i = 0; i < targets.length; i++) {
			if (targets[i].dirty === 1) {
				dirtyTargets.push(targets[i]);
				dirtyDefers.push(targets[i].defer);
				dirtyIndices.push(i);
			}
		}

		$.when.apply(null, dirtyDefers).then(
			function () {
				resetDefers(dirtyIndices);
				endSubmit($('#form-manager-save'));
				nothingToSaveCallback && nothingToSaveCallback();
			},
			function (rejectedElement) {

				if (typeof rejectedElement === 'undefined') {
					rejectedElement = false;
				}

				// a rejected defer will need to pass in the $element
				if (rejectedElement) {
					$([document.documentElement, document.body]).animate(
						{
							scrollTop: $(rejectedElement).offset().top - HEADER_HEIGHT_OFFSET,
						},
						1000,
					);
				}
				resetDefers(dirtyIndices);
				endSubmit($('#form-manager-save'), SUBMIT_FAIL_MESSAGE, SUBMIT_FAIL_CLASS);
			},
		);

		dirtyTargets.forEach(function (target) {
			target.save();
		});
	};

	const cancel = () => {
		for (var i = 0; i < targets.length; i++) {
			if (targets[i].dirty === 1) {
				targets[i].cancel();
			}
		}
	};

	const checkNavSafe = () => {
		if (hasDirties()) {
			if (
				confirm(
                    _t('common_form-manager:confirm-navigation-away'),
				)
			) {
				cancel();
				return true;
			} else {
				return false;
			}
		}
		return true;
	};

	return {
		show,
		register,
		dirty,
		hasDirties,
		dirtyStateChanged,
		onDirtyStateChange,
		getTarget,
		resetDefers,
		save,
		cancel,
		checkNavSafe,
		endSubmit,
	};
};
