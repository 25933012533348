export default function BasicPhoneCheck() {

    var pc = window.basicPhoneCheck = window.basicPhoneCheck || {};

    /**
     * A list of invalid telephone numbers
     * @type Array
     */
    var invalidTelnos = [
        '07777777777',
        '07000000000',
        '01234567890',
        '07777777770',
        '01234467890',
        '07111111111',
        '07111223344',
        '07111223344',
        '07111555666',
        '07123456789',
        '07900000000',
        '07700000000',
        '07712345678',
        '07999999999',
        '01202000000',
        '01202000001',
        '01202111111',
        '01210000000',
        '01211111111',
        '01212121212',
        '01212122112',
        '01212202201',
        '01212212212',
        '01310000000',
        '01313131331',
        '01410000000',
        '01410000001',
        '01510000000',
        '01511001000',
        '01515000000',
        '01515150105',
        '01606000000',
        '01610000000',
        '01610000001',
        '01611111111',
        '01616000000',
        '01777777777',
        '01910000000',
        '01910909090',
        '01911111111',
        '01919999999',
        '02000000000',
        '02011111111',
        '02030000000',
        '02070000000',
        '02072002000',
        '02077227227',
        '02080000000'
    ];

    /**
     * Perform a basic test against a phone number
     * @param {string} tel The telephone number to test
     * @returns {object} An object with two properties, badChars (the number has illegal characters in it) and invalid (a known fake number)
     */
    pc.test = function (tel) {
        var isInvalid = false;
        var result = {
            invalid: false,
            badChars: tel.match(/[^0-9\.\-\s\+()]/)
        };

        for (var i = 0; i < invalidTelnos.length; i++) {
            var t = invalidTelnos[i];     // Remove all non numeric chars
            var intnl1 = t.replace(/^0/, '44');                 // Format to e.g. 447777777777
            var intnl2 = t.replace(/^0/, '0044');               // Format to e.g. 00447777777777

            // Look to see if the value is in the array
            if (Bark.in_array(tel, [t, intnl1, intnl2])) {
                isInvalid = true;
                break;
            }
        }

        if (Bark.array_unique(tel.replace(/^(\44|0044|0)/, '').split('')).length < 3) {
            // There are less than three unique numbers (excluding the leading zero/44) in the phone number
            isInvalid = true;
        }

        if (isInvalid) {
            result.invalid = true;
        }

        return result;
    };

}
