export function SellerCommsUpdatesHeaderManager() {
	try {
		Bark.api(
			'seller-comms/updates/read/',
			{},
			function (data) {
				var unseen = data.data.count_short_unseen;
				if (
					parseInt(unseen) > 0 &&
					$('#js-joyride-updates .js-updates-count-header').length < 1
				) {
					var unseen = '&bull;';
					var originalHtml = $('#js-joyride-updates').find('a').html();
					$('#js-joyride-updates')
						.find('a')
						.html(
							originalHtml +
								'<div class="position-relative"><span class="js-updates-count-header red-circle" style="top: -1.65em; right: -0.4em;"></span></div>',
						);
				}
			},
			function (err) {},
			'GET',
			Bark.apiVersionHeader('v2'),
		);
	} catch (e) {
		console.error(e);
	}
}
