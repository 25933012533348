import {
	sendManySellersAMessage,
	sendSellerAMessage as sendSellerAMessageApiCall,
	getLastMessage,
} from '../../components/apis/buyer-api';
import { useNamespace, _t } from '../../libs/i18next';

/**
 *
 * @param experimentTracking
 * @param source
 * @returns {{openModalSendToAll: openModalSendToAll, sendSellerAMessage: (function(*): (void|undefined)), openModal: openModal}}
 * @constructor
 */
export const initRequestQuoteFromSellerJs = ({
	experimentTracking,
	source = 'project-dashboard',
}) => {
	const { Handlebars } = window;

	// These need to match the names of your input or you will be toast
	const initialState = {
		message: '',
		companyName: '',
		bpvs: null,
		error: null,
		source,
		projectId: null,
		sellerProfileId: null,
	};

	let state = initialState;
	let afterRequestSuccess = null;

	const handleInputChange = (event) => {
		let { name, value } = event.target;
		state[name] = value;
	};

	const resetTextInputs = () => {
		Object.keys(state).forEach((key) => {
			return $(`input[name=${key}], textarea[name=${key}`).val('');
		});
	};

	const resetForm = () => {
		state = initialState;
		$('#buyer-quote-more-info-modal').data('multipleSellers', false);
		resetTextInputs();
	};

	const openModal = (seller, projectId, afterRequestSuccessFn) => {
		autofillMessage({ pridh: projectId });
		let {
			bpvsid: bpvs,
			company_name: companyName,
			id: sellerProfileId,
			avatar: avatarData,
		} = seller;

		let toRow = Handlebars.compile($('#request-quote-to-row').html());
		state = { ...initialState, projectId, companyName, sellerProfileId, bpvs };
		afterRequestSuccess = afterRequestSuccessFn;
		let html = toRow({
			companyName,
			avatarData,
		});

		$('.seller-targets').html(html);
		$('.sellerCountConfirmation').text(companyName);
		$('#buyer-quote-more-info-modal').modal('show');
		experimentTracking &&
			experimentTracking.submitExpActWithVal('quote-more-info-modal-view', {
				projectId,
				sellerProfileId: state.sellerProfileId,
			});

		bindListeners(experimentTracking);
	};

	const openModalSendToAll = (data) => {
		autofillMessage(data);
		state = { ...initialState, ...data };
		let { sellers } = data;
		let lcaseProString;

		let proString = _t('buyer_seller-request-quote:professionals.professional-count', {
			count: sellers.length,
		});

		lcaseProString = proString[0].toLowerCase() + proString.substring(1);

		$('#buyer-quote-more-info-modal').data('multipleSellers', true);
		$('.seller-targets').html(`<p>${proString}</p>`);
		$('.sellerCountConfirmation').text(lcaseProString);
		$('#buyer-quote-more-info-modal').modal('show');

		experimentTracking &&
			experimentTracking.submitExpActWithVal('quote-more-info-modal-view', {
				projectId: state.projectId ?? state.pridh ?? null,
				sellerProfileId: state.sellerProfileId ?? 'all-sellers',
			});
	};

	const validateFormData = ({ message }) => {
		if (message.length === 0) {
			state.error = _t('buyer_seller-list_request-quote:please-add-message');
			return false;
		}

		return true;
	};

	const showError = (error) => {
		error = error ? error : _t('buyer_seller-request-quote:error-message');
		$('#buyer-quote-more-info-modal .error-container').text(error).removeClass('d-none');
		experimentTracking &&
			experimentTracking?.submitExpActWithVal('quote-more-info-modal-fail', {
				projectId: state.projectId ?? state.pridh ?? null,
				sellerProfileId: state.sellerProfileId ?? 'all-sellers',
			});
	};

	const removeErrors = () => {
		$('#buyer-quote-more-info-modal .error-container').text('').addClass('d-none');
	};

	/**
	 * Toggle class can be a little confusing, but basically we want to add d-none when we want to hide it so
	 * we are using he inverse of show.
	 * @param show
	 */
	const toggleConfirmationView = (show) => {
		$('.confirmation-container').toggleClass('d-none', !show);
		$('.extra-info-form-container').toggleClass('d-none', show);
		if (show) {
			experimentTracking?.submitExpActWithVal('quote-more-info-modal-success', {
				projectId: state.projectId ?? state.pridh ?? null,
				sellerProfileId: state.sellerProfileId ?? 'all-sellers',
			});
			setTimeout(() => {
				closeModalAndReset();
			}, 1500);
		}
	};

	const sendSellerAMessage = (projectId) => {
		let isValid = validateFormData(state);

		if (!isValid) {
			return showError(state.error);
		}

		removeErrors();

		if ($('#buyer-quote-more-info-modal').data('multipleSellers') == true) {
			let { message, bpvid, sellers, pridh } = state;
			// Adding the check below as this is used for sending messages
			// to a single seller thorugh their public profile as well,
			// in which case the source should be different.
			let source = sellers.length === 1 ? 'buyer-interaction' : 'bulk-buyer-interaction';
			let multiSellerData = {
				message: message,
				bpvid: bpvid,
				source: source,
				spidhArray: sellers,
				projectIdHash: pridh,
			};

			if (!pridh) {
				window.bugsnagClient.notify(new Error('pridh is not set but should be'), {
					metaData: multiSellerData,
				});
			}

			sendManySellersAMessage(multiSellerData, (res) => {
				toggleConfirmationView(true);
				removeErrors();
				resetForm();
			});
		} else {
			sendSellerAMessageApiCall({ ...state, projectId }, (res) => {
				toggleConfirmationView(true);
				removeErrors();
				resetForm();
				afterRequestSuccess &&
					setTimeout(() => {
						afterRequestSuccess();
					}, 1600);
			});
		}
	};

	const closeModalAndReset = () => {
		$('#buyer-quote-more-info-modal').modal('hide');
		toggleConfirmationView(false);
		resetForm();
	};

	const bindListeners = (experimentTracking) => {
		$('#buyer-quote-more-info-modal textarea')
			.off('click.sendMessage')
			.on('change', (event) => {
				handleInputChange(event);
			});
		$('#buyer-quote-more-info-modal .submit-btn')
			.off('click.sendMessage')
			.on('click.sendMessage', () => {
				experimentTracking &&
					experimentTracking?.submitExpActWithVal('quote-more-info-modal-submit', {
						projectId: state.projectId ?? state.pridh ?? null,
						sellerProfileId: state.sellerProfileId ?? 'all-sellers',
					});
				sendSellerAMessage(state.projectId);
			});
		$('#buyer-quote-more-info-modal .close-button')
			.off('click.skip')
			.on('click.skip', () => {
				experimentTracking &&
					experimentTracking?.submitExpActWithVal('quote-more-info-modal-skip', {
						projectId: state.projectId ?? state.pridh ?? null,
						sellerProfileId: state.sellerProfileId ?? 'all-sellers',
					});
				closeModalAndReset();
				afterRequestSuccess && afterRequestSuccess();
			});
	};

	const autofillMessage = (data) => {
		let projectId = data.pridh ?? null;
		if (projectId) {
			$('#buyer-quote-more-info-modal .msg-box-spinner').removeClass('d-none');
			getLastMessage(
				{ type: 'request-quote', projectId },
				(res) => {
					if (res.data && res.data?.message) {
						$('#buyer-quote-more-info-modal #message-message')
							.val(res.data.message)
							.change()
							.focus();
					}
					$('#buyer-quote-more-info-modal .msg-box-spinner').addClass('d-none');
				},
				(error) => {
					$('#buyer-quote-more-info-modal .msg-box-spinner').addClass('d-none');
				},
			);
		}
	};

	bindListeners(experimentTracking);

	return {
		openModal,
		sendSellerAMessage,
		openModalSendToAll,
	};
};
