export default function () {

    function navigate(direction, scope) {
        var nMoved = scope.data('moved') || 0;
        var viewWidth = scope.width();
        var cardWidth = $('.page-element:first', scope).width();
        var nItems = $('.page-element', scope).length;
        var nToMoveUncalc = Math.floor(viewWidth / cardWidth);
        var nToMove;
        var moved;

        if (direction === 'left') {
            nToMove = Math.min(nToMoveUncalc, nMoved);
            moved = nMoved - nToMove;
            if (moved < 0) {
                moved = 0;
            }
        } else if (direction === 'right') {
            nToMove = Math.min(nToMoveUncalc, nItems - nToMoveUncalc - nMoved);
            moved = nMoved + nToMove;
        } else if (direction === 'none') {
            nToMove = 0;
            moved = Math.min(nMoved + nToMove, nItems - nToMoveUncalc);
        } else {
            console.error('Unknown direction ' + direction);
            return;
        }

        scope.data({moved: moved});
        $('.page-element', scope).css({transform: 'translateX(-' + (moved * 100) + '%)'});
        $('.next-page', scope)[(moved >= (nItems - nToMoveUncalc)) ? 'addClass' : 'removeClass']('disabled');
        $('.previous-page', scope)[moved < 1 ? 'addClass' : 'removeClass']('disabled');

        // Hide the controls if not enough cards to paginate
        $('.page-buttons', scope)[nItems <= nToMoveUncalc ? 'addClass' : 'removeClass']('hidden');
    }

    $(function () {

        $('.directory-pagination-section .previous-page').off('click.navigate').on('click.navigate', function () {
            var t = $(this);

            if (!t.hasClass('disabled')) {
                navigate('left', t.closest('.directory-pagination-section'));
            }
        });

        $('.directory-pagination-section .next-page').off('click.navigate').on('click.navigate', function () {
           var t = $(this);

            if (!t.hasClass('disabled')) {
                navigate('right', t.closest('.directory-pagination-section'));
            }
        });

        $(window).off('resizeEnd.paginationArrows').on('resizeEnd.paginationArrows', function () {
            $('.directory-pagination-section').each(function () {
                navigate('none', $(this));
            });
        });

        navigate('none', $(this));

    });
}
