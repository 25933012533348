export function getDefaultPayloadForStartBark() {
    let payload = {
        ...getBasePayloadObject(),
        category_id: Number($('#category_id').val()),
        postcode_id: $('#postcode_id').val(),
        postcode_type: $('#postcode_type').val(),
        category_name: $('#category_name').val() || $('#category_name_hidden').val()
    }

    return convertToFormData(payload);
}

export function getPayloadForStartBarkFromHeader() {
    let payload = {
        ...getBasePayloadObject(),
        category_id: $('#category_id_top').val(),
        postcode_id: $('#postcode_id_top').val() || -1,
        postcode_type: $('#postcode_type_top').val() || -1,
        category_name: $('#category_name_top').val()
    }

    return convertToFormData(payload);
}

export function getPayloadForPopupTrue({locationId, postCodeType, alid, locact}) {
    let payload = {
        ...getBasePayloadObject(),
        category_id: $('#category_id').val(),
        postcode_id: locationId,
        postcode_type: postCodeType,
        exp_ph: 0,
        bark_mode: $('#bark_mode').val(),
        category_name: $('#category_name').val(),
        alid: alid,
        locact: locact
    }

    return convertToFormData(payload);
}

export function convertToFormData(payload) {
    var formData = new FormData();

    for ( let key in payload ) {
        formData.append(key, payload[key]);
    }

    return formData;
}

function getBasePayloadObject() {
    return {
        exp_ph: 0,
        bark_mode: $('#bark_mode').val()
    }
}
