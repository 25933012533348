import {
    DirectoryPagination,
    DirectoryV3,
    DirectoryOpenBark,
} from "../directory/helpers";

import {
    hasHeroQuestionComponent,
    initHeroQuestion,
} from "./components/hero/hero";
import {
    isWebPresencePage,
    initWebPresencePage,
} from "./components/landing-page/web-presence";
import {
    isReferAFriendPage,
    initReferAFriendPage,
} from "./components/refer-a-friend/main";

export const isBuilder = !!document.querySelector(".builder-page");

export const initBuilder = () => {
    console.log("builder page start...");

    /* run scripts */
    if (hasHeroQuestionComponent()) {
        initHeroQuestion();
    }

    if (isWebPresencePage()) {
        initWebPresencePage();
    }

    if (isReferAFriendPage()) {
        initReferAFriendPage();
    }

    DirectoryPagination();
    DirectoryV3();
    DirectoryOpenBark();
};
