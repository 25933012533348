import { isBuilder } from '../components/builder';
import { isDirectory } from '../components/directory';
import { useNamespace } from '@bark/bark-core';

export const routerDom = async () => {
	await useNamespace(['directory']);
	// builder pages
	if (isBuilder) {
		await useNamespace(['directory_contentful-pages']);
		const { initBuilder } = await import('../components/builder');
		initBuilder();
	}

	// directory pages
	if (isDirectory) {
		const { initDirectory } = await import('../components/directory');
		initDirectory();
	}
};
