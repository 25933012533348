import { getCreateBark } from "../../../legacy-create-bark/helpers/general";
import {
    isUpsell,
    isLocation,
} from "../../../legacy-create-bark/helpers/steps";
import { hasQuestionType } from "../../../legacy-create-bark/helpers/questions";

// state
export const getNewRequestState = () => {
    const cb = getCreateBark();
    if (!cb.new_request_flow) {
        cb.new_request_flow = {};
    }
    return cb.new_request_flow;
};

export const setNewRequestState = (state) => {
    const cb = getCreateBark();
    cb.new_request_flow = {
        ...state,
    };
};

export const updateNewRequestState = (state) => {
    const cb = getCreateBark();
    cb.new_request_flow = {
        ...getNewRequestState(),
        ...state,
    };
};

// checkers
export const isNewRequestFlow = () => {
    const state = getNewRequestState();
    return state.flow === "new_request_flow";
};
export const isGoBackToNewRequestFlow = () => (
    isNewRequestFlow() &&
    ((isUpsell() && !hasQuestionType("postcode")) || isLocation())
);

export const skipUpsellLoadingNewRequestFlow = () => (
    isNewRequestFlow() &&
    getNewRequestState().skipStep !== "location" &&
    getNewRequestState().skipStep !== "upsellLoading"
);
export const isSkipToStep = () => (
    getNewRequestState().payload && getNewRequestState().skipStep !== ""
);

// getters
export const getAnswers = () => {
    const state = getNewRequestState();
    return state.answers;
};

//functions
export const getAndRemovePreSelectAnswer = (questionName) => {
    const hasValue = getNewRequestState()?.preSelectAnswers[questionName]?.value;
    const value = getNewRequestState()?.preSelectAnswers[questionName]?.value || [];
    if(hasValue){
        delete getNewRequestState()?.preSelectAnswers[questionName];
    }

    return value;
}

