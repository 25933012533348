import { parsePhoneNumber } from 'libphonenumber-js';
import { bugsnagClientNotify } from '../components/bugsnag/bugsnag-client';

export function formatPhoneNumber(phoneNumber) {
    let phoneNumberToDisplay = '', phoneNumberToDial = '#';

    if (phoneNumber && phoneNumber.length) {
        // attempt to parse and format the number
        try {
            const parsedNumber = parsePhoneNumber(
                phoneNumber,
                Bark.ENV.locale.toUpperCase(),
            );
            phoneNumberToDisplay = parsedNumber.formatNational();
            phoneNumberToDial = parsedNumber.getURI();
        } catch (e) {
            bugsnagClientNotify('Error formatting phone number', e);
        }
        // Fallback to provided number
        if (phoneNumberToDisplay === '') {
            phoneNumberToDisplay = phoneNumber;
            // remove any possible extension, or any non-numeric characters
            phoneNumberToDial =
                'tel:' +
                phoneNumberToDisplay
                    .toLowerCase()
                    .split('x')[0]
                    .replace(/[^0-9+]/, '');
        }
    }

    return { 'phoneNumberToDisplay': phoneNumberToDisplay, 'phoneNumberToDial': phoneNumberToDial };
}