import Markdown from "../../../legacy-scripts/markdown-parser-light";
import { useNamespace, _t } from '../../../../libs/i18next';

export default async function AdditionalDetailsDesignPicker(options) {

    await useNamespace(['buyer_create-bark_additional-details']);

    const { Handlebars } = window;

    /**
     * Wrapper for document.querySelector and document.querySelectorAll, as these cannot be destructed
     * @param {string} selector
     * @param {boolean} all
     * @returns {HTMLElement|NodeListOf<Element>}
     */
    const query = (selector, all = false) => {
        return document[all ? 'querySelectorAll' : 'querySelector'](selector);
    };

    /**
     * Options to be used in this class
     * @type {any}
     */
    const opts = Object.assign({
        subheadingMarkdown: null,
        allowFiles: true,
    }, options);

    /**
     * Render a subheading
     * @returns {*}
     */
    const renderSubheading = () => {
        if (!opts.subheadingMarkdown) {
            return '';
        }

        const templateElement = query('#additional-details-subheading-template');
        const template = Handlebars.compile(templateElement.innerHTML);
        return template({
            subheading_html: Markdown(opts.subheadingMarkdown)
        });
    };

    /**
     * Additional details modal
     * @type {HTMLElement}
     */
    const additionalDetailsModal = query('#additional-details-modal-with-progress');

    /**
     * @type {HTMLElement}
     */
    const submitButton = query('.js-do-submit-additional-details');

    /**
     * Default settings
     * @type {object}
     */
    const common = {
        submitLabel: _t("buyer_create-bark_additional-details:submit-label"),
        textAreaSelector: '#project-detail-text',
        textHeader: _t("buyer_create-bark_additional-details:text-header"),
    };

    /**
     * Clear text area error
     */
    const clearTextAreaError = () => {
        const projectDetailError = query('#project-detail-error');
        const textArea = query(common.textAreaSelector);

        projectDetailError.classList.add('d-none');
        textArea.classList.remove('has-error');

        textArea.removeEventListener('change', clearTextAreaError);
    };

    /**
     * Validate additional details
     * @param {boolean} hasAdditionalDetails
     * @returns {boolean}
     */
    const validateAdditionalDetails = hasAdditionalDetails => {
        const projectDetailError = query('#project-detail-error');
        const textArea = query(common.textAreaSelector);

        if (hasAdditionalDetails) {
            return true;
        }

        projectDetailError.innerText = _t("buyer_create-bark_additional-details:description-error");
        projectDetailError.classList.remove('d-none');
        textArea.classList.add('has-error');

        textArea.addEventListener('keyup', clearTextAreaError);

        return false;
    };

    /**
     * Simulate an event
     * @param {HTMLElement} element
     * @param {string} eventType
     * @param {object} eventProperties
     */
    const simulateEvent = (element, eventType, eventProperties = {}) => {
        const e = new CustomEvent(eventType, { detail: eventProperties });
        element.dispatchEvent(e);
    };

    /**
     * Init an additional details experiment variant
     * @returns {Object}
     */
    const init = () => {
        let result;

        if (opts.subheadingMarkdown) {
            additionalDetailsModal.classList.add(`category-specific`);
            result = initCategorySpecificDesign();
        } else {
            result = initRegularDesign();
        }

        simulateEvent(query(common.textAreaSelector), 'keyup');

        return result;
    };

    /**
     * Initialise the regular design
     * @returns {object}
     */
    const initRegularDesign = () => {
        return Object.assign(common, {
            allowFiles: opts.allowFiles,
            submitLabel: _t("buyer_create-bark_additional-details:update-request"),
            textSubheading: _t("buyer_create-bark_additional-details:add-more-details")
        });
    };

    /**
     * Initialise the category specific design
     * @returns {object}
     */
    const initCategorySpecificDesign = () => {
        submitButton.classList.remove('disabled');
        submitButton.classList.add('always-enabled');

        return Object.assign(common, {
            allowFiles: opts.allowFiles,
            submitLabel: _t("buyer_create-bark_additional-details:continue-label"),
            textSubheading: renderSubheading, // This will now be enabled in version two of this experiment
            onValidate: validateAdditionalDetails,
        });
    };

    return init();
}
