import { getCreateBark } from './general';

//checkers
export const getCurrentQuestion = () => getCreateBark()?.questions[getCreateBark()?.pos];
export const getCurrentQuestionType = () => getCurrentQuestion()?.type;
export const isCurrentQuestionType = (name) => getCurrentQuestionType() === name;
export const hasPreviousQuestion = () => getNextValidPrevious(getCreateBark().pos,getCreateBark().skippedQuestionPositions).hasPrevious;
export const getQuestions = () => getCreateBark().questions;
export const hasQuestionType = (name) => getQuestions().filter(item => item.type === name).length > 0;
export const hasQuestionTypeOrName = (name) => (
    getQuestions().filter((item) => item.type === name || item.name === name)
        .length > 0
);
export const getQuestionTypeOrNameIndex = (name) => (
    getQuestions().findIndex(
        (item) => item.type === name || item.name === name
    )
);

export const getCurrentQuestionName = () => getCurrentQuestion()?.name;
export const isCurrentQuestionName = (name) => (
    getCurrentQuestionName() === name
)

//functions
export const getNextValidPrevious = (position, skippedPositions) => {
    if (position <= 0) {
        return {hasPrevious: false, prevIndex: 0}
    }

    const previousPosition = position - 1;

    if (skippedPositions.includes(previousPosition)) {
        return getNextValidPrevious(previousPosition, skippedPositions);
    }

    return {
        hasPrevious: true,
        prevIndex: previousPosition
    };
}