import {
	PhoneComplianceMessageIsEnabled,
	DisplayPhoneComplianceMessage,
} from './../legacy-create-bark/features/phone-compliance-message';
import { useNamespace, _t } from '../../libs/i18next';

export default function ContactVerification() {
	useNamespace('common_contact-verification').then(() => {
		/**
		 * The Bark global
		 * @type window.Bark
		 */
		const Bark = (window.Bark = window.Bark || {});

		/**
		 * The Create Bark namespace
		 * @type window.Bark.createBark
		 */
		const cb = (Bark.createBark = Bark.createBark || {});

		/**
		 * @type window.Bark.createBark.contactVerification
		 */
		const contactVerification = (cb.contactVerification = cb.contactVerification || {});

		/**
		 * Verify the buyer's contact details if needed
		 * @param {object} data
		 */
		contactVerification.verifyIfNeeded = (data, phoneNumber = '') => {
			const pid = data.project_id_hash;

			determineContactVerificationStatus('phone', pid)
				.then((performSmsVerification) => {
					if (performSmsVerification) {
						// this is an old function which will trigger inside , and could be refactored
						contactVerification.smsVerificationCodeAfterBark(
							pid,
							performSmsVerification,
							phoneNumber,
						);
					} else {
						cb.showAdditionalDetailsOrRedirect(pid);
					}
				})
				.catch(() => {
					cb.showAdditionalDetailsOrRedirect(pid);
				});
		};

		/**
		 * Determine if a contact verification should be performed
		 * @param {string} contactType
		 * @param {string} pid
		 * @returns {Promise}
		 */
		let determineContactVerificationStatus = (contactType, pid) => {
			const payload = {
				identifierType: 'pid',
				identifier: pid,
				scope: 'buyer',
				contactMethodType: contactType,
			};

			return new Promise((resolve, reject) => {
				Bark.api(
					'contact-verification/determine',
					payload,
					function (data) {
						if (data.status) {
							resolve(data.perform_sms_verification);
							return;
						}

						reject();
					},
					function () {
						reject();
					},
					'POST',
					Bark.apiVersionHeader('v2'),
				);
			});
		};

		/**
		 * Trigger contact verification
		 * @param {string} projectId The hashed project ID
		 * @returns {Promise}
		 */
		let triggerContactVerification = (projectId) => {
			return new Promise((resolve, reject) => {
				const payload = {
					identifierType: 'pid',
					identifier: projectId,
					scope: 'buyer',
					contactMethodType: 'phone',
				};

				Bark.api(
					'contact-verification/send-verification',
					payload,
					function (data) {
						if (data.status) {
							resolve(data);
						} else {
							reject(data);
						}
					},
					function (error) {
						reject(error);
					},
					'POST',
					Bark.apiVersionHeader('v2'),
				);
			});
		};

		/**
		 *
		 * @param {string} projectId
		 * @param {string} code
		 * @param {boolean} skip
		 * @param {function} successCallback
		 * @param {function} failCallback
		 * @author Lord Gourarie
		 */
		let submitContactVerification = (
			projectId,
			code,
			skip,
			successCallback = null,
			failCallback = null,
		) => {
			const payload = {
				identifierType: 'pid',
				identifier: projectId,
				scope: 'buyer',
				code: code,
				contactMethodType: 'phone',
			};

			if (skip) {
				payload['skipping'] = true;
			}

			cb.tagManager('bark-modal:tel-verify:submit');

			Bark.api(
				'contact-verification/verify/',
				payload,
				function (data) {
					if (data.status) {
						cb.tagManager('bark-modal:tel-verify:success');
						cb.createBarkTracking.logModalPageEvent(
							$('#category_id').val(),
							cb.preBarkId,
							'SMS Verification',
							'success',
							{},
							{},
						);
						successCallback && successCallback(data);
					} else {
						cb.tagManager('bark-modal:tel-verify:error');
						cb.createBarkTracking.logModalPageEvent(
							$('#category_id').val(),
							cb.preBarkId,
							'SMS Verification',
							'fail',
							{},
							{},
						);
						failCallback && failCallback(data);
					}
				},
				function (error) {
					cb.tagManager('bark-modal:tel-verify:error');
					cb.createBarkTracking.logModalPageEvent(
						$('#category_id').val(),
						cb.preBarkId,
						'SMS Verification',
						'error',
						{},
						{},
					);
					failCallback && failCallback(error);
				},
				'POST',
				Bark.apiVersionHeader('v2'),
			);
		};

		/**
		 * @author Lord Gourarie
		 */
		let smsPostBarkVerificationFailureCallback = () => {
			smsVerificationShow('sms-form');
			$('.sms-errors')
				.text(_t('common_contact-verification:errors.sms-code-incorrect'))
				.removeClass('d-none');
		};

		/**
		 * @author Lord Gourarie
		 */
		let smsPostBarkVerificationCallback = (data, projectId) => {
			smsVerificationShow(false);
			if (data.status && !data.error) {
				$('#sms-verification-modal').modal('hide');
				cb.showAdditionalDetailsOrRedirect(projectId);
			} else {
				smsVerificationShow('sms-form');
				$('.sms-errors')
					.text(_t('common_contact-verification:errors.sms-code-incorrect'))
					.removeClass('d-none');
			}
		};

		/**
		 *
		 * @param phone
		 * @param successCallback
		 * @param failureCallback
		 * @param projectId
		 * @returns {boolean}
		 * @author Lord Gourarie
		 */
		let smsVerificationSubmit = (phone, successCallback, failureCallback, projectId) => {
			const errorsElem = $('.sms-errors');
			const codeElem = $('#af-verification-code');
			const validCode = codeElem.val().match(/[0-9]{4}/);
			let code;

			errorsElem.toggleClass('d-none', true);

			if (validCode == null) {
				errorsElem
					.text(_t('common_contact-verification:errors.full-code-required'))
					.removeClass('d-none');
				cb.createBarkTracking.logModalPageEvent(
					$('#category_id').val(),
					cb.preBarkId,
					'SMS Verification',
					'fail',
					{},
					{},
				);
				return false;
			}

			code = codeElem.val();
			smsVerificationShow('verify');
			submitContactVerification(projectId, code, false, successCallback, failureCallback);
		};

		/**
		 *
		 * @param showPart
		 * @author Lord Gourarie
		 */
		let smsVerificationShow = (showPart) => {
			const elem = $('.inline-bark-sms-phone-verification')
				.removeClass('sms-form')
				.removeClass('verify')
				.removeClass('change-phone')
				.removeClass('sending')
				.removeClass('sent');

			if (showPart) {
				elem.addClass(showPart);
			}

			if (PhoneComplianceMessageIsEnabled(cb.categoryData?.features)) {
				DisplayPhoneComplianceMessage(
					showPart,
					cb.categoryData.features,
					$('#sms-verification-modal'),
				);
			}
		};

		/**
		 *
		 * @param projectId
		 * @param phone
		 * @author Lord Gourarie
		 */
		let bindModalEvents = (projectId, phone) => {
			const verificationElem = $('#af-verification-code');

			verificationElem.on(
				'input keydown keyup mousedown mouseup contextmenu drop',
				function () {
					let input = $(this).val().replace(/\D+/g, '').substring(0, 4);
					$(this).val(input);
				},
			);

			verificationElem.on('keyup', function (e) {
				if (e.keyCode === 13) {
					$('.sms-verification-continue').click();
				}
			});

			verificationElem.on('change', function () {
				let code = $(this).val().split('');

				for (let i = 0; i < code.length; i++) {
					let codeElem = $('#otp-code-' + i);
					if (codeElem.val() !== code[i]) {
						codeElem.val(code[i]);
					}
				}
			});

			$('.sms-verification-form .code-input').on(
				'keydown contextmenu drop',
				function (e) {
					let input = $(this);
					let inputIndex = $(this).data('index');

					if (!e.key) {
						return;
					}

					function updateCode() {
						let codeArray = verificationElem.val().split('');
						for (let i = 0; i < 4; i++) {
							codeArray[i] = $('#otp-code-' + i).val();
							if (codeArray[i] === '') {
								codeArray[i] = ' ';
							}
						}
						verificationElem.val(codeArray.join(''));
					}

					function prevInput(inputIndex) {
						let firstInputIndex = 0;
						if (inputIndex !== firstInputIndex) {
							setTimeout(function () {
								input.prev().focus();
								input.prev().select();
							}, 0);
						}
					}

					function nextInput(inputIndex) {
						let lastInputIndex = 3;
						if (inputIndex !== lastInputIndex) {
							setTimeout(function () {
								input.next().focus();
								input.next().select();
							}, 0);
						}
					}

					let arrowLeft = 37;
					if (e.keyCode === arrowLeft) {
						return prevInput(inputIndex);
					}
					let arrowRight = 39;
					if (e.keyCode === arrowRight) {
						return nextInput(inputIndex);
					}

					let enter = 13;
					if (e.keyCode === enter) {
						$('.sms-verification-continue').click();
						return;
					}

					let inputKey = e.key;
					// If this key is not keys 0-9 or numpad 0-9. Yep, they're different
					if (
						!(
							(e.keyCode >= 48 && e.keyCode <= 57) ||
							(e.keyCode >= 96 && e.keyCode <= 105)
						)
					) {
						inputKey = '';
					}

					if (e.key === '') {
						return;
					}

					$(this).val(inputKey);
					updateCode();
					if (inputKey && inputKey.length > 0) {
						nextInput(inputIndex);
					}
				},
			);

			$('#sms-verification-change-user-telephone').on('keyup', function (e) {
				if (e.keyCode === 13) {
					$('.update-phone-continue').click();
				}
			});

			$('.sms-verification-continue')
				.off('click.verificationContinue')
				.on('click.verificationContinue', function () {
					smsVerificationSubmit(
						phone,
						function (data) {
							smsPostBarkVerificationCallback(data, projectId);
						},
						smsPostBarkVerificationFailureCallback,
						projectId,
					);
				});

			$('.sms-verification-btn-change-phone')
				.off('click')
				.on('click', function () {
					smsVerificationShow('change-phone');
					$('.modal-header h4').hide();
					$('#sms-verification-change-user-telephone').val(phone);
					$('.update-phone-continue')
						.off('click')
						.on('click', function () {
							$('.sms-errors').text('').addClass('d-none');
							$('.change-number-error').toggleClass('d-none', true);
							if (
								phone.replace(/\D/g, '') ===
								$('#sms-verification-change-user-telephone').val().replace(/\D/g, '')
							) {
								smsVerificationShow('sms-form');
								$('.modal-header h4').show();
								return;
							}
							phone = $('#sms-verification-change-user-telephone').val();
							smsVerificationShow('sending');
							Bark.api(
								'buyer/telephone/',
								{ tel: phone },
								function (data) {
									if (data && !data.is_mobile) {
										// the user entered something other than a mobile, so we should go to the email
										// verification experiment and check if we need to do that
										submitContactVerification(projectId, '5', true);

										$('#sms-verification-modal').modal('hide');

										cb.showAdditionalDetailsOrRedirect(projectId);
									} else {
										triggerContactVerification(projectId)
											.then(function () {
												phone = $('#sms-verification-change-user-telephone').val();
												smsVerificationShow('sms-form');
												$('.modal-header h4').show();
												$('.number-to-verify').text(phone);
												$('.sms-verification-continue')
													.off('click')
													.on('click', function () {
														smsVerificationSubmit(
															phone,
															function (data) {
																smsPostBarkVerificationCallback(data, projectId);
															},
															smsPostBarkVerificationFailureCallback,
															projectId,
														);
													});
											})
											.catch(function () {
												phone = $('.number-to-verify').text();
												smsVerificationShow('sms-form');
												$('.modal-header h4').show();
											});
									}
								},
								function () {
									smsVerificationShow('change-phone');
									$('.modal-header h4').hide();
									$('.change-number-error').toggleClass('d-none', false);
									$('.change-number-error p').text(
										_t('common_contact-verification:errors.change-number.save-failed'),
									);
								},
								'POST',
								Bark.apiVersionHeader('v1'),
							);
						});
				});

			$('.js-resend-code')
				.off('click')
				.on('click', function () {
					$('.sms-errors').text('').addClass('d-none');
					smsVerificationShow('sending');

					triggerContactVerification(projectId)
						.then(() => {
							smsVerificationShow('sms-form');
						})
						.catch(() => {
							smsVerificationShow('sms-form');
						});
				});
		};

		/**
		 *
		 * @param {string} projectId
		 * @param {boolean} skippable
		 * @author Lord Gourarie
		 */
		contactVerification.smsVerificationCodeAfterBark = function (
			projectId,
			skippable,
			phoneNumber = '',
		) {
			const phone = phoneNumber === '' ? cb.gut() : phoneNumber;
			let showSmsModal = true;

			skippable = false; // Turn off

			triggerContactVerification(projectId)
				.then((data) => {
					if (!data.status) {
						cb.showAdditionalDetailsOrRedirect(projectId);
						return;
					}
					$('.sms-verification-continue').text(
						_t('common_contact-verification:confirm-button'),
					);
					smsVerificationShow('sms-form');
					$('.sms-errors').text('').toggleClass('d-none', true);
					if (window.Bark.isMobile()) {
						$('.sms-verification-form .code-input').addClass('d-none');
						$('#af-verification-code').removeClass('d-none').focus();
					} else {
						$('#otp-code-0').focus();
					}
					$('.number-to-verify').text(phone);

					bindModalEvents(projectId, phone);

					$('.sms-verification-buttons .sms-verification-btn-back').html('');

					if (skippable) {
						$('.sms-verification-btn-skip').removeClass('d-none');
						$('.sms-verification-buttons .sms-verification-btn-back').remove();
						$('.sms-verification-btn-skip')
							.off('click')
							.on('click', function () {
								$('#sms-verification-modal').modal('hide');
								submitContactVerification(projectId, '1', true);
								cb.showAdditionalDetailsOrRedirect(projectId);
							});
					}
				})
				.catch(() => {
					submitContactVerification(projectId, '1', false);

					cb.showAdditionalDetailsOrRedirect(projectId);

					showSmsModal = false;
				})
				.finally(() => {
					$('#inlineBarkModal').modal('hide');

					if (showSmsModal) {
						$('#sms-verification-modal').modal('show');

						cb.createBarkTracking.logModalPageEvent(
							$('#category_id').val(),
							cb.preBarkId,
							'SMS Verification',
							'view',
							{},
							{},
						);
					}
				});
		};
	});
}
