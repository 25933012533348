import { _t, useNamespace } from '../../libs/i18next';

//
// Email Address Validation Plugin
//
// Attaching to a form:
//
//    $('jquery_selector').email_validator({
//        in_progress: in_progress_callback, // called when request is made to validator
//        success: success_callback,         // called when validator has returned
//        error: validation_error,           // called when an error reaching the validator has occured
//    });
//
//
// Sample JSON in success callback:
//
//  {
//      "did_you_mean": null,
//      "email": "john.smith@example.com",
//      "error_message": null,
//      "is_disposable": false,
//      "is_high_risk": false,
//      "is_role_address": false,
//      "is_valid": true,
//      "normalised_email": "john.smith@example.com",
//      "risk": "low",
//  }
//

export default function EmailValidator() {
	$.fn.email_validator = function (options) {
		return this.each(function () {
			var thisElement = $(this);
			//Trim string and autocorrect whitespace issues
			var elementValue = thisElement.val();
			elementValue = $.trim(elementValue);
			thisElement.val(elementValue);
			run_validator(elementValue, options, thisElement);
		});
	};

	function run_validator(address_text, options, element) {
		var success = false;

		//Abort existing AJAX Request to prevent flooding
		if (element.mailgunRequest) {
			element.mailgunRequest.abort();
			element.mailgunRequest = null;
		}

		// don't run validator without input
		if (!address_text) {
			return;
		}

		// validator is in progress
		if (options && options.in_progress) {
			options.in_progress(options.e);
		}
		// don't run dupicate calls
		if (element.mailgunLastSuccessReturn) {
			if (address_text == element.mailgunLastSuccessReturn.address) {
				if (options && options.success) {
					options.success(element.mailgunLastSuccessReturn, options.e);
				}
				return;
			}
		}

		// length and @ syntax check
		var error_message = false;
		if (address_text.length > 512)
			error_message = _t('common_email-validator:errors.email-too-long');
		else if (1 !== address_text.split('@').length - 1)
			error_message = _t('common_email-validator:errors.email-invalid');

		if (error_message) {
			if (options && options.error) {
				options.error(error_message, options.e);
			} else {
				if (console) console.log(error_message);
			}
			return;
		}

		// timeout incase of some kind of internal server error
		var timeoutID = setTimeout(function () {
			error_message = _t('common_email-validator:errors.generic');
			if (!success) {
				//Abort existing AJAX Request for a true timeout
				if (element.mailgunRequest) {
					element.mailgunRequest.abort();
					element.mailgunRequest = null;
				}

				if (options && options.error) {
					options.error(error_message, options.e);
				} else {
					if (console) console.log(error_message);
				}
			}
		}, 10000); //10 seconds

		// make ajax call to get validation results
		element.mailgunRequest = $.ajax({
			type: 'POST',
			url: 'https://api.bark.com/bark_data_validator/email/',
            data: {
                email: address_text
            },
			success: function (data) {
				success = true;
				clearTimeout(timeoutID);

				element.mailgunLastSuccessReturn = data;
				if (options && options.success) {
					options.success(data, options.e);
				}
			},
			error: function (request, status_text, error) {
				success = true;
				clearTimeout(timeoutID);
				error_message = _t('common_email-validator:errors.generic');

				if (options && options.error) {
					options.error(error_message, options.e);
				} else {
					if (console) console.log(error_message);
				}
			},
		});
	}
}
