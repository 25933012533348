import flagsmith from 'flagsmith';

if (window.bugsnagOptions?.user?.user_id) {
	flagsmith.identify(bugsnagOptions.user.user_id);
}

flagsmith.init({
	environmentID: window.Bark?.ENV?.fsmith,
	enableAnalytics: false,
	cacheFlags: true,
	preventFetch: true,
});
