import { tracking } from "../../../analytics";

export const DisplayPhoneComplianceMessage = (step, features, $modalContainer) => {

    if (step === "accountTel"
        || step === "submitExistingBuyer"
        || step === "change-phone"
        || step === "buyerInfo") {
        if ($modalContainer.find('#tcpa-message').length == 0) {
            $modalContainer
                .find('.modal-body').first().after(
                    Bark.getHtml(
                        'div',
                        features?.phoneComplianceMessage,
                        'tcpa-message',
                        'bg-light rounded x-small pt-4 text-light-grey'
                    )
                );

            tracking("Place Bark", "Display Phone Compliance Message", {});
        } else {
            $modalContainer.find('#tcpa-message').show();
        }
    } else {
        PhoneComplianceMessageRemove($modalContainer);
    }
}

export const PhoneComplianceMessageIsEnabled = (features) => {
    return features?.phoneComplianceMessage;
}

export const PhoneComplianceMessageRemove = ($modalContainer) => {
    $modalContainer.find('#tcpa-message').remove();
}
