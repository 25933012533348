export const getUserData = () => {
    const user = window?.Bark?.USER
    const env = window?.Bark?.ENV
    const locale = window?.Bark?.LOCALE
    return {
        ...user?.secureUserIdForChameleon && {uid_hash: user.secureUserIdForChameleon}, // [MANDATORY] Chameleon Secure Mode
        ...user?.createdAt && {created: user.createdAt},
        ...user?.services && {services: user.services},
        ...user?.servicesList && {services_list: user.servicesList},
        ...env?.bes_token && {bes_token: env.bes_token},
        ...locale?.iso2c && {country_code: locale.iso2c},
        ...user?.userId && {bark_user_id: user.userId},
        ...(user?.createdAt && getISODateStringFromUnixTime(user?.createdAt)) && {bark_signup_date: getISODateStringFromUnixTime(user.createdAt)},
        site: window?.location?.hostname,
    };
}

const getISODateStringFromUnixTime = (unixTime) => {
    const unixTimeMs = unixTime * 1000 // Milliseconds
    const unixTimeDate = new Date(unixTimeMs)
    if (dateIsValid(unixTimeDate)) {
        return unixTimeDate.toISOString();
    }
    return null;
}

const dateIsValid = (date) => {
    return date instanceof Date && !isNaN(date);
}
