import moment from 'moment';
import { getLang } from "../helpers/locale";
import "moment/locale/de";
import "moment/locale/fr";

export const reloadMomentLocale = (m) => {
    m.locale(getLang());
    return m;
}

export const momentLocale = () => {
    moment.locale(getLang());
    return moment;
};
