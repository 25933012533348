import { getCreateBark } from './general';
import {
    isCurrentQuestionType,
    isCurrentQuestionName,
    getCurrentQuestionName,
    hasQuestionTypeOrName,
    getQuestionTypeOrNameIndex,
} from "./questions";
import { _t } from "../../../libs/i18next";

//checkers
export const isUpsell = () => isCurrentQuestionType("upsell");
export const isLocation = () => isCurrentQuestionType("postcode");
export const isQuestion = () =>
    getCurrentQuestionName() && !isCurrentQuestionName("location");
export const isEmail = () => isCurrentQuestionType("accountEmail");
export const isTelephone = () => isCurrentQuestionType("accountTel");
export const isName = () => isCurrentQuestionType("accountName");
export const afterQuestion = () => isQuestion();
export const afterLocation = () => isQuestion() || isLocation();
export const afterEmail = () => isQuestion() || isLocation() || isEmail();
export const afterTelephone = () =>
    isQuestion() || isLocation() || isEmail() || isTelephone();

//add steps
export const addLoadingStep = (questions) => {
    questions.push({
        gaPath: "loading-indicator",
        hideNavigation: true,
        hideTop: true,
        omitFromCount: true,
        show: false,
        showOnce: true,
        type: "upsellLoading",
    });
};

export const addUpsellStep = (questions) => {
    questions.push({
        gaPath: "upsell",
        fbPath: "Upsell",
        hideTop: true,
        omitFromCount: true,
        type: "upsell",
    });
};
export const addNameStep = (questions) => {
    questions.push({
        gaPath: "account__name",
        hideProgress: true,
        label: _t("buyer_create-bark:separated-buyer-info.name.label-in-question-form"),
        labelExtra: null,
        omitFromCount: true,
        type: "accountName",
    });
};
export const addTelephoneStep = (questions) => {
    questions.push({
        gaPath: 'account__tel',
        hideProgress: true,
        label: _t("buyer_create-bark:separated-buyer-info.phone.label-in-question-form"),
        labelExtra: null,
        omitFromCount: true,
        telephone: getCreateBark().buyerTelephone,
        type: 'accountTel'
    });
};

//functions
export const skipStepWithPayload = ({
    payload = {},
    cb = {},
    question = {},
    skipPayload = {},
    firstStep = "location",
} = {}) => {
    //payload = mockPayload;

    const flowMap = {
        question: false,
        location: afterQuestion(),
        email: afterLocation(),
        telephone: afterEmail(),
        name: afterTelephone(),
    };
    const isSkipStep = flowMap[firstStep];

    return isSkipStep;
};

export const skipToStep = ({ step = "", onSkipStep } = {}) => {

    //add extra steps
    if (step === "accountTel" || step === "accountName") {
        addTelephoneStep(getCreateBark()?.questions);
        addNameStep(getCreateBark()?.questions);
    }

    // check for step
    const current = getCreateBark()?.questions[getCreateBark()?.pos];
    const hasQuestion = hasQuestionTypeOrName(step);
    const questionIndex = getQuestionTypeOrNameIndex(step);

    if (hasQuestion) {
        // set step current
        getCreateBark().pos = questionIndex;
        //run function
        onSkipStep();
    }
};
