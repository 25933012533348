import { tracking } from "../../../analytics";
import { getCreateBark } from '../../helpers/general';
import { postResponseAction } from '../../../apis/buyer-api';

export const getServePublicProfileRequestHtml = (providerNounPlural = "professionals") => {
    let sellerName = window.Bark.get_cookie('publicProfileNameBarkSrc');
    const sellerIdhash = window.Bark.get_cookie('publicProfileIdhBarkSrc');

    if (sellerName.length > 25) {
        sellerName = "this professional";
    }

    const cantHelp = _t("buyer_request_quote-public-profile:cant-help", {
        'seller_name': "<span class=\"font-weight-bold\">"+sellerName+"</span>"
    });

    const sendYourRequest = _t("buyer_request_quote-public-profile:send-your-request", {
        "seller_name": sellerName,
        "provider_noun_plural": providerNounPlural
    });

    const topProviders = _t("buyer_request_quote-public-profile:top-providers", {
        "provider_noun_plural": providerNounPlural
    });

    const compiled = window.Handlebars.compile($(`#from-public-profile-request`).html());

    return compiled({
        topProviders,
        sendYourRequest,
        cantHelp,
        sellerName,
        providerNounPlural
    });

}

export const handleFromPublicProfile = (payload) => {
    const cb = getCreateBark();
    const categoryId = $('#category_id').val();
    const locationId = $('#postcode_id').val();
    const locationType = $('#postcode_type').val();
    let extraFields = {};
    let formattedAnswers = cb.getAnswersForSubmission(payload);
    bindPublicProfileRequestEvents();
    submitCustomerRequestPayload({
            categoryId,
            locationType,
            locationId,
            formattedAnswers,
            extraFields
        },
        payload,
        (data) => {
            if (data.data.type === 'customer_request') {
                let identifier = data.data.id;
                getEligibleProfessionals(
                    identifier,
                    (browseList) => {
                        let {data: sellerList} = browseList;
                        const sellerIdhash = window.Bark.get_cookie('publicProfileIdhBarkSrc');
                        let foundSeller = sellerList.find(o => o.type === 'browse_sellers' && o.id === sellerIdhash);

                        if (foundSeller) {
                            $(".inline-bark-buttons-container").show();
                            $('#inlineBarkModalContent .can-serve-request-header').removeClass('d-none');
                            $('#inlineBarkModalContent .can-serve-request-message').removeClass('d-none');
                            tracking(
                                'Buyer - Public profile',
                                'Can serve request - view'
                            );
                            window.Bark.set_cookie('requestQuoteFromSeller', sellerIdhash, 1, '/');
                            window.Bark.set_cookie('fs_mffs', 'y', 1, '/');
                        } else {
                            cb.questions.splice(cb.pos + 1, 0, {
                                gaPath: 'loading-indicator', // Google Analytics Path
                                hideNavigation: true,
                                hideTop: true,
                                omitFromCount: true,
                                show: false,
                                showOnce: true,
                                type: 'upsellLoading',
                                name: 'upsell-loading-screen'
                            });

                            cb.questions.splice(cb.pos + 2, 0 , {
                                gaPath: 'upsell',
                                fbPath: 'Upsell',
                                hideTop: true,
                                omitFromCount: true,
                                type: 'upsell',
                                name: 'upsell-screen'
                            });

                            $('#inlineBarkModalContent .warning-text').removeClass('d-none').addClass('d-flex');
                            $('#inlineBarkModalContent .cant-serve-request-header').removeClass('d-none');
                            $('#inlineBarkModalContent .cant-serve-request-message').removeClass('d-none');
                            $('#inlineBarkModalContent .cant-serve-request-buttons').removeClass('d-none');

                            tracking(
                                'Buyer - Public profile',
                                'Can\'t serve request - view'
                            );
                        }
                        $('#inlineBarkModalContent .loading-data').addClass('d-none');
                        $('#inlineBarkModalContent .main-message').removeClass('d-none');
                    }, (error) => {
                        bugsnagClient && bugsnagClient.notify(new Error('Error on getting suggested sellers for project'), {
                            metaData: {error: error}
                        });
                    },
                    {
                        page: {number: 1},
                        seller_idh: window.Bark.get_cookie('publicProfileIdhBarkSrc')
                    }
                );
            }
        },
        (error) => {
            bugsnagClient.notify(new Error(`Failed to submit payload for public profile match check`), {metaData: error});
        }
    );
}

const bindPublicProfileRequestEvents = () => {
    const cb = getCreateBark();
    $("#inlineBarkModalContent .cancel-request-quote")
        .unbind("click.close")
        .on("click.close", function () {
            cb.hide();
            tracking(
                'Buyer - Public profile',
                'Can\'t serve request - cancel'
            );
        });

    $(".cant-serve-request-buttons .continue-request-quote")
        .unbind("click.continue")
        .on("click.continue", function () {
            tracking(
                'Buyer - Public profile',
                'Can\'t serve request - get quotes'
            );
        });
}

/**
 * Post a customer request payload to generate an id that will be used to find eligible sellers and check if
 * the source seller is one of them
 *
 * @param categoryId like  categoryId = $('#category_id').val();
 * @param locationType something like $('#postcode_type').val();
 * @param locationId something like $('#postcode_id').val();
 * @param isLocal boolean $('#is_local').is(':checked') negate this when passing in (bc barkmodal is cray)
 * @param formattedAnswers object payload of answers formatted by create bark modal
 * @param version 'v1' by default, provide versionString if needed
 * @param payload The payload from bark-modal
 * @param success function which will receive the identifier token from the api
 * @param failure error handler
 */
const submitCustomerRequestPayload = ({categoryId, locationType, locationId, isLocal, formattedAnswers, version, extraFields}, payload, success, failure) => {
    Bark.api(
        "/customer_requests/",
        {
            data: {
                category_id: categoryId,
                location_type: locationType,
                location_id: locationId,
                cid: window.Bark.ENV.ccid,
                answers: formattedAnswers,
                ...extraFields,
                payload: JSON.stringify(payload),
                type: 'bark-modal'
            }
        },
        (data) => {
            success && success(data);
        },
        (error) => {
            if (failure) {
                failure(error);
            } else {
                bugsnagClient.notify(new Error(`Failed to submit payload for request from public profile`), {metaData: error});
            }
        },
        "POST",
        Bark.apiVersionHeader(version ?? 'v1')
    );
}

const getEligibleProfessionals = (identifier, success, failure, payload = {})  =>  {
    Bark.api(
        `/customer_requests/${identifier}/suggestions`,
        payload,
        (data) => {
            // data will be the seller list
            success && success(data);
        },
        (error) => {
            if (failure) {
                failure(error);
            } else {
                bugsnagClient.notify(new Error(`Failed to get eligible professionals for public profile request quote`), {
                    metaData: {
                        error,
                        identifier
                    }
                });
            }
        },
        "GET",
        Bark.apiVersionHeader('v1')
    );
}
