function PriorityNavManager(){
    var instancevars = {
        mainSelector: 'ul#priority-nav ',
        toggleMoreActive: function(show){
            var t = this;
            if(show){
                $(t.mainSelector + ' li.more a.dropdown-toggle').addClass('active');
            }else{
                $(t.mainSelector + ' li.more a.dropdown-toggle').removeClass('active');
            }
        },
        init: function(mainSelectorOverride, deferrable) {
            var t = this;
            if (typeof mainSelectorOverride === 'undefined') {
                mainSelectorOverride = false;
            }
            if (typeof deferrable === 'undefined') {
                deferrable = false;
            }
            if(mainSelectorOverride){
                t.mainSelector = mainSelectorOverride + ' ';
            }
            $(window).on('resize load',function(){
                t.calcWidth();
            });
            if(deferrable){
                t.calcWidth(false, deferrable);
            }
        },

        toggleDropdown: function(show){
            var t = this;
            if (typeof show === 'undefined') {
                show = true;
            }
            $(t.mainSelector + ' li.more')[show ? 'removeClass':'addClass']('d-none');
        },
        calcWidth: function(visibleOnly, deferrable) {
            var t = this;
            if (typeof visibleOnly === 'undefined') {
                visibleOnly = false;
            }
            if (typeof deferrable === 'undefined') {
                deferrable = false;
            }
            var navwidth = 0;
            var morewidth = $(t.mainSelector + ' .more')
                .removeClass('d-none') // Must be visible to measure. It will be hidden later if not needed
                .outerWidth(true);
            $(t.mainSelector + ' > li:not(.more):not(.d-none)').each(function() {
                navwidth += $(this).outerWidth( true );
            });

            var availablespace = $('.priority-nav.wrap').width() - morewidth;
            if (navwidth > availablespace) {
                var lastItem = $(t.mainSelector + ' > li:not(.more):visible > a').last();
                if(lastItem.length > 0){
                    lastItem.attr('data-width', lastItem.parent().outerWidth(true));
                    lastItem.parent().addClass('d-none');
                    lastItem.addClass('dropdown-item');
                    lastItem.removeClass('nav-link');
                    lastItem.data('parentid', lastItem.parent().prop('id'));
                    lastItem.prependTo($(t.mainSelector + ' .more div.dropdown-menu'));
                    t.calcWidth(true);
                }
            } else {

                var firstMoreElement = $(t.mainSelector + ' li.more div.dropdown-menu a').first();
                if (navwidth + firstMoreElement.data('width') < availablespace) {
                    firstMoreElement.removeClass('dropdown-item');
                    firstMoreElement.addClass('nav-link');
                    var parent = $('#'+ firstMoreElement.data('parentid'));
                    parent.html(firstMoreElement);
                    parent.removeClass('d-none');
                    firstMoreElement.prependTo(parent);
                    parent.insertBefore($(t.mainSelector + ' .more'));
                    parent.show();
                    t.calcWidth();
                }
            }

            t.toggleDropdown($(t.mainSelector + ' li.more div.dropdown-menu a').length > 0)
            if(deferrable){
                deferrable.resolve();
            }
        }
    };
    Object.assign(this, instancevars);
    return this;
}



export const PriorityNavManagerLib = () => {
    if($('.priority-nav').length > 0){
        window.priorityNavManager = new PriorityNavManager();
    }
}