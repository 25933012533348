import { SellerNotificationsV2, SellerCommsUpdatesHeaderManager } from '@bark/bark-core';
import { popupEqualsTrueHandler } from './start-bark-flow-immediately';

export const multiRouteEntrypoint = async ({ params }) => {

	await popupEqualsTrueHandler({ params });

	if (window.Bark.usrActiveRole === 'seller') {
		SellerNotificationsV2();
		SellerCommsUpdatesHeaderManager();
	}
};
