export default class CsatSellerList {
	constructor($widgetContainer) {
		this.widgetContainer = $widgetContainer;
		this.keyName = 'csat-seller-list';
	}

	show() {
        if (!this.displayed()) {
            this.widgetContainer.removeClass('d-none');
            this.viewedProfile() ? this.showAfter(1500) : this.showAfter(30000);
            this.bindListeners();
        }
	}

    hide() {
		if (this.onMobile()) {
			this.widgetContainer.removeClass('slide-up');
			this.widgetContainer.addClass('slide-down');
		} else {
			this.widgetContainer.removeClass('slide-in');
			this.widgetContainer.addClass('slide-out');
		}
		setTimeout(() => {
			this.widgetContainer.addClass('d-none');
		}, 2000);
	}

	bindListeners() {
        let that = this;
        $('#contactSellerModal, #buyer-quote-more-info-modal, #request-reply-modal').on(
					'hidden.bs.modal',
					function (e) {
						that.showAfter(1500);
					},
				);
    }

	showAfter(delayMs) {
		setTimeout(() => {
			if (!this.displayed()) {
				this.onMobile()
					? this.widgetContainer.addClass('slide-up')
					: this.widgetContainer.addClass('slide-in');
				this.setDisplayed(true);
			}
		}, delayMs);
	}

	displayed() {
		return window.localStorage.getItem('csat-widget.displayed');
	}

    viewedProfile() {
		return window.localStorage.getItem('csat-widget.viewedProfile');
	}

	setDisplayed(value) {
		window.localStorage.setItem('csat-widget.displayed', value);
	}

	onMobile() {
		return $(window).width() <= 567;
	}
}
