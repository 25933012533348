import { showCustomerTab, showProfessionalTab} from '../components/terms-and-conditions/terms-and-conditions';

export const termsAndConditionsPage = async () => {

  const hash = window.location.hash.substr(1);
  if (hash === 'professionals') {
    showProfessionalTab();
  } else {
    showCustomerTab();
  }

};
