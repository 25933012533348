import { tracking } from '../../components/analytics/bark-tracking';

const eventGroup = 'Notification opt-out tracking';

function getUserId() {
	return window?.Bark?.USER?.userId;
}

function getTimestamps() {
	const now = new Date();
	return {
		timestamp_utc: now.toUTCString(),
		timestamp_unix: Math.floor(now / 1000),
	};
}

function trackOptInSuccess(preference, userType) {
	const eventName = 'Notifications - subscribe - success';
	const params = {
		notification_name: preference,
		user_id: getUserId(),
		user_type: userType,
		...getTimestamps(),
	};
	tracking(eventGroup, eventName, params);
}

function trackOptOutSuccess(preference, userType) {
	const eventName = 'Notifications - unsubscribe - success';
	const params = {
		notification_name: preference,
		user_id: getUserId(),
		user_type: userType,
		...getTimestamps(),
	};
	tracking(eventGroup, eventName, params);
}

function trackOptInFailure(preference, userType) {
	const eventName = 'Notifications - subscribe - fail';
	const params = {
		notification_name: preference,
		user_id: getUserId(),
		user_type: userType,
		...getTimestamps(),
	};
	tracking(eventGroup, eventName, params);
}

function trackOptOutFailure(preference, userType) {
	const eventName = 'Notifications - unsubscribe - fail';
	const params = {
		notification_name: preference,
		user_id: getUserId(),
		user_type: userType,
		...getTimestamps(),
	};
	tracking(eventGroup, eventName, params);
}

export function trackEmailPreferences(trackingData, userType, success = false) {
	for (const preference in trackingData) {
		if (trackingData[preference] === 1) {
			success
				? trackOptInSuccess(preference, userType)
				: trackOptInFailure(preference, userType);
		} else {
			success
				? trackOptOutSuccess(preference, userType)
				: trackOptOutFailure(preference, userType);
		}
	}
}
