export const clearAllAlerts = (elements = []) => {
  elements.forEach( element => {
    element.removeClass('is-invalid');
    element.closest('.form-group').children('.invalid-feedback').html('');
  });

}

export const showAlertPassword = (element, text) => {
  element.addClass('is-invalid');
  element.closest('.form-group').children('.invalid-feedback').html(text);
}

