const CODE_ENTER = 'Enter';
const CODE_RETURN = 'Enter';
const ARROW_LEFT_CODE = 37;
const ARROW_RIGHT_CODE = 39;
const ENTER_CODE = 13;

export {
    CODE_ENTER,
    CODE_RETURN,
    ARROW_LEFT_CODE,
    ARROW_RIGHT_CODE,
    ENTER_CODE
}
