import { useNamespace, _t } from '../../../libs/i18next';
export const validatePasswords = async (password, confirmPassword) => {
	await useNamespace('common_change-password');
	if (password.length === 0) {
		return {
			field: 'password',
			error: _t('common_change-password:errors.no-password'),
		};
	} else if (password !== confirmPassword) {
		return {
			field: 'confirm-password',
			error: _t('common_change-password:errors.no-match'),
		};
	}

	return null;
};
