import {streamComplete} from "../../helpers/stream-complete";
import { launchBarkModal } from '../new-request-flow/components/entry-service/start-bark';

export const initCategoryAutocompleteForInput = (inputId, hiddenFieldIdToAttachResults, { isStartBark = false, payload = () => {} } = {}) => {
    streamComplete(`#${inputId}`, 'services', 'GET', (item) => {
        $(`#${hiddenFieldIdToAttachResults}`).val(item.id);
        if(isStartBark){
            launchBarkModal(payload());
        }
    });

    addListenerForClearingInput(inputId, hiddenFieldIdToAttachResults);
}

const addListenerForClearingInput = (inputId, hiddenFieldIdToAttachResults) => {
    $(`${inputId}`).off('keyup.clear').on('keyup.clear', function () {
        if (!$(this).val()) {
            $(`#${hiddenFieldIdToAttachResults}`).val(null);
        }
    });
}
