export const openLauncher = () => {
  const launcherTrigger = document.querySelector('div[id^="chmln-list-trigger-"]');
  if(!launcherTrigger) return;

  launcherTrigger.click();
}

export const isLauncherOpen = () => !!getLauncher().querySelector('.chmln-list-open');
export const isLauncherClosed = () => !isLauncherOpen();
export const isLauncherInDom = () => !!getLauncher();
export const getLauncher = () => document.querySelector('div[id^="chmln-list-"]');