import { BuyerProjectInteractionTracking } from '../analytics';
import { getLastMessage } from '../apis/buyer-api';
import { _t } from '../../libs/i18next';

export default function ({ hasActiveProject = false, customTracking = undefined }) {
	let contactSellerModal,
		invalidFieldCount = 0,
		phoneToValidate = '',
		projectId = '',
		sourceType = '',
		emailToValidate = '',
		sellerProfileId = 0,
		bpvsId = null,
		buyerExperiment,
		bjSellerPhoneNum,
		experimentsTrackingHandler,
		lastMessages = {
			callback: '',
			email: '',
		};

	function closeContactModal() {
		// Buyer dashboard uses foundation
		if ($('#contactSellerModal').hasClass('reveal-modal')) {
			$('#contactSellerModal').foundation('reveal', 'close');
		} else {
			$('#contactSellerModal').modal('hide');
		}
		logEvent('hide_modal', sellerProfileId);
	}

	/**
	 * Focuses the contact field, if it is empty, and the user is not on mobile
	 */
	function focusContactField() {
		if (!Bark.isMobile()) {
			if (
				$('#contact-me-by-phone.active', contactSellerModal).length &&
				!$('#contact-me-by-phone.active', contactSellerModal).val()
			) {
				$('[name=phone]', contactSellerModal).focus();
			} else if (
				$('#contact-me-by-email.active', contactSellerModal).length &&
				!$('#contact-me-by-email.active', contactSellerModal).val()
			) {
				$('[name=email]', contactSellerModal).focus();
			}
		}
		setSubmittedState(false);
	}

	/**
	 * Initialize the contact method tabs
	 */
	function initContactMethods() {
		function setActiveContactMethod() {
			if ($('#contact-me-by-phone', contactSellerModal).hasClass('active')) {
				//Autofill message (Phone)
				autofillMessage('callback', true);

				$('.phone-row', contactSellerModal).removeClass('d-none');

				if (buyerExperiment && bjSellerPhoneNum) {
					$('.bj-callback-option', contactSellerModal).removeClass('d-none');
					$('.message-row').addClass('d-none');
				}

				$('.email-explanation').addClass('d-none');
				$('.js-post-contact-wrapper').removeClass('mb-2').addClass('mb-4');

				$('.email-row', contactSellerModal).addClass('d-none');
				$('.js-post-contact .button-text').text(
					_t('common_contact-seller-modal:buttons.request-callback'),
				);
			} else if ($('#contact-me-by-email', contactSellerModal).hasClass('active')) {
				//Autofill message (Email)
				autofillMessage('email', true);

				$('.phone-row', contactSellerModal).addClass('d-none');
				$('.email-row', contactSellerModal).removeClass('d-none');

				//Buyer experiment logic
				$('.bj-callback-option', contactSellerModal).addClass('d-none');
				$('.message-row').removeClass('d-none');

				$('.js-post-contact-wrapper').removeClass('mb-4').addClass('mb-2');
				$('.email-explanation').removeClass('d-none');
				$('.js-post-contact .button-text').text(
					_t('common_contact-seller-modal:buttons.send-email'),
				);
			}
			logExperimentEvent('view');
		}

		$('.contact-nav a', contactSellerModal).on('click', function (e) {
			$('.contact-nav a', contactSellerModal).removeClass('active');
			$(this).addClass('active').blur();
			setActiveContactMethod();
			resetContactValidationMessages();
			toggleContactTimeSection();
			focusContactField();
			logEvent('method_click', $(this).data('type'));
			e.preventDefault();
			return false;
		});

		$('.contact-nav a', contactSellerModal).eq(0).addClass('active');
		setActiveContactMethod();
	}

	/**
	 * Initialise the contact time radio button click handler
	 */
	function initContactTimes() {
		var container = $('.contact-time-container', contactSellerModal);

		var setSelectedContactTime = function () {
			$('[name=contact-time]', container)
				.parent()
				.removeClass('selected')
				.addClass('text-grey-400');
			$('[name=contact-time]:checked', container)
				.parent()
				.addClass('selected')
				.removeClass('text-grey-400');
		};

		$('[name=contact-time]', container).on('click', function () {
			setSelectedContactTime();
			logEvent('contact_click', $(this).data('type'));
		});
		$('[name=contact-time]', container).eq(0).prop('checked', true);
		setSelectedContactTime();
	}

	function initExperimentTracking({ sellerProfileId, projectId, sourceType }) {
		experimentsTrackingHandler = hasActiveProject
			? typeof customTracking === 'function' && customTracking()
			: BuyerProjectInteractionTracking();

		experimentsTrackingHandler.initialise(); // keeping it agnostic here
		experimentsTrackingHandler.setSpid(sellerProfileId);
		experimentsTrackingHandler.setPid(projectId);
		experimentsTrackingHandler.setPagename(sourceType);
	}

	function logExperimentEvent(actionPartialName) {
		let activeMethod = $('#contact-me-by-phone', contactSellerModal).hasClass('active')
			? 'phone'
			: 'email';
		experimentsTrackingHandler?.submitExpActWithVal(
			`${sourceType}-contact-modal-${activeMethod}-${actionPartialName}`,
			{ sellerProfileId: sellerProfileId },
		);
	}

	/**
	 * Logs an event
	 * @param {string} eventName
	 * @param {string} eventIdentifier
	 * @param {string=seller_profile_message_modal} eventCategory
	 */
	function logEvent(eventName, eventIdentifier, eventCategory) {
		try {
			eventCategory = eventCategory || 'seller_profile_contact_modal';
			$.post('/api/event-log/', {
				event_category: eventCategory,
				event_name: eventName,
				event_id: eventIdentifier,
			});
		} catch (e) {
			bugsnagClient.notify(e);
		}
	}

	/**
	 * Clears all validation error messages and classes
	 */
	function resetContactValidationMessages() {
		$('.error-container', contactSellerModal).addClass('d-none');
		$('.invalid-feedback', contactSellerModal).hide();
		$('.is-invalid', contactSellerModal).removeClass('is-invalid');
		invalidFieldCount = 0;
	}

	/**
	 * Disable the submit and close buttons
	 * @param {boolean} isSubmitting If true, disable submit buttons and show submitting status, otherwise reset
	 */
	function setSubmittedState(isSubmitting) {
		$('.js-post-contact .button-text', contactSellerModal)[
			isSubmitting ? 'addClass' : 'removeClass'
		]('d-none');
		$('.js-post-contact', contactSellerModal).attr(
			'disabled',
			isSubmitting ? true : false,
		);
		$('button.close', contactSellerModal)[isSubmitting ? 'addClass' : 'removeClass'](
			'd-none',
		);
		$('.js-post-contact .spinner-border', contactSellerModal)[
			isSubmitting ? 'removeClass' : 'addClass'
		]('d-none');
	}

	/**
	 * Shows a validation error by highlighting the affected field and displaying the associated invalid message. Also records an event
	 * @param {string} fieldName The name of the field
	 */
	function showContactValidationError(fieldName) {
		var field = $('[name=' + fieldName + ']', contactSellerModal);
		field.addClass('is-invalid');
		Bark.addErrorAnimation(field);
		field.next('.invalid-feedback').show();
		invalidFieldCount++;
	}

	/**
	 * Submit the contact form to the API endpoint
	 */
	function submitContactForm() {
		$('.error-container', contactSellerModal).addClass('d-none');
		var payload = {
			message: $('[name=message]', contactSellerModal).val(),
			project_id: projectId,
			seller_profile_id: sellerProfileId,
			bpvs: bpvsId,
			source: sourceType,
			type: 'phone',
		};
		if ($('#contact-me-by-phone.active', contactSellerModal).length) {
			payload.phone = phoneToValidate;
			payload.type = 'contact';
			if ($('#contact-me-now', contactSellerModal).prop('checked')) {
				payload.timeframe = 'now';
			} else if ($('#contact-me-evening', contactSellerModal).prop('checked')) {
				payload.timeframe = _t('common_contact-seller-modal:timeframe.in-the-evening');
			} else if ($('#contact-me-weekend', contactSellerModal).prop('checked')) {
				payload.timeframe = _t('common_contact-seller-modal:timeframe.at-the-weekend');
			}
		} else if ($('#contact-me-by-email.active', contactSellerModal).length) {
			payload.email = emailToValidate;
			payload.type = 'message';
		}
		Bark.api(
			'seller/contact',
			payload,
			function (data) {
				if (data.status) {
					toggleConfirmationMessage(true);
					logExperimentEvent('success');
					$('html, body').animate(
						{
							scrollTop: 0,
						},
						250,
					);

					if (buyerExperiment) {
						closeContactModal();
					} else {
						// Auto-close the modal in 2 seconds
						window.setTimeout(function () {
							closeContactModal();
						}, 2000);
					}
				} else {
					logExperimentEvent('fail');
					$('.error-container', contactSellerModal)
						.removeClass('d-none')
						.text(_t('common_contact-seller-modal:errors.generic-error'));
				}
				setSubmittedState(false);
			},
			function (error) {
				var errors = [];
				if (
					error.responseJSON &&
					error.responseJSON.error &&
					error.responseJSON.error.errors
				) {
					for (var field in error.responseJSON.error.errors) {
						errors.push(error.responseJSON.error.errors[field]);
					}
				}
				if (!errors.length && error.responseJSON && error.responseJSON.message) {
					errors.push(error.responseJSON.message);
				}
				$('.error-container', contactSellerModal)
					.removeClass('d-none')
					.text(errors.join(' '));
				setSubmittedState(false);
				logExperimentEvent('fail');
			},
			'POST',
			Bark.apiVersionHeader('v2'),
		);

		//buyer journey experiment
		var submitEvent = new CustomEvent('successful-contact-request', {
			detail: payload.type,
		});
		document.dispatchEvent(submitEvent);
	}

	/**
	 * Shows the confirmation message
	 * @param {boolean} show Whether to show the confirmation message
	 */
	function toggleConfirmationMessage(show) {
		//There is a super sexy custom confirmation here for the bj experiment.
		if (buyerExperiment && show) {
			$('#bj-success').modal('show');
		} else {
			$('.contact-form-container', contactSellerModal)[show ? 'addClass' : 'removeClass'](
				'd-none',
			);
			$('.confirmation-container', contactSellerModal)[show ? 'removeClass' : 'addClass'](
				'd-none',
			);
		}

		// Reset the form
		if (!show) {
			$('#contact-me-by-email', contactSellerModal).removeClass('active');
			$('#contact-me-by-phone', contactSellerModal).addClass('active');
			$('.phone-row', contactSellerModal).removeClass('d-none');
			$('.email-row', contactSellerModal).addClass('d-none');
			$('[name=contact-time]', contactSellerModal)
				.parent()
				.removeClass('selected')
				.addClass('text-grey-400');
			$('[name=contact-time]', contactSellerModal)
				.eq(0)
				.prop('checked', true)
				.parent()
				.addClass('selected')
				.removeClass('text-grey-400');
			$('input,textarea', contactSellerModal).each(function () {
				$(this).val($(this).data('original-value') || null);
			});
			$('.js-bj-call-seller-desktop').attr('href', '#');
			$('.bj-seller-phone-number-hidden', contactSellerModal).text(
				_t('common_contact-seller-modal:reveal-phone-number-cta'),
			);
			$('.js-post-contact .button-text').text(
				_t('common_contact-seller-modal:buttons.request-callback'),
			);
			resetContactValidationMessages();
			toggleContactTimeSection();
		}
	}

	/**
	 * Shows/hides the contact time fields depending on whether the selected contact method is email or phone
	 */
	function toggleContactTimeSection() {
		$('.contact-time-content')[
			$('#contact-me-by-phone.active').length ? 'removeClass' : 'addClass'
		]('d-none');
	}

	/**
	 * Validates the contact form prior to submission.
	 */
	function validateContactForm() {
		resetContactValidationMessages();
		setSubmittedState(true);
		phoneToValidate = '';
		emailToValidate = '';
		// Reset this message in case it was changed by a previous validation attempt.
		$('.email-row .invalid-feedback').text(
			_t('common_contact-seller-modal:errors.invalid-email'),
		);
		var msg = $('[name=message]', contactSellerModal).val();

		if ($('#contact-me-by-phone.active', contactSellerModal).length) {
			phoneToValidate = $('input[name=phone]', contactSellerModal).val() || '';
			if (!phoneToValidate.length) {
				// If the "contact-me-by-phone" button is selected, phone is required and must be valid
				showContactValidationError('phone');
				setSubmittedState(false);
				logEvent('validation_error_phone', phoneToValidate);
			} else {
				var basicValidationResult = window.basicPhoneCheck.test(phoneToValidate);
				if (basicValidationResult.invalid || basicValidationResult.badChars) {
					showContactValidationError('phone');
					setSubmittedState(false);
					logEvent('validation_error_phone', phoneToValidate);
				}
			}
		} else if ($('#contact-me-by-email.active', contactSellerModal).length) {
			// require a message if sending an email
			if (msg.length < 30) {
				showContactValidationError('message');
				$('.error-container', contactSellerModal)
					.removeClass('d-none')
					.text(_t('common_contact-seller-modal:errors.message-too-short'));
				setSubmittedState(false);
				logEvent('validation_error_message', msg);
			}
			emailToValidate = $('input[name=email]', contactSellerModal).val() || '';
			// Basic local validation
			if (!emailToValidate.match(/.+@.+\..+/)) {
				showContactValidationError('email');
				setSubmittedState(false);
				logEvent('validation_error_email', emailToValidate);
			}
		} else {
			// Something is wrong. Abort!
			setSubmittedState(false);
			bugsnagClient.notify(
				new Error('Submitted contact form with no contact method selected.'),
			);
			return false;
		}

		// If no validation errors, proceed with any external validations
		if (invalidFieldCount === 0) {
			validatePhoneOrContinue();
		} else {
			logExperimentEvent('fail');
		}
	}

	/**
	 * Validates the email address externally. If not present, skips this validation
	 */
	function validateEmailOrContinue() {
		if (!emailToValidate.length) {
			// No email address to validate, move on to submit the form
			return submitContactForm();
		}
		$('[name=email]', contactSellerModal).email_validator({
			success: function (data) {
				if (data.is_valid) {
					submitContactForm();
				} else {
					logExperimentEvent('fail');
					if (data.did_you_mean) {
						$('.email-row .invalid-feedback').html(
							_t('common_contact-seller-modal:email-suggestion', {
								did_you_mean_suggestion: `<a class="email-did-you-mean" href="#">${data.did_you_mean}</a>`,
							}),
						);
						$('.email-did-you-mean', contactSellerModal).one('click', function (e) {
							$('[name=email]', contactSellerModal).val(data.did_you_mean);
							resetContactValidationMessages();
							e.stopPropagation();
							return false;
						});
					}
					showContactValidationError('email');
					setSubmittedState(false);
					logEvent('validation_error_email_external', emailToValidate);
				}
			},
			error: function () {
				showContactValidationError('email');
				setSubmittedState(false);
				logExperimentEvent('fail');
			},
		});
	}

	/**
	 * Validates the phone number externally. If not present, skips this validation
	 */
	function validatePhoneOrContinue() {
		if (!phoneToValidate.length) {
			// No phone number to validate, move on to the email address
			return validateEmailOrContinue();
		}
		Bark.validate.ukTel(
			phoneToValidate,
			function (success, moderate) {
				if (success || (!success && moderate)) {
					validateEmailOrContinue();
				} else {
					showContactValidationError('phone');
					setSubmittedState(false);
					logEvent('validation_error_phone_external', phoneToValidate);
					logExperimentEvent('fail');
				}
			},
			false,
			hasActiveProject ? 'buyer' : undefined,
		);
	}

	function loadLastMessages(projectId) {
		if (projectId) {
			$('#contactSellerModal .msg-box-spinner').removeClass('d-none');

			const callBackMsgPromise = getLastMessage({ type: 'callback', projectId });
			const emailMsgPromise = getLastMessage({ type: 'email', projectId });
			return Promise.all([callBackMsgPromise, emailMsgPromise])
				.then((values) => {
					if (values[0].data && values[0].data?.message) {
						lastMessages.callback = values[0].data.message;
					}
					if (values[1].data && values[1].data?.message) {
						lastMessages.email = values[1].data.message;
					}
				})
				.finally(() => {
					$('#contactSellerModal .msg-box-spinner').addClass('d-none');
				});
		} else {
			return Promise.resolve();
		}
	}

	function autofillMessage(type, delay = false) {
		if (lastMessages[type]) {
			let $msgBox = $('#contactSellerModal #message-message');
			$msgBox.val('');
			if (delay) {
				setTimeout(function () {
					$msgBox.val(lastMessages[type]).change().focus();
				}, 100);
			} else {
				$msgBox.val(lastMessages[type]).change().focus();
			}
		}
	}

	function init() {
		contactSellerModal = $('#contactSellerModal');

		// Contact button event handler
		$('body').on('click.show', '.js-contact-seller', function (e) {
			// Ensure that a seller profile ID data element has been set

			e.preventDefault();
			sellerProfileId = $(this).data('seller-profile-id')
				? $(this).data('seller-profile-id').toString()
				: 0;
			bpvsId = $(this).data('bpvs') || null;
			projectId = $(this).data('project-id') || '';
			buyerExperiment = $(this).data('bj-exp') === 1;
			bjSellerPhoneNum = $(this).data('tel');
			sourceType = $(this).data('source-type') || '';
			let buyerPhone = $(this).data('buyer-tel')
				? $(this).data('buyer-tel').toString()
				: '';
			let buyerEmail = $(this).data('buyer-email');
			initExperimentTracking({ sourceType, sellerProfileId, projectId });

			if (sellerProfileId.length) {
				if (buyerExperiment) {
					$('#contactSellerModal .error-container').addClass('bj-experiment');
				}

				if (buyerPhone && buyerPhone.length > 0) {
					$('#contact-phone').data('original-value', buyerPhone);
				}

				if (buyerEmail && buyerEmail.length > 0) {
					const $contactEmail = $('#contact-email');
					$contactEmail.data('original-value', buyerEmail);
					$contactEmail.attr('disabled', 'true');
				}

				if (buyerExperiment && bjSellerPhoneNum) {
					var $callSellerButton = $('.js-bj-call-seller');
					$('.bj-seller-phone-number').text(bjSellerPhoneNum);
					$callSellerButton.attr('href', 'tel:' + bjSellerPhoneNum);
					$('.bj-callback-option').removeClass('d-none');
					$('.message-row').addClass('d-none');
				} else {
					$('.bj-callback-option').addClass('d-none');
					$('.message-row').removeClass('d-none');
					$('#contactSellerModal .error-container').removeClass('bj-experiment');
				}

				// Add the company name to the confirmation message text, if provided.
				var companyName = $(this).data('seller-name') || '';
				companyName = companyName.length ? ' to ' + companyName : '';
				$('.confirmation-message .company-name', contactSellerModal).text(companyName);

				// Clear any previous error messages
				resetContactValidationMessages();

				loadLastMessages(projectId).then(function () {
					//Autofill message (Phone)
					autofillMessage('callback');
				});

				// Show the modal
				if ($('#contactSellerModal').hasClass('reveal-modal')) {
					$('#contactSellerModal').foundation('reveal', 'open');
				} else {
					$('#contactSellerModal').modal('show');
				}

				// Log an event
				logEvent('show_modal', sellerProfileId);
				logExperimentEvent('view');
			} else {
				// This should never happen, so inform Bugsnag
				bugsnagClient.notify(new Error('js-contact-seller.click:failed'), {
					severity: 'error',
				});
			}
			return false;
		});

		$('.js-bj-call-seller-desktop').on('click', function (e) {
			if ($(this).attr('href') === '#') {
				e.preventDefault();
				const revealEvent = new CustomEvent('reveal-phone');
				document.dispatchEvent(revealEvent);
			} else {
				const callEvent = new CustomEvent('call-seller-desktop');
				document.dispatchEvent(callEvent);
			}

			$('.bj-seller-phone-number-hidden').text(bjSellerPhoneNum);
			$(this).attr('href', 'tel:' + bjSellerPhoneNum);
		});

		// Reset the form before the modal is shown
		contactSellerModal.on('show.bs.modal.reset', function () {
			toggleConfirmationMessage(false);
		});

		// Focus the visible field when the modal is shown, if on desktop
		contactSellerModal.on('shown.bs.modal.focus', function () {
			focusContactField();
		});

		// Foundation events, for the buyer dashboard
		$(document).on('open.zf.reveal', function () {
			toggleConfirmationMessage(false);
			focusContactField();
		});

		// Init custom controls
		initContactMethods();
		initContactTimes();

		// Submit button handler
		$('.js-post-contact', contactSellerModal).on('click', function (e) {
			logExperimentEvent('submit');
			validateContactForm();
			e.preventDefault();
			return false;
		});

		// Close button handler for Foundation/Buyer Dashboard version
		if ($('#contactSellerModal').hasClass('reveal-modal')) {
			$('button.close', contactSellerModal).on('click.close', closeContactModal);
		}

		if (Bark.GET('amp_contact')) {
			$('.js-contact-seller').eq(0).click();
			logEvent('show_modal_from_amp', sellerProfileId);
		}

		// Explicitly set the viewport height so that the modals will scroll as expected on mobile
		if (Bark.isMobile()) {
			// only do this for internal pages or pageloads with a project ID  so googlebot doesnt get cross at us
			if (
				$('.seller-public-profile-page').length < 1 ||
				$('.seller-public-profile-page.has-back-to-quotes').length > 0
			) {
				setTimeout(function () {
					var viewheight = $(window).height(),
						viewwidth = $(window).width(),
						viewport = document.querySelector('meta[name=viewport]');
					viewport.setAttribute(
						'content',
						'height=' + viewheight + ', width=' + viewwidth + ', initial-scale=1.0',
					);
				}, 300);
			}
			// On Android, we need to scroll the field into view
			$('textarea', contactSellerModal).focus(function () {
				if (
					$(window).height() < contactSellerModal.height() &&
					!$('body').hasClass('ios')
				) {
					$('html, body').animate(
						{
							scrollTop: $(this).offset().top - 40,
						},
						250,
					);
				}
			});
		}
	}

	init();
}
