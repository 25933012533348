export function ViewportSize() {
	const mobileThreshold = 768;
	const tabletThreshold = 992;

	const getWindowWidth = () => {
		return $(window).width();
	};

	const isMobile = () => {
		return getWindowWidth() < mobileThreshold;
	};

	const isTablet = () => {
		return getWindowWidth() > mobileThreshold && getWindowWidth() < tabletThreshold;
	};

	const isDesktop = () => {
		return getWindowWidth() > tabletThreshold;
	};

	return {
		isMobile,
		isTablet,
		isDesktop,
	};
}
