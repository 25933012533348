
export default function () {
    window.BarkV2 = window.BarkV2 || {};

    $(window).resize(function () {
        if ($('#category_name').length > 0) {
            setSuggestionsPositionCategory();
        }
    });

    $('input').on('keyup', function () {
        setSuggestionsPositionCategory()
    });


    function setSuggestionsPositionCategory() {
        var cat_field = $('#category_name');
        $('#ui-id-1').css({
            'width': cat_field.outerWidth(),
            'top': cat_field.offset().top + 64,
            'left': cat_field.offset().left
        });
    }


    $(document).ready(function () {
        $('.initiate-new-bark').click(function (event) {
            event.preventDefault();

            var categoryId = $(this).data('cid');

            if (!categoryId)
                return;

            const $postcodeId   = $('#postcode_id');
            const $postcodeType = $('#postcode_type');

            if (!$postcodeId.val() || !$postcodeType.val()) {
                $postcodeId.val('-1');
                $postcodeType.val('-1');
            }

            $('#category_id').val(categoryId);
            $('#category_name_hidden').val('');

            $('#bark_submit').trigger('click');
        });

        $('.directory-category').click(function (event) {
            var href = $(this).data('href');


            if (!href)
                return;

            window.location.href = href;
        });

        $('.category-focus-button').click(function (event) {
            var categoryInput = $("#category_name");

            $('html, body').animate({
                scrollTop: 0,
                easing: 'easeout'
            }, 400);

            categoryInput.focus()
        });
    })
}