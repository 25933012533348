export const showEnquiriesModal = (
    sellerId,
    companyName,
    companyNumber,
    companyNumberFormatted,
    projectId,
) => {
    if (window.BuyerApp) {
        window.BuyerApp.launchModule('enquiries-modal', '__enquiries-modal', {
            sellerId,
            companyName,
            companyNumber,
            companyNumberFormatted,
            projectId,
        });
    }
};
