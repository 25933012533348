export default function () {
	$(window).resize(function () {
		var viewportWidth = $(window).width();
		var tabletBreakpoint = 991;

        if (viewportWidth > tabletBreakpoint && typeof $.fn.collapse === 'function') {
            $('#navbarSupportedContent').collapse('hide');
        }
    });

	var countryDropdown = $('.country-selection-fake-dropdown');

	//If a modal closes and opens another modal it removes the modal open class which allows us to scroll. This is not good news
	$(document).on('hidden.bs.modal', function (event) {
		if ($('.modal:visible').length) {
			$('body').addClass('modal-open');
		}
	});

	$(document).ready(function () {
		countryDropdown.on('click', function () {
			$('#countrySelectModal').modal();
		});
	});
}
