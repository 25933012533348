import {tracking} from "../../../analytics";
import {browseTracking, clearBrowseCookie, setBrowseCookie} from "./browse-project";
import {postResponseAction} from "../../../apis/buyer-api";
import {getCreateBark} from "../../helpers/general";

export default class BrowseProjectEndpoints {
    payload = {
        validateProject: {},
        formattedAnswers: {}
    };
    sellerProfileId = '';

    interimPayload = {};

    shouldBuildPayload = true;

    setPayloadArg(key, dataObject) {
        this.payload[key] = dataObject;
    }

    saveInProgressPayload(payload) {
        this.interimPayload = payload;
    }

    setInteractedSellerProfileId(sellerProfileId) {
        this.sellerProfileId = sellerProfileId;
    }

    getInteractedSellerProfileId() {
        return this.sellerProfileId;
    }

    pauseBarkModalAndRedirectToBrowseFlow({validateProject, formattedAnswers, answers, extraFields}) {
        const categoryId = $('#category_id').val();
        const locationId = $('#postcode_id').val();
        const locationType = $('#postcode_type').val();
        this.submitPayload({
                categoryId,
                locationType,
                locationId,
                formattedAnswers,
                extraFields
            },
            {
                validateProject,
                answers,
            },
            (data) => {
                if (data.data.type === 'customer_request') {
                    let identifier = data.data.id;
                    getCreateBark().TenderBrowseExperiment.track('Buyer- Place Bark - Browse redirect', {browse_list_id:identifier})
                    setBrowseCookie(identifier);
                    getCreateBark().hideWithoutConfirm();
                    window.location.href = `/browse/${categoryId}/${identifier}/`;
                }
            },
            (error) => {
                $('#js-tenderbrowse-bottomsection-container').hide();
                getCreateBark().TenderBrowseExperiment.track('Buyer - Place Bark - Tender error');
                getCreateBark().next();
                bugsnagClient.notify(new Error(`Failed to submit payload for browse list`), {metaData: error});
            }
        );
    }

    /**
     * Post a payload to the API for (temporary) storage. Also saves the identifier to the session
     *
     * @param categoryId like  categoryId = $('#category_id').val();
     * @param locationType something like $('#postcode_type').val();
     * @param locationId something like $('#postcode_id').val();
     * @param isLocal boolean $('#is_local').is(':checked') negate this when passing in (bc barkmodal is cray)
     * @param formattedAnswers object payload of answers formatted by create bark modal
     * @param version 'v1' by default, provide versionString if needed
     * @param payload The payload from bark-modal
     * @param success function which will receive the identifier token from the api
     * @param failure error handler
     */
    submitPayload({categoryId, locationType, locationId, isLocal, formattedAnswers, version, extraFields}, payload, success, failure) {
        Bark.api(
            "/customer_requests/",
            {
                data: {
                    category_id: categoryId,
                    location_type: locationType,
                    location_id: locationId,
                    cid: window.Bark.ENV.ccid,
                    answers: formattedAnswers,
                    ...extraFields,
                    payload: JSON.stringify(payload),
                    type: 'bark-modal'
                }
            },
            (data) => {
                success && success(data);
            },
            (error) => {
                if (failure) {
                    failure(error);
                } else {
                    bugsnagClient.notify(new Error(`Failed to submit payload for browse list`), {metaData: error});
                }
            },
            "POST",
            Bark.apiVersionHeader(version ?? 'v1')
        );
    }

    bindCreateBarkModalEventListeners() {
        const cb = getCreateBark();
        $('.js-tenderbrowse-next').off('click').on('click', function(e) {
            e.preventDefault();
            // what does this do?
            $('#js-tenderbrowse-bottomsection-container').hide()
            cb.next();
        })
        $('.js-tenderbrowse-browse').off('click').on('click', function(e) {
            e.preventDefault();
            cb.TenderBrowseExperiment.track('Buyer - Place Bark - Browse')
            const bp = cb.BrowseProjectExperiment;
            let answers = bp.interimPayload;
            bp.setPayloadArg('formattedAnswers', cb.getAnswersForSubmission(answers));
            // this will force it to skip those questions
            answers['tender-browse'] = {
                'type': 'tenderBrowse',
                'value': true
            };
            answers['upsell-screen'] = {
                'type': 'upsell',
                'value': true
            };
            answers['upsell-loading-screen'] = {
                'type': 'upsellLoading',
                'value': true
            };
            let extraFields = {};
            // include experiment ids in case they impact sorting
            if (cb.categoryData && cb.categoryData.experiments) {
                for (const experimentName in cb.categoryData.experiments) {
                    extraFields['experiment_id_' + experimentName] = cb.categoryData.experiments[experimentName].id;
                }
            }
            bp.pauseBarkModalAndRedirectToBrowseFlow({
                ...bp.payload,
                answers,
                extraFields
            });
        })
        $('.js-tenderbrowse-tender').off('click').on('click', function(e) {
            e.preventDefault();
            // do a tenderness.
            cb.TenderBrowseExperiment.track('Buyer - Place Bark - Tender')
            $('#js-tenderbrowse-bottomsection-container').hide();
            cb.next();
        })

        $('.js-tenderbrowse-back-from-email-capture').off('click').on('click', function(e) {
            e.preventDefault();
            $('#js-tenderbrowse-bottomsection-container').hide()
            cb.prev();
            cb.TenderBrowseExperiment.track('Buyer - Place Bark - Tender vs Browse - View')
        })

        // Need to do this to avoid a potential loop where the buyer clicks cancel on the last question
        $('.js-tenderbrowse-quit').off('click').on('click', function(e) {
            e.preventDefault();
            $('#js-tenderbrowse-bottomsection-container').hide()
            cb.hide();
        })

        $('.js-tenderbrowse-back').off('click').on('click', function(e) {
            e.preventDefault();
            cb.prev();
        })
    }

    postBarkCreatedHook(data) {
        $('#inlineBarkModal').off('hide.bs.modal.trackClose'); //remove tracking listener since we placed a project
        const sellerProfileId = getCreateBark().BrowseProjectExperiment?.getInteractedSellerProfileId();
        const project_id_hash = data.data?.project_id_hash;
        if (sellerProfileId && project_id_hash) {
            browseTracking('Browse-list - quote requested success', {sellerProfileId, project_id_hash});
            postResponseAction(
                {
                    projectId: project_id_hash,
                    sellerProfileId,
                    type: 'quote',
                    source: 'browse-interaction'
                },
                (data) => {
                    // save the sellerprofileId to a cookie once it is successed
                    window.Bark.set_cookie('bp_spidh', [sellerProfileId], 0, '/');
                }
            );
        }
        clearBrowseCookie();
    }

    setupBrowseForCBShowModal(isInitialFlow, validateProjectData) {
        let cb = getCreateBark();
        let variant = cb.categoryData.experiments.browse_list_v1?.variant ?? 0
        if (variant > 0) {
            cb.BrowseProjectExperiment.setPayloadArg('validateProject', validateProjectData)
            if (isInitialFlow) {
                cb.TenderBrowseExperiment.setIsActive();
                cb.TenderBrowseExperiment.setVariant(cb.categoryData.experiments.tender_browse_split.variant);
            }
            cb.TenderBrowseExperiment.setPreBarkId(cb.preBarkId)
        }
        if (isInitialFlow) {
            tracking("Experiment Allocation", "Experiment Allocation - browse_list_v1", {
                variant: variant,
                pre_bark_id: cb.preBarkId
            });
        } else {
            cb.browseModalSkipQuestions = true;
            cb.onProjectCreated = this.postBarkCreatedHook;
            const keys = Object.keys(cb.categoryData.categories);
            // if a logged in user we have to avoid appending questions twice! remove them before appending
            if (cb.categoryData.user.userLoggedIn) {
                keys.forEach((catId) => {
                    if (cb.categoryData.categories[catId].custom_fields) {
                        cb.categoryData.categories[catId].custom_fields = cb.categoryData.categories[catId].custom_fields.filter((q) => {
                           return q.type !== 'upsellLoading' && q.type !== 'tenderBrowse' && q.type !== 'buyerInfo';
                        });
                    }
                })
            }
        }
    }
}
