import { tracking } from '../../../analytics'

export default class CsatExperiment {
    variantToStep = {
        1: 'lastCategoryQuestion',
        2: 'accountEmail',
        3: 'accountTel',
        4: 'accountName'
    };

    constructor(data) {
        this.container = $('#csatModal');
        this.stepToDisplay = this.variantToStep[data.variant];
        this.sessionId = data.sessionId;
        this.bindListeners();
    }

    displayCsatMeasurement(stepToDisplay) {
        if (this.stepToDisplay == stepToDisplay) {
            $('.modal-open .modal').css('overflow-y', 'hidden');
            this.container.removeClass('d-none');
            let that = this;
            setTimeout(function () {
                that.container.addClass('proper-state');
                setTimeout(function () {
                    $('.modal-open .modal').css('overflow-y', 'auto');
                }, 1000);
            }, 50);
            this.hideKeyboardOnMobile();
            this.fixOnFocusDisplayOnMobile();
            tracking('Customer Satisfaction Feedback Impression', this.stepToDisplay, {
                session_id: this.sessionId
            });
        }
    }

    //stop csat container from sliding up on android mobile when keyboard displayed
    fixOnFocusDisplayOnMobile() {
        let thatContainer = this.container;

        if (/Android/.test(navigator.appVersion)) {
            window.addEventListener("resize", function () {
                if (document.activeElement.tagName == "INPUT" || document.activeElement.tagName == "TEXTAREA") {
                    thatContainer.addClass('android-keyboard');
                } else {
                    thatContainer.removeClass('android-keyboard');
                }
            })
        }
    }

    hideKeyboardOnMobile() {
        document.activeElement.blur();
        $("input").blur();
    }

    hide() {
        this.container.addClass('d-none');
        this.container.removeClass('proper-state');
    }

    bindListeners() {
        this.container.find('.close-csat-cross').on('click', () => {
            this.container.hide();
        });

        this.container.find('.csat-record').on(
            'click',
            {
                container: this.container,
                sessionId: this.sessionId,
                stepToDisplay: this.stepToDisplay
            },
            (event) => {
                event.preventDefault();
                const feedBack = $(event.currentTarget).attr('data-val');
                const location = event.data.stepToDisplay;
                setTimeout(function () {
                    event.data.container.fadeOut(600);
                }, 500);

                tracking('Customer Satisfaction Feedback', location, {
                    feedback_score: feedBack,
                    session_id: event.data.sessionId,
                });
            });
    }
}

