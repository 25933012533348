export default function ModalManager() {
	let disabledHTML;

	return {
		modalTemplate: Handlebars.compile(
			document.getElementById('modal-template').innerHTML,
		),
		modalErrorTemplate: Handlebars.compile(
			document.getElementById('modal-error-message-template').innerHTML,
		),
		modalSuccessContentTemplate: Handlebars.compile(
			document.getElementById('success-modal-content-template').innerHTML,
		),
		modalId: 'bark-modal',
		modalContainerId: 'modal-container',
		eventList: [],
		backdrop: 'static',
		getId: function () {
			return this.modalId;
		},
		getContainerId: function () {
			return this.modalContainerId;
		},
		getElement: function () {
			var modal = $('#' + this.getId());
			if (modal.length > 0) {
				return modal;
			}
			return null;
		},
		quickModal: function (name, description, content, actionButtons, classes, id) {
			if (typeof id === 'undefined') {
				id = false;
			}
			if (!id) {
				id = 'bark-modal-' + Math.floor(Math.random() * 999999);
			}
			if (typeof actionButtons === 'undefined' || actionButtons === null) {
				actionButtons = [];
			}
			if (typeof classes === 'undefined' || classes === null) {
				classes = [];
			}
			var modalContent = this.modalTemplate({
				modal_id: id,
				backdrop: this.backdrop,
				title: name,
				subtitle: description,
				content: content,
				action_buttons: actionButtons.join(''),
				classes: classes,
				nofade: true,
			});
			var t = this;
			$('#' + this.getContainerId()).append(modalContent);
			$('#' + id).on('hidden.bs.modal', function () {
				$('#' + id).modal('dispose');
				$('#' + id).remove();
			});
			$('#' + id).modal('show');
			return $('#' + id);
		},
		set: function (name, description, content, actionButtons, classes, events) {
			if (typeof actionButtons === 'undefined' || actionButtons === null) {
				actionButtons = [];
			}
			if (typeof classes === 'undefined' || classes === null) {
				classes = [];
			}
			var modalContent = this.modalTemplate({
				modal_id: this.getId(),
				backdrop: this.backdrop,
				title: name,
				subtitle: description,
				content: content,
				action_buttons: actionButtons.join(''),
				classes: classes,
			});
			var modal = this.getElement();
			if (modal) {
				this.update(modalContent);
			} else {
				this.create(modalContent);
			}

			var t = this;

			if (events) {
				events.forEach(function (event) {
					if (event.type && event.callback) {
						event.scope = typeof event.scope === 'undefined' ? event.scope : null;
						event.data = typeof event.data === 'undefined' ? event.data : {};

						t.setEvent(event.type, event.scope, event.data, event.callback);
					}
				});
			}

			this.getElement().modal();
		},
		setEvent: function (type, scope, data, callback) {
			this.getElement().on(type, scope, data, callback);
			this.eventList.push({ type: type, scope: scope });
		},
		clearEvents: function () {
			do {
				var event = this.eventList.pop();
				if (typeof event !== 'undefined') {
					this.getElement().off(event.type, event.scope);
				}
			} while (this.eventList.length > 0);
		},
		create: function (modalContent) {
			var t = this;
			$('#' + this.getContainerId()).append(modalContent);
			this.getElement().on('hidden.bs.modal', function (e) {
				t.cleanup();
			});
		},
		showError: function (errorMessage) {
			this.showErrors([errorMessage]);
		},
		showErrors: function (errorMessages) {
			$('.modal-alerts').empty();
			var t = this;
			errorMessages.forEach(function (errorMessage) {
				$('.modal-alerts').append(t.modalErrorTemplate({ message: errorMessage }));
			});
			$('.modal').animate(
				{
					scrollTop: $('.modal-alerts:first').offset().top,
				},
				1000,
			);
		},
		showSuccessModal: function (successMessage, successSubtext, displaySeconds) {
			successMessage = successMessage || _t('common:save-settings.success-text');
			successSubtext = successSubtext || '';

			var successContent = this.modalSuccessContentTemplate({
				message: successMessage,
				subtext: successSubtext,
			});

			var modalContent = this.modalTemplate({
				modal_id: this.getId(),
				backdrop: this.backdrop,
				title: '',
				subtitle: '',
				content: successContent,
				action_buttons: [],
				classes: ['no-header'],
			});

			var modal = this.getElement();
			if (modal) {
				this.update(modalContent);
			} else {
				this.create(modalContent);
			}
			this.getElement().modal();

			// Auto-hide the modal
			if (displaySeconds > 0) {
				window.setTimeout(function () {
					modalManager.destroy();
				}, displaySeconds * 1000);
			}
		},
		showLoading: function (loadingText) {
			if (typeof loadingText === 'undefined') {
				loadingText = _t('common:save-settings.loading-text');
			}
			this.quickModal(
				'',
				'',
				'<div class="waiting mb-2">' +
					'<div class="waiting-loader text-center mb-3">\n' +
					'    <div class="spinner-border text-primary my-0" role="status">\n' +
					'        <span class="sr-only">' +
					loadingText +
					'</span>\n' +
					'    </div>\n' +
					'</div>\n' +
					'<h4 class="mb-0 text-center">' +
					loadingText +
					'</h4>' +
					'</div>',
				[],
				['modal-sm', 'show-loading-modal'],
			);
		},
		hideLoading: function () {
			$('.show-loading-modal').parent('.modal').modal('hide');
		},
		update: function (modalContent) {
			this.getElement().html($(modalContent).html());
			this.clearEvents();
		},
		destroy: function () {
			var theModal = this.getElement();
			if (theModal) {
				theModal.modal('hide');
			}
		},
		cleanup: function () {
			this.getElement().modal('dispose');
			this.getElement().remove();
		},
		cancelLinkHTML: function (cancelText) {
			if (typeof cancelText === 'undefined') {
				cancelText = _t('common:save-settings.cancel-button');
			}
			return (
				'<a class="modal-back-link text-sm" data-dismiss="modal" href="#">' +
				cancelText +
				'</a>'
			);
		},
		submitButtonHTML: function (submitText, disabled) {
			if (typeof submitText === 'undefined') {
				submitText = _t('common:save-settings.save-button');
			}
			if (typeof disabled === 'undefined') {
				disabled = false;
			}
			disabledHTML = '';
			if (disabled) {
				disabledHTML = ' disabled="disabled"';
			}
			return (
				'<button class="btn btn-primary submit modal-submit" type="button"' +
				disabledHTML +
				'><span class="submit-spinner spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="submit-text">' +
				submitText +
				'</span></button>'
			);
		},
		basicSubmitButtonHTML: function (submitText, disabled) {
			if (typeof submitText === 'undefined') {
				submitText = _t('common:save-settings.save-button');
			}
			if (typeof disabled === 'undefined') {
				disabled = false;
			}
			disabledHTML = '';
			if (disabled) {
				disabledHTML = ' disabled="disabled"';
			}
			return (
				'<button class="btn btn-primary submit modal-submit" type="button"' +
				disabledHTML +
				'><span class="submit-text">' +
				submitText +
				'</span></button>'
			);
		},
		basicOkayButtonHTML: function (okText, disabled) {
			if (typeof submitText === 'undefined') {
				submitText = _t('common:save-settings.ok-button');
			}
			if (typeof disabled === 'undefined') {
				disabled = false;
			}
			disabledHTML = '';
			if (disabled) {
				disabledHTML = ' disabled="disabled"';
			}
			return (
				'<button class="btn btn-primary modal-back-link " data-dismiss="modal" type="button"' +
				disabledHTML +
				'><span class="submit-text">' +
				submitText +
				'</span></button>'
			);
		},
		defaultActionButtons: function (isLast, disabled) {
			var actionButtons = [
				this.cancelLinkHTML(),
				this.submitButtonHTML(
					isLast === false
						? _t('common:save-settings.next-button')
						: _t('common:save-settings.save-button'),
					disabled,
				),
			];
			return actionButtons;
		},
		setBackdrop: function (state) {
			this.backdrop = state;
		},
	};
}
