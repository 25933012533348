import { default as AdditionalDetailsWithProgress } from '../legacy-scripts/additional-details-with-progress';
import { AdditionalDetailsDesignPicker } from '../legacy-create-bark/features/additional-details';
import { default as AdditionalDetails } from '../legacy-scripts/additional-details';
import { default as CreateBarkModal } from '../legacy-create-bark/create-bark-modal-v2ef';
import { default as DropZone } from '../legacy-scripts/dropzone';
import { default as QuestionPrefills } from '../legacy-scripts/question-prefills';
import { default as RangeSliderQuestions } from '../legacy-scripts/range-slider-questions';
import { default as ContactVerification } from '../legacy-scripts/contact-verification';
import pikaday from 'pikaday';
import * as IonRangeSlider from 'ion-rangeslider';
import * as strtotime from '../legacy-scripts/strtotime';
import { useNamespace } from '../../libs/i18next';

export const initLegacyCreateBark = async () => {
	await useNamespace('dropzone');

	AdditionalDetailsWithProgress();
	window.AdditionalDetailsDesignPicker = AdditionalDetailsDesignPicker;
	window.AdditionalDetails = AdditionalDetails;
	ContactVerification();
	await CreateBarkModal();
	DropZone();
	QuestionPrefills();
	RangeSliderQuestions();
	window.Pikaday = pikaday;

	try {
		import('jquery-toast-plugin');
	} catch (e) {
		bugsnagClient.notify(new Error('Failed to import the toast'), { error: e });
	}
};
