const Bark = (window.Bark = window.Bark || {});
const cb = (Bark.createBark = Bark.createBark || {});
import { tracking } from "@bark/bark-core";

const getWebPresenceComponent = () => {
    return $(".web-presence");
};

export const isWebPresencePage = () => {
    return getWebPresenceComponent().length > 0;
};

export const initWebPresencePage = () => {
    console.log("Web presence start...");
    fillCurrencyPlaceholder();
    fillSubscriptionPlaceholder();
    registerInterestListener();
};

function fillSubscriptionPlaceholder()
{
    Bark.api('seller/available-subscriptions', {}, function (e) {
        if (e.data.length) {
            for (let i = 0; i < e.data.length; i++) {
                let cur = e.data[i];
                if (!cur.annual) {
                    $('.js-web-presence-has-vat')[cur.has_vat ? 'removeClass' : 'addClass']('d-none');
                    break;
                }
            }
        }
    }, function (e) {
        console.log('fail');
        console.log(e);
    });
}

function fillCurrencyPlaceholder() {
    Bark.api('seller/self', {}, function (e) {
        $('.js-currency-symbol').text(e.data.currency_symbol);
    }, function (e) {
        console.log('fail');
        console.log(e);
    });
}

function registerInterestListener(){
    $('.registerInterest').on('click', function () {
        tracking(
            'Web Presence',
            'Register Interest',
            {
                    'buttonClicked': $(this).data('position'),
                    'seller_profile_id': $('.web-presence').data('spf-id'),
                    'category_id': $('.web-presence').data('category-id')
                }
            );

        Bark.api(
            'seller/web-presence/register-interest',
            null,
            function (resp) {
                console.log('successful')
            },
            function(e) {
                console.log('fail');
                console.log(e);
            },
            'GET'
        );
    });
}
