import { _t } from '../../libs/i18next';
import { bugsnagClientNotify } from '../../components/bugsnag/bugsnag-client';
import { tracking as barkTrackingLogEvent } from '../../components/analytics';
import { default as Switch } from '../../components/form/switch';
import { default as Select } from '../../components/form/select';
import {
	getLastMessage,
	sendContactRequest,
	postResponseAction,
	postBulkResponseAction,
	sendManySellersAMessage,
	sendSellerAMessage,
} from '../../components/apis/buyer-api';
import { default as EventEmitter } from '../../helpers/event-emitter';

export default function () {
	const { Handlebars } = window;
	const eventEmitter = new EventEmitter();

	const state = {
		buyerPhone: '',
		sellerProfileIds: [],
		projectId: '',
		bpvId: '',
		bpvsId: '',
	};

	const callbacks = {
		modalSuccess: null,
		requestQuoteSuccess: null,
		requestQuoteError: null,
		requestQuoteRqfaSuccess: null,
		requestQuoteRqfaError: null,
	};

	const domElements = {
		modalId: 'request-reply-modal',
		modalContainerId: 'request-reply-modal-container',
		selectId: 'request-reply-select-availability',
		selectContainerId: 'request-reply-select-availability-container',
		switchId: 'request-reply-call-switch',
		switchContainerId: 'request-reply-call-switch-container',
	};

	const hbTemplates = {
		modalTemplate: Handlebars.compile(
			document.getElementById('request-reply-modal-template').innerHTML,
		),
		switchTemplate: Handlebars.compile(
			document.getElementById('switch-v1-template').innerHTML,
		),
		selectTemplate: Handlebars.compile(
			document.getElementById('select-v1-template').innerHTML,
		),
	};

	const callSwitch = new Switch(
		domElements.switchContainerId,
		domElements.switchId,
		hbTemplates.switchTemplate,
		eventEmitter,
	);

	const selectAvailability = new Select(
		domElements.selectContainerId,
		domElements.selectId,
		hbTemplates.selectTemplate,
		[
			{
				label: _t(
					'buyer_seller-request-quote:request-reply-modal.call-availability-options.now',
				),
				value: 'now',
			},
			{
				label: _t(
					'buyer_seller-request-quote:request-reply-modal.call-availability-options.evenings',
				),
				value: 'in the evening',
			},
			{
				label: _t(
					'buyer_seller-request-quote:request-reply-modal.call-availability-options.weekends',
				),
				value: 'at the weekend',
			},
		],
		eventEmitter,
	);

	const setStateDataOrDefault = (data) => {
		if (data?.sellerProfileIds) {
			setSellerProfileIds(data.sellerProfileIds);
		} else {
			state.sellerProfileIds = [];
		}
		state.projectId = data.projectId ?? '';
		state.bpvId = data.bpvId ?? '';
		state.bpvsId = data.bpvsId ?? '';
	};

	const setCallbacksOrDefault = (callbacksData) => {
		callbacks.modalSuccess = callbacksData.modalSuccess ?? null;
		callbacks.requestQuoteSuccess = callbacksData.requestQuoteSuccess ?? null;
		callbacks.requestQuoteError = callbacksData.requestQuoteError ?? null;
		callbacks.requestQuoteRqfaSuccess = callbacksData.requestQuoteRqfaSuccess ?? null;
		callbacks.requestQuoteRqfaError = callbacksData.requestQuoteRqfaError ?? null;
	};

	const setBuyerPhone = (buyerPhone) => {
		state.buyerPhone = buyerPhone;
	};

	const setSellerProfileIds = (sellerProfileIds) => {
		state.sellerProfileIds = Array.isArray(sellerProfileIds)
			? sellerProfileIds
			: [sellerProfileIds];
	};

	const setModalSuccessCallback = (callback) => {
		callbacks.modalSuccess = callback;
	};

	const show = (data, callbacksData = {}) => {
		setStateDataOrDefault(data);
		setCallbacksOrDefault(callbacksData);

		buildModal();
		callSwitch.draw();
		selectAvailability.draw();

		bindListeners();

		requestQuote();
		toggleSendMessageButton();

		toggleSelectAvailability(false);
		autofillMessage();

		logTrackingEvent('view request reply modal');

		$(`#${domElements.modalId}`).modal('show');
	};

	const buildModal = () => {
		$(`#${domElements.modalContainerId}`).empty();
		let html = hbTemplates.modalTemplate({
			isRqfa: isRqfa(),
			buyerHasPhone: !!state.buyerPhone,
			text: {
				titleRqfa: _t('buyer_seller-request-quote:request-reply-modal.title-rqfa'),
				title: _t('buyer_seller-request-quote:request-reply-modal.title'),
				subRqfa: _t('buyer_seller-request-quote:request-reply-modal.sub-rqfa', {
					end_span: '</span>',
					count: state.sellerProfileIds.length,
					top_matches_count_span: "<span class='js-top-matches-count'>",
					top_matches_string_span: "<span class='js-top-matches-string'>",
					interpolation: { escapeValue: false },
				}),
				sub: _t('buyer_seller-request-quote:request-reply-modal.sub'),
				detailsAttached: _t(
					'buyer_seller-request-quote:request-reply-modal.details-attached',
				),
				askForAQuote: _t('buyer_seller-request-quote:request-reply-modal.ask-for-quote'),
				requestCall: _t('buyer_seller-request-quote:request-reply-modal.request-call'),
				messagePlaceholder: _t(
					'buyer_seller-request-quote:request-reply-modal.message-placeholder',
				),
				sendAMessageTitle: _t(
					'buyer_seller-request-quote:request-reply-modal.send-a-message-title',
				),
				sendAMessageTitleRqfa: _t(
					'buyer_seller-request-quote:request-reply-modal.send-a-message-title-rqfa',
					{
						count: state.sellerProfileIds.length,
					},
				),
				sendAMessageSub: _t(
					'buyer_seller-request-quote:request-reply-modal.send-a-message-sub',
				),
				sendMessageButton: _t(
					'buyer_seller-request-quote:request-reply-modal.send-message-button',
				),
				skipMessageButton: _t(
					'buyer_seller-request-quote:request-reply-modal.skip-message-button',
				),
				messageSent: _t('buyer_seller-request-quote:request-reply-modal.message-sent'),
				replyRequested: _t(
					'buyer_seller-request-quote:request-reply-modal.reply-requested',
					{ count: state.sellerProfileIds.length },
				),
			},
		});
		$(`#${domElements.modalContainerId}`).append(html);
	};

	const bindListeners = () => {
		eventEmitter.on(`switch.${domElements.switchId}.changed`, (args) => {
			toggleSelectAvailability(args.checked);
			toggleSendMessageButton();
			logTrackingEvent('request a call toggle');
		});

		eventEmitter.on(`select.${domElements.selectId}.clicked`, (args) => {
			logTrackingEvent('availability box');
		});

		$(`#${domElements.modalId} #request-reply-message`).keyup(() => {
			toggleSendMessageButton();
		});

		$(`#${domElements.modalId} .js-submit-send-message`)
			.off('click')
			.on('click', function (e) {
				e.preventDefault();
				submitSendMessage();
				logTrackingEvent('send message');
			});

		$(`#${domElements.modalId} .js-skip-send-message`)
			.off('click')
			.on('click', function (e) {
				e.preventDefault();
				logTrackingEvent('skip send message');
				$(`#${domElements.modalId}`).modal('hide');
				delay(500).then(() => {
					callbacks.modalSuccess && callbacks.modalSuccess();
				});
			});
	};

	const toggleSelectAvailability = (isChecked) => {
		if (isChecked) {
			$(`#${domElements.selectContainerId} .select-v1`)
				.removeClass('d-none')
				.addClass('d-flex');
		} else {
			$(`#${domElements.selectContainerId} .select-v1`)
				.removeClass('d-flex')
				.addClass('d-none');
		}
	};

	const toggleSendMessageButton = () => {
		const toggle = callSwitch.isChecked() || !!getMessage();
		$(`#${domElements.modalId} .js-submit-send-message`).prop('disabled', !toggle);
	};

	const isRqfa = () => {
		return Array.isArray(state.sellerProfileIds) && state.sellerProfileIds.length > 1;
	};

	const autofillMessage = () => {
		if (state.projectId) {
			$(`#${domElements.modalId} .msg-box-spinner`).removeClass('d-none');
			getLastMessage(
				{ type: 'request-quote', projectId: state.projectId },
				(res) => {
					if (res.data && res.data?.message) {
						$(`#${domElements.modalId} #request-reply-message`)
							.val(res.data.message)
							.change()
							.focus();
					}
					$(`#${domElements.modalId} .msg-box-spinner`).addClass('d-none');
					toggleSendMessageButton();
				},
				(error) => {
					$(`#${domElements.modalId} .msg-box-spinner`).addClass('d-none');
				},
			);
		}
	};

	const submitSendMessage = () => {
		startWaiting();
		sendCallAvailability()
			.then((result) => {
				delay(1000, result);
			})
			.then((result) => {
				sendRequestQuoteMessage();
			})
			.then((result) => {
				showSuccess();
			})
			.catch((error) => {
				let errMsg = isRqfa()
					? 'Failed to send message to all'
					: 'Failed to send message to seller';
				bugsnagClientNotify(errMsg, error);
			})
			.finally(() => {
				stopWaiting();
			});
	};

	const startWaiting = () => {
		$(`#${domElements.modalId} .js-submit-send-message`).prop('disabled', true);
		$(`#${domElements.modalId} .js-submit-send-message .spinner-border`).removeClass(
			'd-none',
		);
		$(`#${domElements.modalId} .js-submit-send-message .btn-text`).text(
			_t('buyer_seller-request-quote:request-reply-modal.sending-message-button'),
		);
	};

	const stopWaiting = () => {
		$(`#${domElements.modalId} .js-submit-send-message .spinner-border`).addClass(
			'd-none',
		);
		$(`#${domElements.modalId} .js-submit-send-message .btn-text`).text(
			_t('buyer_seller-request-quote:request-reply-modal.send-message-button'),
		);
		$(`#${domElements.modalId} .js-submit-send-message`).prop('disabled', false);
	};

	const showSuccess = () => {
		$(`#${domElements.modalId} .modal-body.main`).addClass('d-none');
		$(`#${domElements.modalId} .modal-footer.main`).addClass('d-none');
		$(`#${domElements.modalId} .modal-body.success`)
			.removeClass('d-none')
			.addClass('d-flex');

		delay(1500)
			.then(() => {
				$(`#${domElements.modalId}`).modal('hide');
				delay(1000);
			})
			.then(() => {
				callbacks.modalSuccess && callbacks.modalSuccess();
			});
	};

	const getMessage = () => {
		return $(`#${domElements.modalId} #request-reply-message`).val();
	};

	const sendCallAvailability = () => {
		if (!callSwitch.isChecked()) {
			return Promise.resolve();
		}

		let callAvailabilityRequests = [];
		state.sellerProfileIds.forEach((sellerProfileId) => {
			let requestPromise = sendContactRequest({
				projectId: state.projectId,
				sellerProfileId: sellerProfileId,
				bpvsId: state.bpvsId,
				source: 'seller-list',
				type: 'contact',
				phone: state.buyerPhone,
				timeframe: selectAvailability.getSelectedValue(),
				message: '',
			});
			callAvailabilityRequests.push(requestPromise);
		});

		return Promise.all(callAvailabilityRequests);
	};

	const requestQuote = () => {
		return new Promise((resolve, reject) => {
			if (isRqfa()) {
				postBulkResponseAction(
					{
						type: 'quote',
						source: 'bulk-buyer-interaction',
						sellerProfileIdArray: state.sellerProfileIds,
						projectId: state.projectId,
						bpvid: state.bpvId,
						log_event: true,
					},
					(response) => {
						callbacks.requestQuoteRqfaSuccess &&
							callbacks.requestQuoteRqfaSuccess(response);
						resolve(response);
					},
					(error) => {
						callbacks.requestQuoteRqfaError && callbacks.requestQuoteRqfaError(error);
						reject(error);
					},
				);
			} else {
				postResponseAction(
					{
						type: 'quote',
						source: 'buyer-interaction',
						sellerProfileId: state.sellerProfileIds[0],
						projectId: state.projectId,
						bpvsid: state.bpvsId,
					},
					(response) => {
						callbacks.requestQuoteSuccess &&
							callbacks.requestQuoteSuccess(response, state.sellerProfileIds[0]);

						resolve(response);
					},
					(error) => {
						callbacks.requestQuoteError && callbacks.requestQuoteError(error);
						reject(error);
					},
				);
			}
		});
	};

	const sendRequestQuoteMessage = () => {
		if (state.sellerProfileIds.length === 0 || !getMessage()) {
			return Promise.resolve();
		}

		return new Promise((resolve, reject) => {
			if (isRqfa()) {
				sendManySellersAMessage(
					{
						message: getMessage(),
						bpvid: state.bpvId,
						source: 'bulk-buyer-interaction',
						spidhArray: state.sellerProfileIds,
						projectIdHash: state.projectId,
					},
					(response) => {
						resolve(response);
					},
					(error) => {
						reject(error);
					},
				);
			} else {
				sendSellerAMessage(
					{
						message: getMessage(),
						bpvs: state.bpvsId,
						source: 'buyer-interaction',
						sellerProfileId: state.sellerProfileIds[0],
						projectId: state.projectId,
					},
					(response) => {
						resolve(response);
					},
					(error) => {
						reject(error);
					},
				);
			}
		});
	};

	const logTrackingEvent = (eventName) => {
		barkTrackingLogEvent('seller-list-&-profile', `request-reply - ${eventName}`, {
			project_id: state.projectId,
		});
	};

	const delay = (ms, response = null) => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(response);
			}, ms);
		});
	};

	return {
		show,
		setBuyerPhone,
		setSellerProfileIds,
		setModalSuccessCallback,
		logTrackingEvent,
	};
}
