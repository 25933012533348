export const getLang = (fallback = 'en') => {
    return window?.Bark?.ENV?.lang || fallback;
}

export const getCountry = (fallback = 'GB') => {
    return window?.Bark?.ENV?.locale || fallback;
}

export const getFullLocale = (fallback = 'en-GB') => {
    return window?.Bark?.ENV?.lang && window?.Bark?.ENV?.locale ?
        `${window.Bark.ENV.lang}-${window.Bark.ENV.locale.toUpperCase()}` :
        fallback;
}
