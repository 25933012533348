export function formatExperimentsForNRF(preValidateResponse) {
	return (
		Object.keys(preValidateResponse.experiments ?? {})
			.map((key) => {
				if (preValidateResponse.experiments[key] === null) return;

				return {
					name: key,
					...preValidateResponse.experiments[key],
				};
			})
			.filter((x) => x) ?? []
	);
}
