export default function() {
    var barkModal = $('#inlineBarkModal');

    barkModal.on('show.bs.modal', function (e) {
        $('body').addClass('no-scroll');
    });

    barkModal.on('hidden.bs.modal', function (e) {
        $('body').removeClass('no-scroll');
    });
}
