const getCustomerTermsBlock = () => document.querySelector('#customer-terms-block');
const getProfessionalTermsBlock = () => document.querySelector('#professional-terms-block');
const getCustomerTab = () => document.querySelector('.customer-tab');
const getProfessionalTab = () => document.querySelector('.professional-tab');

const resetPage = () => {
  getCustomerTermsBlock().classList.add('d-none');
  getProfessionalTermsBlock().classList.add('d-none');
  getCustomerTab().classList.remove('active');
  getProfessionalTab().classList.remove('active');
}

export const showCustomerTab = () => {
  resetPage();
  getCustomerTermsBlock().classList.remove('d-none');
  getCustomerTab().classList.add('active');
}

export const showProfessionalTab = () => {
  resetPage();
  getProfessionalTermsBlock().classList.remove('d-none');
  getProfessionalTab().classList.add('active');
}