const Bark = (window.Bark = window.Bark || {});
const cb = (Bark.createBark = Bark.createBark || {});
import { tracking } from "@bark/bark-core";

const getReferAFriendComponent = () => {
    return $(".refer-a-friend");
};

export const isReferAFriendPage = () => {
    return getReferAFriendComponent().length > 0;
};

export const initReferAFriendPage = () => {
    console.log("Refer a friend start...");
    registerCopyButtonListener();
    shareButtonListener();
};

function shareButtonListener() {
    $('.refer-share-btn').on('click', function () {
        tracking(
            'Refer a friend',
            'Share button',
            {
                'buttonClicked': $(this).data('type'),
                'seller_profile_id': $('.refer-a-friend').data('spf-id'),
                'category_id': $('.refer-a-friend').data('category-id')
            }
        );
    });
}

function registerCopyButtonListener(){
    $('.referral-btn-copy').on('click', function () {

        var selector = $(this).data('copy');
        var copyText = document.getElementById(selector);
        var copyTest = document.queryCommandSupported('copy');
        if (copyTest === true) {
            /* Select the text field */
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices */

            /* Copy the text inside the text field */
            document.execCommand("copy");
            $(this).html("Copied");

        } else {
            // Fallback if browser doesn't support .execCommand('copy')
            window.prompt("Copy to clipboard: Ctrl+C or Command+C, Enter", copyText);
        }

        tracking(
            'Refer a friend',
            'Copy link',
            {
                    'buttonClicked': $(this).data('position'),
                    'seller_profile_id': $('.refer-a-friend').data('spf-id'),
                    'category_id': $('.refer-a-friend').data('category-id')
                }
            );
    });
}
