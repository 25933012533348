import { tracking } from './bark-tracking';

export function logProjectInitialised({
	categoryId,
	preBarkId,
	countryId,
	eventGroup = 'Place Bark',
	...rest
}) {
	tracking(eventGroup, 'New Project Initialised Legacy Flow', {
		category_id: categoryId,
		pre_bark_id: preBarkId,
		country_id: countryId,
		...rest,
	});
}

export default class CreateBarkTracking {
    constructor() {
        this._startBarkLogged = false;
        this._categoryId = null;
        this._countryId = null;
        this._eventGroup = 'Place Bark';
        this._flow = 'create-bark-modal';
    }

    setFlow(isNewRequestFlow){
        this._flow = isNewRequestFlow ? 'new-request-app-v1' : 'create-bark-modal'
    }
    getFlowParams(){
        return {
            'app-version' : this._flow,
        }
    }

    setCategoryId(categoryId) {
        categoryId = parseInt(categoryId);
        this._categoryId = categoryId ? categoryId : null;
    }

    setCountryId(countryId) {
        this._countryId = countryId ? parseInt(countryId) : null;
    }

    logStartBark(categoryId, preBarkId) {
        // logs that the user has started a bark, which means either
        //   - they have started typing into a category or location input
        //   - they have done something else which causes the bark modal to show
        if (!this._startBarkLogged) {
            this._startBarkLogged = true;
            tracking(
                this._eventGroup,
                'Start Bark',
                {
                    category_id: categoryId ? parseInt(categoryId) : this._categoryId,
                    pre_bark_id: preBarkId ? preBarkId : null,
                    country_id: this._countryId,
                    ...this.getFlowParams(),
                }
            );
        }
    }

    _getPageType(rawPageType) {
        let pageType = Bark.ucfirst(rawPageType);

        switch (rawPageType) {
        case 'select':
        case 'checkbox':
        case 'photoCheck':
        case 'photoSelect':
        case 'text':
        case 'textArea':
            pageType = 'Question';
            break;
        case 'postcode':
            pageType = 'Location';
            break;
        case 'accountEmail':
            pageType = 'Email';
            break;
        case 'accountTel':
            pageType = 'Phone Number';
            break;
        }
        return pageType
    }

    logModalPageEvent(categoryId, preBarkId, rawPageType, eventType, page = {}, params = {}) {
        params['bark_modal_page_type'] = rawPageType;
        params['category_id'] = parseInt(categoryId) ? parseInt(categoryId) : null;
        params['pre_bark_id'] = parseInt(preBarkId) ? parseInt(preBarkId) : null;

        let pageType = this._getPageType(rawPageType);

        if (pageType === 'Question') {
            // index in this case starts from 1
            const {name, index} = page;
            params['question_name'] = name;
            params['question_number'] = index;
            let completeIndex = eventType === 'success' ? index : (index - 1);
            params['question_completion_percent'] = completeIndex / params['question_number_total'];
        }

        pageType += ' - ' + eventType;

        tracking(this._eventGroup, pageType, {
            ...params,
            ...this.getFlowParams(),
        });
    }
}
