
export function BuyerMessenger() {

	$(document).ready(function () {

		if ($('#buyer-dashboard').length) { // legacy php view
			getCombinedUpdates(0, 0);
		}

	});

}
