import {clearAllAlerts, showAlertAndHideAfterSeconds} from "../components/magic-link/helpers/alert";
import {resendByExpiredMagicLink} from "../components/magic-link/helpers/api";
import {
    getEmailSessionStorage,
    setEmailSessionStorage,
    setMagicTokenSessionStorage
} from "../components/magic-link/helpers/session-storage";
import debounce from "just-debounce-it";
import {bugsnagClientNotify, _l} from "@bark/bark-core";

export const ExpiredLinkSentPage = async () => {
    let email = document.querySelector('#email').value;
    if (email.length > 0) {
        setEmailSessionStorage(email);
    } else {
        email = getEmailSessionStorage();
        if (email?.length){
            document.querySelector('#email-display #email-value').innerHTML = email;
        } else {
            window.location.href = _l('/login/send-link/');
        }
    }

    const urlParams = new URLSearchParams(window.location.search);
    const debouncedSendMagicLink = debounce(async () => {
        const mgk = urlParams.get('mgk');
        const magicLinkResponse = await resendByExpiredMagicLink(mgk);
        if (magicLinkResponse?.status) {
            setMagicTokenSessionStorage(mgk)
            window.location.href = document.querySelector('[name=dest_url]').value;
        } else {
            bugsnagClientNotify('Magic Link resend not able to send');
        }
    }, 1000);

    //listeners
    document.querySelector('.resent-link').addEventListener("click", async (e) => {
        e.preventDefault();
        debouncedSendMagicLink();
    });
}
