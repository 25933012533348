/**
 * Parse a markdown string and return a HTML string
 * @param {string} markdown
 * @notes A partial implementation of the CommonMark spec, tested against the first couple paragraphs of the spec
 * @see https://raw.githubusercontent.com/jgm/CommonMark/master/spec.txt
 * @see https://parsedown.org/demo
 * @returns {string}
 * @constructor
 */
export default function Markdown (markdown) {

    /**
     * Tokens which are block level
     * @type {object}
     */
    const blockTokens = {
        '#[^#]': 'h1',
        '##[^#]': 'h2',
        '###[^#]': 'h3',
        '####[^#]': 'h4',
        '#####[^#]': 'h5',
        '######[^#]': 'h6',
        '-{2,}': 'hr',
        '\\d+\\.\\s': 'ol',
        '-\\s': 'ul',
        '```': 'pre',
        '(>|&gt;)': 'blockquote',
    };

    /**
     * What to replace a block level token with
     * @type {object}
     */
    const blockReplaceMap = {
        'h1': '#+',
        'h2': '#+',
        'h3': '#+',
        'h4': '#+',
        'h5': '#+',
        'h6': '#+',
        'hr': '-{2,}',
        'pre': '```',
    };

    /**
     * Determine what the block level token should be
     * @param {string} text
     * @returns {{text: string|null, chosenToken: string|null}}
     */
    const getBlockToken = (text) => {
        const trimmed = text.trim();
        let chosenToken = 'p';

        if (!text) {
            return {chosenToken: null, text: null}
        }

        Object.keys(blockTokens).forEach(key => {
            const re = new RegExp(`^${key}`);

            if (text.match(re)) {
                chosenToken = blockTokens[key];
                return false;
            }
        });

        if (blockReplaceMap[chosenToken]) {
            text = text.replace(new RegExp(`^${blockReplaceMap[chosenToken]}`), '').trim();
        }

        return {
            chosenToken,
            text
        };
    };

    /**
     * Determine whether a token is for a list element
     * @param {string} test
     * @returns {boolean}
     */
    const isListElement = test => {
        return ['ul', 'ol', 'li'].includes(test);
    };

    /**
     * Process images
     * @param {string} text
     * @returns {string}
     */
    const processImages = text => {
        return text.replace(/!\[([^\]]*)\]\(([^\)]*)\)/g, (_, alt, src) => {
            return `<img src="${src}" alt="${alt}"/>`;
        });
    };

    /**
     * Process any links
     * @param {string} text
     * @returns {string}
     */
    const processLinks = text => {
        text = text.replace(/\[([^\]]*)\]\(([^\)]*)\)/g, (_, bodyText, href) => {
            return `<a href="${href}">${bodyText}</a>`;
        });

        return text.replace(/<http([^\s>]*)>/, (_, href) => {
            return `<a href="http${href}">http${href}</a>`;
        })
    };

    /**
     * Parse inline tokens, such as bold, italics, links, etc
     * @param {string} text
     * @param {string} currentBlockToken The current block level token, e.g. pre, ol
     * @returns {string|*}
     */
    const processTokensInBlock = (text, currentBlockToken) => {
        const patterns = {
            '\\*\\*([^*]+)\\*\\*': 'strong',
            '__([^_]+)__': 'strong',
            '\\*([^*]+)\\*': 'em',
            '_([^_]+)_': 'em',
            '~([^~]+)~': 'strike',
            '`([^`]+)`': 'code',
            '\\d+\\.(.*)': 'li',
            '-[^-](.*)': 'li',
            '^>(.*)': '',
            '^&gt;(.*)': '',
        };
        let pattern;

        if (currentBlockToken === 'pre') {
            return text;
        }

        for (pattern in patterns) {
            text = text.replace(new RegExp(pattern, 'g'), (original, capture) => {
                if (isListElement(patterns[pattern]) && !isListElement(currentBlockToken)) {
                    return original;
                }

                if (patterns[pattern]) {
                    return `<${patterns[pattern]}>${capture.trim()}</${patterns[pattern]}>`;
                } else {
                    return capture.trim();
                }
            });
        }

        text = processImages(text);
        text = processLinks(text);

        return text;
    };

    /**
     * Parse text into block level chunks
     * @param {string} string
     * @returns {string[]} The text, grouped by block
     */
    const getBlocks = string => {
        const output = [''];
        const lines = string.split('\n');
        let waitingFor = null;
        let waitingForReplace = null;

        lines.forEach((line, index) => {
            if (waitingFor) {

                if (line.trim().match(new RegExp(waitingFor))) {
                    waitingFor = null;


                    if (waitingForReplace) {
                        line = line.replace(new RegExp(waitingForReplace), '');
                        waitingForReplace = null;
                    }

                    output[output.length - 1] += `\n${line.trim()}`;

                    output.push('');
                } else {
                    output[output.length - 1] += `\n${line.trim()}`;
                }

            } else {
                if (line.match(/^#/)) {
                    output.push(line);
                    output.push('');
                } else if (line.match(/^```/)) {
                    output.push(line);

                    if (line === '```' || !line.trim().match(/```$/)) {
                        waitingFor = '```$';
                        waitingForReplace = '```$';
                    }

                } else if (line.match(/^(>|&gt;)/)) {
                    output.push(line);
                    waitingFor = '^[^>|&gt;]';
                } else if (line.match(/^-\s/)) {
                    output.push(line);
                    waitingFor = '^[^-]';
                } else if (line === '') {
                    output.push('');
                } else {
                    output[output.length - 1] += `${line.trim()}\n`;
                }
            }
        });

        return output.filter(x => { return !!x; });
    };

    /**
     * Parse a markdown string and return a HTML string
     * @param {string} string
     * @returns {string}
     */
    const parse = string => {
        const output = [];
        const blocks = getBlocks(string.trim());

        blocks.forEach(block => {
            const { chosenToken: rowToken, text } = getBlockToken(block);
            const lines = text ? text.split('\n') : [];
            const outputLines = [];

            if (!lines) {
                return;
            }

            lines.forEach(line => {
                line && outputLines.push(processTokensInBlock(line, rowToken));
            });

            output.push(`<${rowToken}>${outputLines.join('\n')}</${rowToken}>`);
        });

        return output.join('\n');
    };

    return parse(markdown);
};
