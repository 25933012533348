export const streamComplete = ($textfield, url, method, onSelectCallback) => {
    var sc = $($textfield).streamComplete({
        src: `${window.Bark.ENV.api_hostname}/${url}`,
        requestmethod: method || 'GET',
        clearonemptysearch: true,
        delay: 500,
        usestandardsyntax: true,
        onselect: onSelectCallback || function () {},
        onbeforesearch: function (payload) {
            payload.coid = window.Bark.ENV.ccid;
        },
        preprocessresults: function (data) {
            var output = [];
            var cur;
            var i;
            if (['#postcode', '#category_name_top'].includes($textfield) && data.length > 10) {
                data = data.slice(0, 10);
            }

            for (i = 0; i < data.length; i++) {
                cur = data[i];
                cur.value = cur.name;
                output.push(cur);
            }

            return output;
        },
        onopen: function (result, outputbounds) {
            var T = this;
            var offset = T.offset();
            var rect;

            if ($textfield === '#postcode' && $('.full-postcode-input #postcode').length) {
                rect = T.closest('.full-postcode-input')[0].getBoundingClientRect();
                outputbounds.top = (offset.top + rect.height) - 4;
                outputbounds.left = rect.left;
                outputbounds.width = rect.width;
            }

            if ($textfield === '#category_name_top') {
                rect = T.closest('.category_name_top')[0].getBoundingClientRect();
                outputbounds.top = 75;
                outputbounds.left = rect.left;
                outputbounds.width = rect.width;
                outputbounds.position = 'fixed';
            }
        },
        // prevent different fields from using the same results container
        classnames: ['results-' + $textfield.replace(/[^0-9A-Za-z]/g, '')],
        minlength: $textfield.indexOf('postcode') > -1 ? 3 : 2
    }).data('streamcomplete');

    // For the postcode fields, if they enter a valid postcode or location, but do not actually select it from the dropdown, still use it
    // This would be determined by there being a single matching result
    if (['#postcode', '#postcode-auto', '.inline-bark-q-text #postcode-auto'].indexOf($textfield) > -1) {
        // For each location search response, if there is only one matching result, set the hidden location fields to that result.
        // If there's more or less than 1, clear the location fields.
        sc.s.onresponse = function (e) {
            if (e.data && e.status && e.status === true) {
                if (e.data.length === 1) {
                    $("#postcode_id").val(e.data[0].id);
                    $("#postcode_type").val(e.data[0].type);
                    $('#postcode').data({ locationData: e.data[0] });
                    $('#postcode-auto').data({ locationData: e.data[0] });
                    $('#urgent-lat').val(e.data[0].lat)
                    $('#urgent-lng').val(e.data[0].lng)
                } else {
                    $("#postcode_id").val(null);
                    $("#postcode_type").val(null);
                    $('#postcode').data({ locationData: null });
                    $('#postcode-auto').data({ locationData: null });
                }
            }
        };

        sc.s.onclose = function(e) {
            // If the user has cleared the field, remove the options and selection
            if ($($textfield).val() == "") {
                sc.results = [];
                sc.render([]);
                $("#postcode_id").val(null);
                $("#postcode_type").val(null);
                $('#postcode').data({ locationData: null });
                $('#postcode-auto').data({ locationData: null });
                return;
            }
            // If they close the dropdown with only one selection, update the text to match it
            // (The ID will already have been handled by onresponse)
            if (e.results && e.results.length === 1 && $("#postcode_id").val() > 0) {
                $($textfield).val(e.results[0].name);
            }
        };
    }

    // On each response, clear out any previous selection, as we will now have new options.
    if ($textfield === "#category_name") {
        sc.s.onresponse = function (e) {
            $("#category_id").val(null);
            // If there is only one match, OR the first item is an exact match, use that item
            if (e.data && e.status && (e.status === true) && e.data.length) {
                if ((e.data.length === 1) || (e.data[0].name.toLowerCase() == $($textfield).val().toLowerCase())) {
                    $("#category_id").val(e.data[0].id);
                }
            }
        };

        sc.s.onclose = function(e) {
            // If the user has cleared the field, remove the options and selection
            if ($($textfield).val() == "") {
                sc.results = [];
                sc.render([]);
                $("#category_id").val(null);
                return;
            }
            // If they close the dropdown with only one selection, update the text to match it
            // (The ID will already have been handled by onresponse)
            if (e.results && e.results.length === 1 && $("#category_id").val() > 0) {
                $($textfield).val(e.results[0].name);
            }
        };
    } else if ($textfield === "#category_name_top") {
        sc.s.onresponse = function (e) {
            $("#category_id_top").val(null);
            // If there is only one match, OR the first item is an exact match, use that item
            if (e.data && e.status && (e.status === true) && e.data.length) {
                if ((e.data.length === 1) || (e.data[0].name.toLowerCase() == $($textfield).val().toLowerCase())) {
                    $("#category_id_top").val(e.data[0].id);
                }
            }
        }

        sc.s.onclose = function(e) {
            // If the user has cleared the field, remove the options and selection
            if ($($textfield).val() == "") {
                sc.results = [];
                sc.render([]);
                $("#category_id_top").val(null);
                return;
            }
            // If they close the dropdown with only one selection, update the text to match it
            // (The ID will already have been handled by onresponse)
            if (e.results && e.results.length === 1 && $("#category_id_top").val() > 0) {
                $($textfield).val(e.results[0].name);
            }
        };
    }

    return sc;
}

window.streamComplete = streamComplete;
