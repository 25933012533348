import { formatExperimentsForNRF } from '@bark/bark-core/src/components/new-request-flow/helpers/experiments';

export const popupEqualsTrueHandler = async ({ params }) => {
	if (params && canPopup(params)) {
		let { locid: locationId, pt: postCodeType, alid, locact } = params;

		locationId = isNaN(Number(locationId)) ? -1 : Number(locationId);
		postCodeType ??= -1;

		let {
			getPayloadForPopupTrue,
			launchBarkModal,
		} = await import('@bark/bark-core');

		const thePayloadFormdata = getPayloadForPopupTrue({
			locationId,
			postCodeType,
			alid,
			locact,
		});

		launchBarkModal(thePayloadFormdata);

	}
};

function canPopup(params) {
	if (params.autosearch && $('#category_id').val()) {
		return true;
	}

	if (params && (params.popup === 'true' || params.nrfp === 'true')) {
		const form = $('#project-create-form');
		const isPopupTurnedOffOnPage = $('.bark-journey-popup-off').length > 0;
		return form.attr('can-popup') !== undefined && !isPopupTurnedOffOnPage;
	}

	return false;
}
