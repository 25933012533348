//jquery
import $ from 'jquery';

// I don't love this but foundation pages are going to set their own jQuery and add foundation so we will not overwrite here
// We should aim to get rid of this asap so it is only the jQuery we import here.
window.jQuery = window.jQuery || $;
window.$ = window.$ || $;

// This needs to be done before any dynamic imports are used so that old browsers can support dynamic import.
import dynamicImportPolyfill from 'dynamic-import-polyfill';

/**
 * The string STATIC_ASSETS_PATH is replaced at build time by rollup using a build env
 */
dynamicImportPolyfill.initialize({
	// This is the variable that will be replaced by @rollup/plugin-replace
	modulePath: 'STATIC_ASSETS_PATH',
	importFunctionName: 'dynamicImportPolyfill',
});

//bootstrap
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';

// 3rd party
import 'popper.js';
import jstz from 'jstz';
import Handlebars from 'handlebars/dist/cjs/handlebars';
window.jstz = jstz;

//components
import { Nav, HeaderJs, BarkModal, FooterJs, DirectoryExplore } from '../navigation';

// utils
import { ClearInputHelper, FirefoxAntiFlicker, QueryStringUtil } from '../../helpers';

//legacy
import { default as Consts } from '../legacy-scripts/consts';
import { default as BarkVersionTen } from '../legacy-scripts/bark-v10';
import {
	default as BarkLib,
	exposeBarkLibFunctionsToTheWindow,
} from '../legacy-scripts/barklib';

import { init as initHeapServerSideTracking } from '../analytics/heap-server-side';
import { SendAppInitEvent } from '../../helpers/legacy-scripts-support';
import { initI18n } from '../../libs/i18next';
import registerI18nHelper from 'handlebars-i18next';

//functions
const runJavascriptFromTheOldWebpackBundle = () => {
	HeaderJs();
	FooterJs();
	window.QS = new QueryStringUtil();
	Nav();
	BarkModal();
	ClearInputHelper();
	FirefoxAntiFlicker();
};

export const initCore = async () => {
	const i18nInstance = await initI18n();
	registerI18nHelper(Handlebars, i18nInstance, '_t');
	window.Handlebars = Handlebars;
	Consts();
	BarkVersionTen();
	BarkLib();
	exposeBarkLibFunctionsToTheWindow();
	DirectoryExplore();
	initHeapServerSideTracking();

	Bark.ENV.tz = jstz.determine().name();

	runJavascriptFromTheOldWebpackBundle();

	SendAppInitEvent('core');

	const event = new Event('coreLoaded');
	document.dispatchEvent(event);
};
