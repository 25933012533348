/**
 * Remove falsey items from an object or array
 * @param {object|array} objectOrArray
 * @returns {object|array}
 * @see https://stackoverflow.com/a/57625661/1925574
 */
export const cleanEmpty = objectOrArray => {
    if (Array.isArray(objectOrArray)) {

        return objectOrArray
            .map(value => (value && typeof value === 'object') ? cleanEmpty(value) : value)
            .filter(value => !(value == null));

    } else {
        return Object.entries(objectOrArray)
            .map(([keys, value]) => [keys, value && typeof value === 'object' ? cleanEmpty(value) : value])
            .reduce((a, [key, value]) => (value == null ? a : (a[key] = value, a)), {});
    }
}
