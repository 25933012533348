import { barkApi } from "../../legacy-scripts/barklib";
import { bugsnagClientNotify } from "../../bugsnag/bugsnag-client";

export const sendPasswordChange = async (newpass) => new Promise( resolve => {
  barkApi(
        `user/password-web`,
        {
            newpass,
        },
        (res) => {
          resolve(res);
        },
        (error) => {
          console.error(error);
          bugsnagClientNotify('sendPasswordChange not successful', error);
          resolve(false);
        },
        'POST'
    );
});