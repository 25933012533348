import { isChameleonActive } from './helpers/check';
import { isLauncherClosed, isLauncherInDom, openLauncher } from './helpers/launchers';
import { setAutoLauncherOpenedLocalStorage, getAutoLauncherOpenedLocalStorage } from './helpers/local-storage';
import { tracking as heapTracking } from '../analytics/bark-tracking';

export const autoOpenLauncherAfterSeconds = ($seconds) => {
  if(getAutoLauncherOpenedLocalStorage()) return;

  setTimeout(() => {
    if(!isChameleonActive() || !isLauncherInDom()) return;

    heapTracking('Chameleon Event', 'Auto open launcher - on page');
    if(isLauncherClosed()){
      openLauncher();
      setAutoLauncherOpenedLocalStorage();
      heapTracking('Chameleon Event', 'Auto open launcher - auto opened');
    }
  }, $seconds * 1000);
} 

export const trackLauncherOnPageAfterSeconds = ($seconds) => {  
  setTimeout(() => {
    if(!isChameleonActive() || !isLauncherInDom()) return;
  
    heapTracking('Chameleon Event', 'Launcher - on page');
  }, $seconds * 1000);
} 