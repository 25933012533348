import { useNamespace, _t } from '../../libs/i18next';

export default function BarkVersionTen () {

    useNamespace(['seller_profile']).then(function() {
        var sellerProfileChanged = false;

        let setupAutocomplete = window.setupAutocomplete = ($textfield, query_url, resultfields, mapping_func, data_values_subkey, alternative_suggestions) => {
            var bestMatch = [];

            var all_results_filled = function () {
                var all_filled = true;

                jQuery.each(resultfields, function (idx, it) {
                    if (it.jqobj.val().length <= 0) {
                        all_filled = false;
                    }
                });

                return all_filled;
            };

            var processData = function (data, query) {

                try {
                    if (data_values_subkey === undefined) {
                        array = $.map(data.values, mapping_func);
                    } else {
                        array = $.map(data.values[data_values_subkey], mapping_func);
                    }
                } catch (err) {
                    var array = [];
                }

                var match_count = 0;
                var matched_item = null;

                var query_sanitised = query.replace(new RegExp(' ', 'g'), '')

                $.each(array, function (key, val) {
                    if (val.label.toLowerCase() === query.toLowerCase() || val.label.toLowerCase() === query_sanitised.toLowerCase()) {
                        matched_item = val;
                        match_count++;
                    } else if (val.match.toLowerCase() === query.toLowerCase() || val.match.toLowerCase() === query_sanitised.toLowerCase()) {
                        matched_item = val;
                        match_count++;
                    }
                });

                if (match_count == 1) {
                    bestMatch = matched_item;
                    jQuery.each(resultfields, function (idx, it) {
                        it.jqobj.val(matched_item[it.attr]);
                        if (typeof it.jqobj.trigger === 'function') {
                            it.jqobj.trigger('change');
                        }
                    });
                }

                if (array.length == 1 && Object.keys(bestMatch).length) {
                    return [];
                }

                return array;

            };
            var requestHandle = null;
            var sourcefunc = function (request, response) {
                var query = request.term.toLowerCase();

                if (query in cache) {
                    var data = cache[query];

                    response(processData(data, query));

                    return;
                }

                // cancel any previous request to prevent blocking
                if (requestHandle) {
                    requestHandle.abort();
                    requestHandle = null;
                }

                requestHandle = $.getJSON(query_url, {q: query}, function (data, status, xhr) {
                    cache[query] = data;
                    requestHandle = null;

                    response(processData(data, query));

                });
            };

            $textfield.on("keyup keypress", function (e) {

                var code = e.keyCode || e.which;

                if (code == 37 || code == 38 || code == 39 || code == 40) { //up right left down
                    return false;
                }

                if (code == 13) { //enter

                    $textfield.trigger("blur");

                    return false;
                }

                jQuery.each(resultfields, function (idx, item) {
                    item.jqobj.val("");
                });

                bestMatch = [];

            });

            $textfield.on('focus click', function () {
                $(this).autocomplete("search", $(this).val());
            });

            var cache = {};

            var anchorTo = null;

            if ($textfield.is('.postcode-input-v2')) {
                anchorTo = '.full-postcode-input'
            } else if ($textfield.is('.postcode-auto')) {
                anchorTo = '#postcode-auto'
            }

            var position = anchorTo
                ? {my: "left top", at: "left bottom+8", of: anchorTo}
                : {my: "left top", at: "left bottom+8"};

            var inst = $textfield.autocomplete({
                position: position,
                delay: 50,
                minLength: $textfield.attr('name').indexOf('postcode') > -1 ? 3 : 2,
                focus: function (e, ui) {
                    return false;
                },
                source: sourcefunc,
                //focus: function(event, ui) {
                //
                //    jQuery.each(resultfields,function(idx,it) {
                //        it.jqobj.val(ui.item[it.attr]);
                //    });
                //
                //},
                select: function (event, ui) {

                    jQuery.each(resultfields, function (idx, it) {
                        it.jqobj.val(ui.item[it.attr]).change();
                    });

                    if ($textfield.attr('id') == 'postcode') {
                        $('#postcode_error').hide();
                        $('#uk-section').removeClass('new-error');
                    } else if ($textfield.attr('id') == 'postcode_top') {
                        $('#postcode_error_top').hide();
                        $('#postcode_top_container').removeClass('new-error');
                    }
                }
            }).data('ui-autocomplete');

            inst._renderMenu = function (ul, items) {
                var that = this;

                if (ul[0] && that.bindings[0]) {
                    ul[0].style.maxWidth = $(that.bindings[0]).outerWidth(true) + 'px';
                }

                $.each(items, function (index, item) {
                    that._renderItemData(ul, item);
                });
                if (alternative_suggestions === true) {
                    $(ul).addClass("suggestion-alternative");
                }
            };

            inst._resizeMenu = function () {

                //overriding this function stops it setting a width which we can do in the old css
                return;
            };

            $textfield.blur(function () {

                if (all_results_filled() === true) {
                    return;
                } else {
                    var bestMatch = [];
                    var query = $textfield.val();

                    sourcefunc({'term': $textfield.val()}, function (res) { /*do nothing*/
                    });
                }
            });

            return inst;
        }

        // Stop page scrolling when scrolling in autocomplete dropdowns
        $(document).on('DOMMouseScroll mousewheel', '.ui-menu', function (ev) {
            var $this = $(this),
                scrollTop = this.scrollTop,
                scrollHeight = this.scrollHeight,
                height = $this.height(),
                delta = (ev.type == 'DOMMouseScroll' ?
                    ev.originalEvent.detail * -40 :
                    ev.originalEvent.wheelDelta),
                up = delta > 0;

            var prevent = function () {
                ev.stopPropagation();
                ev.preventDefault();
                ev.returnValue = false;
                return false;
            };

            if (!up && -delta > scrollHeight - height - scrollTop) {
                // Scrolling down, but this will take us past the bottom.
                $this.scrollTop(scrollHeight);
                return prevent();
            } else if (up && delta > scrollTop) {
                // Scrolling up, but this will take us past the top.
                $this.scrollTop(0);
                return prevent();
            }
        });

        $(document).ready(function () {
            if (window.Bark.USER?.hashed_seller_profile_id && window.io) {
                var phsd = Bark.ENV.phsd || $('meta[name="phsd"]').attr('content');
                var socket = window.io(window.Bark.consts.WS_URL + '/msg', {
                    query: 'phsd=' + phsd,
                    reconnectionAttempts: 3,
                    reconnectionDelay: 6000,
                    transports: ['websocket']
                });

                socket.on('reconnect_attempt', function () {
                    socket.io.opts.transports = ['polling', 'websocket'];
                });

                socket.on('connect', function () {
                    socket.emit('subscribe', 'r_0_b_0_' + window.Bark.USER.hashed_seller_profile_id);
                });
            }

            var tz = jstz.determine();
            Bark.set_cookie('time_zone', tz.name(), 60, '/');

            if ($("#city_name").length) {
                var reqUrl = "/address/";

                if ($("#city_name").data('lmode') === 'sc')
                    reqUrl += "?m=sc";

                var params = [
                    {'jqobj': $('#city_id'), 'attr': 'id'},
                    {'jqobj': $('#city_full'), 'attr': 'label'},
                    {'jqobj': $('#city_type'), 'attr': 'type'}
                ];

                if ($('#city_lat').length > 0) {
                    params.push({'jqobj': $('#city_lat'), 'attr': 'lat'});
                    params.push({'jqobj': $('#city_lng'), 'attr': 'lng'});
                }

                setupAutocomplete(
                    $("#city_name"),
                    reqUrl,
                    params,
                    function (m) {
                        return {
                            label: m.name,
                            match: m.match,
                            id: m.id,
                            type: m.type,
                            lat: m.lat,
                            lng: m.lng
                        };
                    }
                );
            }

            /**
             * Autocomplete on /find
             * For adwords direct linked campaigns
             */
            if ($("#postcode_autocomplete").length) {
                setupAutocomplete(
                    $("#postcode_autocomplete"),
                    "/address/",
                    [{'jqobj': $('#postcode_id'), 'attr': 'id'}, {'jqobj': $('#postcode_type'), 'attr': 'type'}],
                    function (m) {
                        return {
                            label: m.name,
                            match: m.match,
                            id: m.id,
                            type: m.type
                        };
                    }
                );
            }


            if ((typeof Bark.ENV !== "undefined" && Bark.ENV.locale.toLowerCase() != 'ie') && $("#postcode_top").length) {
                setupAutocomplete(
                    $("#postcode_top"),
                    "/address/",
                    [{'jqobj': $('#postcode_id_top'), 'attr': 'id'}, {
                        'jqobj': $('#postcode_type_top'),
                        'attr': 'type'
                    }],
                    function (m) {
                        return {
                            label: m.name,
                            match: m.match,
                            id: m.id,
                            type: m.type
                        };
                    },
                    undefined,
                    true
                );
            } else if (typeof Bark.ENV !== "undefined" && Bark.ENV.locale.toLowerCase() == 'ie' && $("#postcode_top").length) {
                var autocompletetop;

                function initAcmplTop() {
                    var input = document.getElementById('postcode_top');
                    var options = {
                        componentRestrictions: {country: 'ie'}
                    };
                    autocompletetop = new google.maps.places.Autocomplete(input, options);
                    autocompletetop.addListener('place_changed', fillInAddressTop);
                }

                function fillInAddressTop() {
                    var place = autocompletetop.getPlace();
                    $('#postcode_id_top').val(place.place_id);
                    $('#postcode_type_top').val('goog');
                    $('#postcode_id').val(place.place_id);
                    $('#postcode').val(place.formatted_address);
                    $('#postcode_type').val('goog');
                }

                initAcmplTop();
            }

            if ($("#seller-profile-form").length) {
                $('body').on('change', $("#seller-profile-form").find("input:not([type='file']), textarea"), function (e) {
                    sellerProfileChanged = true;
                });
            }

            $('.call-buyer-button').click(function () {
                ga('send', 'event', 'seller-response-actions', 'button-call-telephone', 'Seller calls Buyer from email');
            });

            $('.email-buyer-button').click(function () {
                ga('send', 'event', 'seller-response-actions', 'button-email-buyer', 'Seller clicks Buyer email from dashboard');
            });


            window.onbeforeunload = function (e) {
                if (sellerProfileChanged) {
                    return t_('seller_profile:unsaved-changes-can-be-lost');
                }
            };


            $('form[data-remote]').on('submit', function (e) {
                var form = $(this);

                var csrf_name = $('meta[name="csrf_name"]').attr('content');
                var csrf_value = $('meta[name="csrf_value"]').attr('content');

                e.preventDefault();

                Bark.showLoading();

                $.ajax({
                    type: form.prop('method'),
                    url: form.prop('action'),
                    data: form.serialize() + "&" + csrf_name + "=" + csrf_value,
                    dataType: 'json',
                    success: function (resp, status) {

                        if (resp.status) {
                            window.location.reload(true);
                            return true;
                        }

                        Bark.hideLoading(1000);

                    },
                    error: function (e) {
                        Bark.hideLoading(1000);
                    }
                })

            });

            $(".share-public-profile .facebook").click(function () {
                $(".fb-share-button").trigger("click");
            });

            $(".photo-nav img").click(function () {
                let obj = $(this);
                $('#download-link').attr('href', obj.attr('data-src'));

                $(".photo-gallery img:visible").fadeOut(500, function () {
                    $(".photo-gallery img[data-large-id='" + obj.attr('data-thumbnail-id') + "']").fadeIn(500);
                });

                $(".photo-gallery .download-overlay:visible").fadeOut(500, function () {
                    $(".photo-gallery .download-overlay[data-large-id='" + obj.attr('data-thumbnail-id') + "']").fadeIn(500);
                });

                $(".photo-gallery p:visible").fadeOut(500, function () {
                    $(".photo-gallery p[data-large-id='" + obj.attr('data-thumbnail-id') + "']").fadeIn(500);
                });
            });

            var slidePosition = 0;

            $(".photo-nav .fa-chevron-right").click(function () {

                var source = $(this).parent();
                var imgLength = source.find('img').length - 1;
                slidePosition = source.attr('slide-position') ? source.attr('slide-position') : 0;
                if (!$(this).hasClass("inactive")) {
                    var i;
                    var widths = 0;
                    var img;
                    var margin;
                    slidePosition++;
                    for (i = 0; i < slidePosition; i++) {
                        img = source.find('.thumbnail').eq(i);
                        margin = +img.css('margin-right').replace('px', '');
                        // Use getBoundingClientRect as opposed to .width() so that the precise width is calculated
                        widths += img[0].getBoundingClientRect().width + margin;
                    }

                    // Make the left arrow active
                    if (imgLength >= 1) {
                        source.find('.fa-chevron-left').removeClass("inactive");
                    }
                    source.find(".photo-nav-slider").css({marginLeft: -widths});

                    if (slidePosition === (imgLength - 1)) {
                        $(this).addClass("inactive");
                    }

                    source.attr('slide-position', slidePosition);
                }
            });

            $(".photo-nav .fa-chevron-left").click(function () {
                var source = $(this).parent();
                var imgLength = source.find('img').length - 1;
                slidePosition = source.attr('slide-position') ? source.attr('slide-position') : 0;
                if (!$(this).hasClass("inactive")) {
                    var i;
                    var widths = 0;
                    var img;
                    var margin;
                    slidePosition--;
                    for (i = 0; i < slidePosition; i++) {
                        img = source.find('.thumbnail').eq(i);
                        margin = +img.css('margin-right').replace('px', '');
                        // Use getBoundingClientRect as opposed to .width() so that the precise width is calculated
                        widths += img[0].getBoundingClientRect().width + margin;
                    }

                    source.find(".photo-nav-slider").css({marginLeft: -widths});
                    if (imgLength >= 1) {
                        source.find('.fa-chevron-right').removeClass("inactive");
                    }
                    if (slidePosition === 0) {
                        $(this).addClass("inactive");
                    }

                    source.attr('slide-position', slidePosition);
                }
            });

            $(".star5, .star4, .star3, .star2, .star1").hover(function () {
                $(".star5, .star4, .star3, .star2, .star1").removeClass("no-star");
                if ($(this).hasClass("star1")) {
                    $(".star5, .star4, .star3, .star2").addClass("no-star");
                }
                if ($(this).hasClass("star2")) {
                    $(".star5, .star4, .star3").addClass("no-star");
                }
                if ($(this).hasClass("star3")) {
                    $(".star5, .star4").addClass("no-star");
                }
                if ($(this).hasClass("star4")) {
                    $(".star5").addClass("no-star");
                }
            }, function () {
                $(".star5, .star4, .star3, .star2, .star1").removeClass("no-star");
                if ($("input[name='selected-rating']").val() == 0) {
                    $(".star5, .star4, .star3, .star2, .star1").addClass("no-star");
                }
                if ($("input[name='selected-rating']").val() == 1) {
                    $(".star5, .star4, .star3, .star2").addClass("no-star");
                }
                if ($("input[name='selected-rating']").val() == 2) {
                    $(".star5, .star4, .star3").addClass("no-star");
                }
                if ($("input[name='selected-rating']").val() == 3) {
                    $(".star5, .star4").addClass("no-star");
                }
                if ($("input[name='selected-rating']").val() == 4) {
                    $(".star5").addClass("no-star");
                }
            });

            $(".star5, .star4, .star3, .star2, .star1").click(function () {
                $("#modal_rating_error").hide();
                if ($(this).hasClass("star1")) {
                    $("input[name='selected-rating']").val(1);
                }
                if ($(this).hasClass("star2")) {
                    $("input[name='selected-rating']").val(2);
                }
                if ($(this).hasClass("star3")) {
                    $("input[name='selected-rating']").val(3);
                }
                if ($(this).hasClass("star4")) {
                    $("input[name='selected-rating']").val(4);
                }
                if ($(this).hasClass("star5")) {
                    $("input[name='selected-rating']").val(5);
                }
                $(".star5, .star4, .star3, .star2, .star1").removeClass("no-star");
                if ($("input[name='selected-rating']").val() == 0) {
                    $(".star5, .star4, .star3, .star2, .star1").addClass("no-star");
                }
                if ($("input[name='selected-rating']").val() == 1) {
                    $(".star5, .star4, .star3, .star2").addClass("no-star");
                }
                if ($("input[name='selected-rating']").val() == 2) {
                    $(".star5, .star4, .star3").addClass("no-star");
                }
                if ($("input[name='selected-rating']").val() == 3) {
                    $(".star5, .star4").addClass("no-star");
                }
                if ($("input[name='selected-rating']").val() == 4) {
                    $(".star5").addClass("no-star");
                }
            });

            $("textarea[name='modal_textarea']").keyup(function () {
                if ($(this).val().length >= 30) {
                    $("#modal_text_error").hide();
                    $("#modal_text_error").parent().removeClass('new-error');
                }
            });

            $("input[name='modal_name']").keyup(function () {
                $("#modal_name_error").hide();
                $("#modal_name_error").parent().removeClass('new-error');
            });

            $("input[name='modal_email']").keyup(function () {
                $("#modal_email_error").hide();
                $("#modal_email_error").parent().removeClass('new-error');
            });

            $("input[name='modal_used']").click(function () {
                $("#modal_used_error").hide();
                $("#modal_used_error").parent().removeClass('new-error');
            });

            $("#review-modal-close").click(function () {
                $('#writeReviewModal').foundation('reveal', 'close');
                window.setTimeout(function () {
                    $('#review-form').show();
                    $('#review-modal-success').hide();

                    $("input[name='modal_name']").val('');
                    $("input[name='modal_email']").val('');
                    $("input[name='modal_text']").val('');
                    $("input[name='modal_rating']").val(0);
                    $("input[name='modal_used']:checked").attr("checked", "");
                }, 1000);
            });

            if ($("body").hasClass("mobile") && $(window).width() > 640) {
                $(".mobile #buyer-options-dropdown").hide();
                $(".mobile #seller-options-dropdown").hide();
                $(".mobile #buyer-messenger-options-dropdown").hide();

            }

            if ($("body").hasClass("mobile") && $(window).width() > 640) {

                $(".mobile #buyer-options").on('touchstart', function (e) {
                    $(".mobile #buyer-options-dropdown").toggle();
                    e.preventDefault();
                });

                $(".mobile #seller-options").on('touchstart', function (e) {
                    $(".mobile #seller-options-dropdown").toggle();
                    e.preventDefault();
                });

                $(".mobile #buyer-messenger-options").on('touchstart', function (e) {
                    $(".mobile #buyer-messenger-options-dropdown").toggle();
                    e.preventDefault();
                });

            }

            var postingReview = false;

            $(".post-review").click(function () {
                if (postingReview) {
                    return;
                }
                postingReview = true;
                if ($("input:radio[name='modal_used']:checked") == undefined) {
                    used = '';
                } else {
                    used = $("input:radio[name='modal_used']:checked").val();
                }

                var postData = {
                    sid: $("input[name='modal_sid']").val(),
                    bid: $("input[name='modal_bid']").val(),
                    rating: $("input[name='selected-rating']").val(),
                    text: $("textarea[name='modal_textarea']").val(),
                    modal_name: $("input[name='modal_name']").val(),
                    modal_email: $("input[name='modal_email']").val(),
                    modal_used: used,
                    source: $('#writeReviewModal').data('source'),
                };
                Bark.showLoading();
                $.post("/post_review_ajax/", postData, function (data) {
                    Bark.hideLoading();
                    if (data.success) {

                        try {
                            if (typeof socket !== 'undefined') {
                                socket.emit('newReview', {
                                    note: postData.text,
                                    room: 'r_0_b_0_' + window.Bark.USER?.hashed_seller_profile_id,
                                    is_buyer: 0,
                                    id: data.reviewId,
                                    project_url: '/sellers/reviews/'
                                });
                            }
                        } catch (e) {
                        }

                        if (typeof window.posting_review_complete_callback === 'function') {
                            window.posting_review_complete_callback()
                        } else {
                            $("#review-form").fadeOut(function () {
                                $("#review-modal-success").fadeIn();
                            });
                        }
                    } else {
                        postingReview = false;
                        if (typeof data.errors.email != "undefined") {
                            error = true;
                            $("#modal_email_error").text(data.errors.email).show().css("display", "block");
                            $("#modal_email_error").parent().addClass('new-error');
                        }
                        if (typeof data.errors.name != "undefined") {
                            error = true;
                            $("#modal_name_error").text(data.errors.name).show().css("display", "block");
                            $("#modal_name_error").parent().addClass('new-error');
                        }
                        if (typeof data.errors.text != "undefined") {
                            error = true;
                            $("#modal_text_error").text(data.errors.text).show().css("display", "block");
                            $("#modal_text_error").parent().addClass('new-error');
                        }
                        if (typeof data.errors.rating != "undefined") {
                            error = true;
                            $("#modal_rating_error").text(data.errors.rating).show().css("display", "block");
                            $("#modal_rating_error").parent().addClass('new-error');
                        }
                        if (typeof data.errors.used != "undefined") {
                            error = true;
                            $("#modal_used_error").text(data.errors.used).show().css("display", "block");
                            $("#modal_used_error").parent().addClass('new-error');
                        }
                    }
                }, 'json').fail(function () {
                    Bark.hideLoading();
                    postingReview = false;
                });
            });

            $("#password-modal-close").click(function () {
                $('#passwordModal').foundation('reveal', 'close');
                window.setTimeout(function () {
                    $('#password-form').show();
                    $('#password-modal-success').hide();
                    $("#current").val('');
                    $("#password").val('');
                    $("#confirm").val('');
                    $('#passwordButtons').removeClass('hide');
                }, 1000);
            });
            $("#current, #password, #confirm").keyup(function () {
                $("#password-modal-error").hide();
            });

            $("#user-change-password-action").click(function () {
                var data = {
                    current: $("#current").val(),
                    password: $("#password").val(),
                    confirm: $("#confirm").val()
                };

                var csrf = $('#passwordCSRF').attr('name');
                data[csrf] = $('#passwordCSRF').val();

                $.post("/user/change_password_ajax/", data, function (data, status) {
                    var error = false;

                    if (data.errors.password.length > 0) {
                        error = true;
                        $("#password-modal-error").text("*" + data.errors.password).show();
                        $("#password-modal-error").parent().addClass('new-error');
                    } else if (data.success == true) {
                        $("#password-form").fadeOut(function () {
                            $("#password-modal-success").fadeIn();
                            $('#passwordButtons').addClass('hide');
                        });
                    }
                }, 'json');

            });

            $("#buyer-change-password-action").click(function () {
                var data = {
                    current: $("#current").val(),
                    password: $("#password").val(),
                    confirm: $("#confirm").val()
                };

                var csrf = $('#passwordCSRF').attr('name');
                data[csrf] = $('#passwordCSRF').val();

                $.post("/buyers/change_password_ajax/", data, function (data, status) {
                    var error = false;

                    if (data.errors.password.length > 0) {
                        error = true;
                        $("#password-modal-error").text("*" + data.errors.password).show();
                        $("#password-modal-error").parent().addClass('new-error');
                    } else if (data.success == true) {
                        $("#password-form").fadeOut(function () {
                            $("#password-modal-success").fadeIn();
                            $('#passwordButtons').addClass('hide');
                        });
                    }
                }, 'json');

            });

            $('.review-respond-popup').click(function (event) {

                event.preventDefault();

                $("#review-respond-modal-error").text('');
                $("#review-respond-modal-error").parent().parent().removeClass('new-error');

                var review_id = $(event.target).attr('data-review-id');
                $('#review-respond-id').val(review_id);

                if ($('#review-response-' + review_id).length > 0) {
                    $('#review-respond-content').val($('#review-response-' + review_id).text());
                } else {
                    $('#review-respond-content').val('');
                }

                $("#review-respond-form").show();
                $("#review-respond-success").hide();

                $('#review-respond').foundation('reveal', 'open');

            });

            $('.review-report-popup').click(function (event) {

                event.preventDefault();
                $("#review-report-modal-error").text('');
                $("#review-report-modal-error").parent().parent().removeClass('new-error');
                $("input[type='radio'][name='review-report-options']").removeAttr('checked');
                $('#review-report-content').val('');
                $("#review-report-form").show();
                $("#review-report-success").hide();

                var review_id = $(event.target).attr('data-review-id');
                $('#review-report-id').val(review_id);
                $('#review-report').foundation('reveal', 'open');

            });

            $(window).resize(function () {
                fixedHeaderCheck();
            });
            $(window).scroll(function () {
                fixedHeaderCheck();
            });
            fixedHeaderCheck();

            var interval_slides = setInterval(changeSlide, 6000);

            //TODO Check with Chris about this

            $(window).on("blur focus", function (e) {
                var prevType = $(this).data("prevType");

                if (prevType != e.type) {
                    switch (e.type) {
                        case "blur":
                            clearInterval(interval_slides);
                            interval_slides = null;
                            break;
                        case "focus":
                            if (!interval_slides) {
                                interval_slides = setInterval(changeSlide, 6000);
                            }
                            break;
                    }
                }

                $(this).data("prevType", e.type);
            });

            //$(document).foundation();

            $(".country-dropdown li:not(.dropdown-hidden)").click(function () {
                if ($(this).parent().find(".dropdown-hidden").is(":hidden")) {
                    $(this).parent().find(".dropdown-hidden").show();
                } else {
                    $(this).parent().find(".dropdown-hidden").hide();
                }
            });

            //Seller Dashboard profile completeness

            $(function () {
                var $ppc = $('.progress-pie-chart'),
                    percent = parseInt($ppc.data('percent')),
                    deg = 360 * percent / 100;
                if (percent > 50) {
                    $ppc.addClass('gt-50');
                }

                if (percent < 25) {
                    $ppc.addClass('lt-25');
                } else if (percent < 50) {
                    $ppc.addClass('lt-50');
                } else if (percent < 75) {
                    $ppc.addClass('lt-75');
                } else {
                    $ppc.addClass('lt-100');
                }

                $('.ppc-progress-fill').css('transform', 'rotate(' + deg + 'deg)');
                $('.ppc-percents span').html(percent + '%');
            });

            //checkNotifications();


            $('#seller_name').keyup(function () {
                $('#seller_name-error').hide();
                $(this).parent().find("span.new-error").hide();
                $("#seller_name-error-c").removeClass("new-error");
            });

            $('#company').keyup(function () {
                $(this).parent().find("span.new-error").hide();
                $("#company-error-c").removeClass("new-error");

            });
            $('#telephone').keyup(function () {

                $(this).parent().find("span.new-error").hide();
                $("#telephone-error-c").removeClass("new-error");

            });

            $('#quote').keyup(function () {
                $("#quote-error").parent().find("span.new-error").hide();
                $("#quote-error-c").removeClass("new-error");

            });

            $('#quote_type').change(function () {
                $("#quote-error").parent().find("span.new-error").hide();
                $("#quote-error-c").removeClass("new-error");

                $(this).removeClass('new-error');

            });

            $('.purchase-pack').click(function (e) {
                var pack_id = $(this).attr('data-pack-id');
                $('#payment_type').val('pack_' + pack_id);
                $('#credit-processing-modal-errors').html('');
                $('#credit-processing-modal-change-card').hide();

                $('#credit-processing-modal .controls').hide();

            });

            $('#quote_later').click(function () {
                $("#quote_type").removeClass('new-error');

                if ($(this).is(":checked")) {
                    $("#quote").prop('disabled', true);
                    $("#quote_type").prop('disabled', true);
                    $("#quote_details").prop('disabled', true);
                } else {
                    $("#quote").prop('disabled', false);
                    $("#quote_type").prop('disabled', false);
                    $("#quote_details").prop('disabled', false);
                }
                $("#quote-error").parent().find("span.new-error").hide();
            });

            $(".section-hidden").hide();

            $('#projectNotInterested').click(function () {
                $('#projectReplyOptions').hide();
                $('.project-view-bottom').hide();
                $('#mobileProjectReplyOptions').fadeOut();
                $('#notInterestedReason').fadeIn();
                $('.project-view-bottom').fadeIn();
            });


            // End of project_view.php intested/not-interested appear/dissappear

            // Start of project_view.php MOBILE intested/not-interested appear/dissappear
            $(".project-reply-primary").not('.xp').click(function () {
                $('#projectReplyOptions').hide();
                $('.project-view-bottom').hide();
                $('#mobileProjectReplyOptions').fadeOut();
                $('.project-view-response').show();
                $('html, body').animate({
                    scrollTop: $(".project-view-response").offset().top - 41
                }, 2000);
            });

            $(".project-reply-primary.xp").click(function () {
                $('#projectReplyOptions button').hide();
                $('.project-view-bottom').hide();
                $('#mobileProjectReplyOptions').fadeOut();
                $('.project-view-response').show();
                $('html, body').animate({
                    scrollTop: $(".project-view-header").offset().top - 83
                }, 2000);
            });

            $('a.we-re-hiring').on('click', function () {
                ga('send', 'event', 'link', 'click', 'We are hiring - header');
            });

            $('a.we-re-hiring-footer').on('click', function () {
                ga('send', 'event', 'link', 'click', 'We are hiring - footer');
            });

            if ($(".project-reply-primary.xp").length) {
                $(window).off('scroll.respondproximity').on('scroll.respondproximity', function () {
                    var scrollpos = $(this).scrollTop();
                    var elemtop = $('.project-view-large-right .project-view-new').offset().top;
                    var offset = 150;
                    if (scrollpos > elemtop - offset) {
                        $('.project-view-bottom').hide();
                        $('#mobileProjectReplyOptions').fadeOut();
                        $('.project-view-response').show();
                        $(window).off('scroll.respondproximity')
                    }
                });
            }

            $(".project-reply-secondary").click(function () {
                $('#projectReplyOptions').hide();
                $('.project-view-bottom').hide();
                $('#mobileProjectReplyOptions').fadeOut();
                $('#notInterestedReason').show();
                $('.project-view-bottom').show();
                $('html, body').animate({
                    scrollTop: $("#notInterestedReason").offset().top - 41
                }, 2000);
            });
            // End of project_view.php MOBILE intested/not-interested appear/dissappear

            // Start of Welcome to Bark buyer modal

            $('.js-bark-confirmation-close-1').click(function () {
                $(this).closest('.js-bark-confirmation-modal').find('#postBarkWelcome1').hide();
            });
            $('.js-bark-confirmation-close-2').click(function () {
                $(this).closest('.js-bark-confirmation-modal').find('#postBarkWelcome2').hide();
            });
            $('.js-bark-confirmation-close-3').click(function () {
                $(this).closest('.js-bark-confirmation-modal').find('#postBarkBnb').hide();
            });

            $('.js-bark-confirmation-open-1').click(function () {
                $(this).closest('.js-bark-confirmation-modal').find('#postBarkWelcome1').fadeIn();
            });
            $('.js-bark-confirmation-open-2').click(function () {
                $(this).closest('.js-bark-confirmation-modal').find('#postBarkWelcome2').fadeIn();
            })
            $('.js-bark-confirmation-open-3').click(function () {
                $(this).closest('.js-bark-confirmation-modal').find('#postBarkBnb').fadeIn();
            });

            // // End of Welcome to Bark buyer modal

            $("#company, #profile_text").keyup(function () {
                $(this).parent().find("small").hide();
            });
            $("#category_id").change(function () {
                $("#category_id").parent().find("small").hide();
            });
            $("#city_id").change(function () {
                $("#city_id").parent().find("small").hide();
            });
            $("#seller_continue").click(function (e) {
                var error = false;
                //Validate
                if ($("#company").val().length == 0) {
                    error = true;
                    $("#company").parent().find("small").show();
                }
                if ($("#category_id").val().length == 0 || $("#category_name").val().length == 0) {
                    error = true;
                    $("#category_id").parent().find("small").show();
                }
                if ($("#city_id").val().length == 0 || $("#city_name").val().length == 0) {
                    error = true;
                    $("#city_id").parent().find("small").show();
                }
                if ($("#profile_text").val().length < 50) {
                    error = true;
                    $("#profile_text").parent().find("small").show();
                }
                //Change Modal
                if (!error) {
                    $('#signup-modal-2').foundation('reveal', 'open');
                }
            });

            $("#email2").keyup(function () {
                $("#email2").parent().find("small").hide();
            });
            $("#telephone").keyup(function () {
                $("#telephone").parent().find("small").hide();
            });
            $("#password2, #confirm").keyup(function () {
                $("#password2").parent().find("small").hide();
                $("#confirm").parent().find("small").hide();
            });

        });

        let validateCompany = window.validateCompany = (company) => {
            return company.length > 0 && /[a-zA-Z0-9]/g.test(company);
        }

        let validateEmail = window.validateCompany = (email) => {
            //Simple javascript regex, needs server side validation also
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        }

        function fixedHeaderCheck() {
            var winwidth;
            var scrolltop;
            var topthreshold;
            var body = $('body');
            var critical;
            if (!body.hasClass('not-home') || (body.hasClass('directory') && !body.hasClass('pros'))) {
                winwidth = $(window).width();
                scrolltop = $(window).scrollTop();
                topthreshold = winwidth > Bark.consts.MOBILE_WIDTH_THRESHOLD ? 400 : 200;
                critical = 720;

                try {
                    if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) {
                        critical += 170;
                    } else if (navigator.userAgent.search("Firefox") >= 0) {
                        critical += 150;
                    }
                } catch (e) {
                    console.error(e);
                }

                if (winwidth > critical && scrolltop > topthreshold) {
                    // If on the home page and the screen is wide enough and we're scrolled down low enough, show the place bark tab
                    $('.distance1').hide();
                    $('.distance2').fadeIn();
                } else {
                    $('.distance1').fadeIn();
                    $('.distance2').hide();
                }

                // Legacy:
                if (winwidth > Bark.consts.MOBILE_WIDTH_THRESHOLD) {
                    if (scrolltop > 400) {
                        if (body.hasClass('home')) {
                            $(".contain-to-grid").removeClass('transparent');
                            $(".contain-to-grid").addClass('not-transparent');
                            $(".header-background").fadeIn();
                        }
                    } else {
                        $('.bark-login-container').fadeIn(150);
                        if (body.hasClass('home')) {
                            $(".contain-to-grid").addClass('transparent');
                            $(".contain-to-grid").removeClass('not-transparent');
                            $(".header-background").fadeOut();
                        }
                    }
                } else {
                    if (scrolltop > 200) {
                        if (body.hasClass('home')) {
                            $(".contain-to-grid").removeClass('transparent');
                            $(".contain-to-grid").addClass('not-transparent');
                            $(".header-background").fadeIn();
                        }
                    } else {
                        if (body.hasClass('home')) {
                            $(".contain-to-grid").addClass('transparent');
                            $(".contain-to-grid").removeClass('not-transparent');
                            $(".header-background").fadeOut();
                        }
                    }
                }
            } else {
                $(".top-bar").show();
            }
        }

        function changeSlide() {
            // This is a strage piece of code to have running by default, we should explicitly call this method if we want it,
            // but at the moment the listener is on focus. This stops it running on the new homepage.
            if ($('#testimonials').length > 0) {
                return;
            }
            //Find highest z-index, fadeoutand hide, put at back
            var topIndex = -100;
            var bottomIndex = 0;
            $(".slide").each(function () {
                if (parseInt($(this).css("z-index")) > topIndex) {
                    topIndex = parseInt($(this).css("z-index"));
                }

                if (parseInt($(this).css("z-index")) < bottomIndex) {
                    bottomIndex = parseInt($(this).css("z-index"));
                }
            });
            $(".slide").each(function () {
                $(this).find("h5, img, p").fadeOut();
                if (parseInt($(this).css("z-index")) == topIndex) {
                    $(this).fadeOut(1500, function () {
                        $(".slide").each(function () {
                            $(this).find("h5, img, p").fadeIn();
                            if (parseInt($(this).css("z-index")) == topIndex) {
                                $(this).css("z-index", bottomIndex);
                                $(this).show();
                            } else {
                                $(this).css("z-index", (parseInt($(this).css("z-index")) + 1));

                            }
                        });
                    });

                }
            });

        }

        function get_gallery_dim() {
            var images_width = 0;
            var images_set = $(".photo-nav img").map(function (i, img) {
                var width = $(img).width();
                images_width += width;
                return width;
            });

            return {width: images_width, length: images_set.length};
        }

        let cleanse = window.cleanse = (string, length) => {
            length = length || 0;
            if (string && string.length > length && length != 0) {
                return $('<div/>').text(string.substring(0, length)).html() + '&hellip;';
            } else {
                return $('<div/>').text(string).html();
            }
        }

        $(function () {

            $(".new-project-checkbox>input[type='checkbox']").change(function () {
                if ($(this).is(":checked")) {
                    $(this).parent().addClass('selected');
                } else {
                    $(this).parent().removeClass('selected');
                }
            });

            if ($.fn.imagesLoaded) {
                // The images loaded plugin is available to us so this must be a page with aphoto gallery
                $('.photo-nav').imagesLoaded(function () {
                    if (get_gallery_dim().width > $('div.photo-nav-inner').width()) {
                        $(".photo-nav .fa-chevron-right").removeClass("inactive");
                    }
                });
            }

            $('#buyerChangePasswordButton').click(function () {
                $('#buyerChangePassword').show('slow');
                $(this).addClass('disabled-button');
            });

            $('#sellerChangePasswordButton').click(function () {
                $('#sellerChangePassword').show('slow');
                $(this).addClass('disabled-button');
            });

            $('#view-bark-details').on('mouseover', function () {
                if (get_gallery_dim().width > $('div.photo-nav-inner').width()) {
                    $(".photo-nav .fa-chevron-right").removeClass("inactive");
                }

                $('#view-bark-details').unbind();
            });

        });

        let nl2br = window.nl2br = (str, is_xhtml) => {
            var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
        }

        let getCookie = window.getCookie = (cname) => {
            var name = cname + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1);
                if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
            }
            return "";
        }

        /*  form validation  */

        function Validation() {
            this.validation_errors = 0;
        }

        Validation.prototype.step = function ($jqobj, error_message, valid_func, $jqapply) {

            if ($jqapply == undefined) {
                $jqapply = $jqobj;
            }

            if (valid_func($jqobj)) {
                $jqapply.removeClass("new-error");
                $jqapply.next("span.new-error").detach();
            } else {
                $jqapply.addClass("new-error");
                $jqapply.next("span.new-error").detach();
                $jqapply.after('<span class="new-error"> ' + error_message + ' </span>');
                this.validation_errors++;
            }
        }

        Validation.prototype.failed = function () {
            return (this.validation_errors > 0);
        }

        function val_not_empty($item) {
            return ($item.val().trim().length > 0)
        }

        /* end form validation */


        $(document).ready(function () {

            $('.certificate-email-button').click(function (e) {
                $('#snippetEmailAddress').val('');
            });

            $('body').on('click', '.showMoreAlternatives', function (e) {
                currentAlternativePage += 1;
                $('.suggestionPage' + currentAlternativePage).removeClass('hiddenSuggestion').addClass('visibleSuggestion');
                if (currentAlternativePage == alternativePages)
                    $('.showMoreAlternatives').hide();
            });

            $('#suggestCategoryButton').on('click', function (e) {
                $('#suggestCategoryError').parent().removeClass('new-error');
                $('#suggestCategoryError').hide();

                if ($("input[name='suggest_category_name']").val().trim().length === 0) {
                    $('#suggestCategoryError').html(t_('seller_profile:suggest-category-error'));
                    $('#suggestCategoryError').parent().addClass('new-error');
                    $('#suggestCategoryError').show();
                    return false;
                } else if ($("input[name='suggest_category_name']").val().trim().length < 3) {
                    $('#suggestCategoryError').html(t_('seller_profile:suggest-category-error-length'));
                    $('#suggestCategoryError').parent().addClass('new-error');
                    $('#suggestCategoryError').show();
                    return false;
                } else {
                    return true;
                }
            });

            $('body').on('click', '.not-interested-button', function (e) {

                e.preventDefault();
                var recordType = false;
                if ($(this).attr('data-type') == 'prospect') {
                    recordType = 'prospect';
                } else if ($(this).attr('data-type') == 'seller') {
                    recordType = 'seller';
                } else {
                    return false;
                }

                var recordId = $(this).attr('data-id');
                var projectId = $(this).attr('data-project-id');
                var declineType = 99;
                if (document.querySelector('input[name="not-interested"]:checked') !== null) {
                    declineType = document.querySelector('input[name="not-interested"]:checked').value;
                }

                var data = {
                    "recordType": recordType,
                    "recordId": recordId,
                    "projectId": projectId,
                    "declineType": declineType
                };

                $.ajax({
                    type: "POST",
                    url: "/response_declined/",
                    data: data,
                    success: function (data) {
                        $('#notInterestedReason').hide();
                        $('.project-view-bottom').hide();
                        $('#notInterestedConfirmation').fadeIn();
                    }
                });

            });

            // Project undo not interested action
            $('body').on('click', '.undo-not-interested-button', function (e) {

                e.preventDefault();
                var recordType = false;
                if ($(this).attr('data-type') == 'prospect') {
                    recordType = 'prospect';
                } else if ($(this).attr('data-type') == 'seller') {
                    recordType = 'seller';
                } else {
                    return false;
                }

                var recordId = $(this).attr('data-id');
                var projectId = $(this).attr('data-project-id');
                var declineType = 99;
                if (document.querySelector('input[name="not-interested"]:checked') !== null) {
                    declineType = document.querySelector('input[name="not-interested"]:checked').value;
                }

                var data = {
                    "recordType": recordType,
                    "recordId": recordId,
                    "projectId": projectId,
                    "declineType": declineType
                };

                $.ajax({
                    type: "POST",
                    url: "/response_declined_delete/",
                    data: data,
                    success: function (data) {
                        $('#notInterestedConfirmation').hide();
                        $('#projectReplyOptions').fadeIn();
                        $('.project-view-bottom').fadeIn();
                    }
                });
            });

            window.triggerUpload = function (id) {
                var elem = document.getElementById(id);
                try {
                    var event = new MouseEvent('click');
                    elem.dispatchEvent(event);
                } catch (e) {
                    elem.click();
                }
            };

        });

        let getUrlParameter = window.getUrlParameter = (sParam) => {
            var sPageURL = decodeURIComponent(window.location.search.substring(1)),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');

                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : sParameterName[1];
                }
            }
        };

        function linkedin_track_pack_purchase(sellerOriginCampaign) {
            window.bugsnagClient && window.bugsnagClient.notify(
                new Error('DeprecatedFunction: linkedin_track_pack_purchase'),
                {metaData: {campaign: sellerOriginCampaign}}
            );
        }

        let format_currency = window.format_currency = (number) => {
            return Number(number).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        let isEmpty = window.isEmpty = (val) => {
            return (val === undefined || val == null || val.length <= 0) ? true : false;
        }

        $(function () {

            $('input[name=switch-profile-toggle]:radio').change(function () {
                var value = $(this).val();

                value = (value === 'buyers') ? 'sellers' : 'buyers';

                $('body').append('<div id="switchUserProfileOverlay" class="full-screen-load" style="display:none;">' +
                    '<div class="full-screen-load-container">' +
                    '<div class="full-screen-load-content">' +
                    '<i class="fa fa-spinner fa-spin"></i>' +
                    '<span>Switching profile</span>' +
                    '</div>' +
                    '</div>' +
                    '</div>');

                $('#switchUserProfileOverlay').fadeIn({
                    duration: 250, complete: function () {
                        window.location.replace('/' + value + '/switch/');
                    }
                });
            });

        });

        /* section for adding browser support detection */
        if (typeof document.body.style.grid !== 'string') {
            document.body.classList.add('no-support-display-grid')
        }

        window.logExperimentSessionAction = ({
                                                 action: act,
                                                 actionValue: actv,
                                                 experimentId: xpid,
                                                 projectId: pid,
                                                 hashedProjectId: hpid
                                             }) => {

            const pg = window.location.pathname;
            if (!xpid) {
                return;
            }

            Bark.json({
                url: '/api/exp-act/',
                data: {xpid, act, actv, pid, pg, hpid}
            });
        };

        if (window.Handlebars) {
            window.Handlebars.registerHelper('pluralize', function (number, singular, plural) {
                if (number === 1)
                    return singular;
                else
                    return (typeof plural === 'string' ? plural : singular + 's');
            });

            window.Handlebars.registerHelper('not', function (value) {
                return !value;
            });

            window.Handlebars.registerHelper('is_not_null', function (value) {
                return value !== null;
            });

            window.Handlebars.registerHelper('toLowerCase', function (value) {
                return value.toLowerCase();
            });

            window.Handlebars.registerHelper('isOne', function (value) {
                return value === 1;
            });
        }
    });
}


