import { validateEmail } from "../components/magic-link/helpers/form";
import { sendMagicLink } from "../components/magic-link/helpers/api";
import { showAlertEmailError, showAlertMagicLinkError, clearAllAlerts, getTranslatedAlertTextFromErrorCode } from "../components/magic-link/helpers/alert";
import { setEmailSessionStorage } from "../components/magic-link/helpers/session-storage";
import { showLoading, hideLoading } from "../components/magic-link/helpers/loading";
import {_l} from "@bark/bark-core"

export const LoginSendLinkPage = async () => {

  //listeners
  document.querySelector('#send-link').addEventListener('submit', async (e) => {
    e.preventDefault();
    const emailElement = document.querySelector('#email');
    const email = emailElement.value;
    const validate = validateEmail(email);
    clearAllAlerts();

    if(validate){
      showLoading();
      const magicLinkResponse = await sendMagicLink(email);
      if(magicLinkResponse?.status){
        setEmailSessionStorage(email);
        window.location.href = _l('/login/link-sent/');
      } else {
        hideLoading();
        let errorMessage = ''
        if(magicLinkResponse?.error_code) {
            errorMessage = await getTranslatedAlertTextFromErrorCode(magicLinkResponse?.error_code)
        } else {
            errorMessage = magicLinkResponse?.error
        }
        showAlertMagicLinkError(errorMessage);
      }
    } else {
      showAlertEmailError();
    }
  });

}
