import { cleanEmpty } from "../../helpers";
import {_t, useNamespace} from "../../libs/i18next";

export default async function AdditionalDetails(options = {}) {

    await useNamespace(['buyer_create-bark_additional-details']);

    /**
     * Close the additional details modal
     */
    const closeModal = () => {
        additionalDetailsModal.modal('hide');
    };

    /**
     * Options
     */
    let opts = Object.assign({
        allowFiles: true,
        placeholderText: _t("buyer_create-bark_additional-details:placeholder-text"),
        project: null,
        projectId: null,
        modalSelector: '#additional-details-modal-with-progress',
        qualityScoreTitle: _t("buyer_create-bark_additional-details:quality-score-title"),
        submitLabel: _t("buyer_create-bark_additional-details:update-request"),
        skipLabel: _t("buyer_create-bark_additional-details:skip-label"),
        showProgress: true,
        showProgressSmile: false,
        textAreaSelector: '#project-detail-text',
        textHeader: _t("buyer_create-bark_additional-details:get-faster-text-header", {
            line_break: '<br/>',
            interpolation: {escapeValue: false}
        }),
        textHeadingSuccessText: _t("buyer_create-bark_additional-details:text-heading-success-text"),
        textSubheading: _t("buyer_create-bark_additional-details:add-more-details-subheading"),
        onInit: () => {},
        onBeforeSkip: () => {},
        onContinueAfterSubmitted: closeModal,
        onSkip: closeModal,
        onSubmit: (hasAdditionalDetails, payload) => {},
        onSubmitFail: closeModal,
        onSubmitSuccess: closeModal,
        onValidate: (hasAdditionalDetails, payload) => {},
    }, cleanEmpty(options));

    const { bugsnagClient, Bark: { api, initModalUpload } } = window;

    /**
     * @type {jQuery}
     */
    const additionalDetailsModal = $(opts.modalSelector);

    /**
     * Flag to determine whether the additional details are currently being submitted
     * @type {boolean}
     */
    let detailsSubmitting = false;

    /**
     * Get uploaded file/image ids
     * @param {string} type One of image, file
     * @returns {[]}
     */
    const getAssetIds = type => {
        if (!type || !['file', 'image'].includes(type.toLowerCase())) {
            bugsnagClient && bugsnagClient.notify(new Error(`Unknown asset type '${type}'`));
            return [];
        }

        return $(`input[id^="${type}_id_"]`, additionalDetailsModal).map((_, target) => {
            return $(target).val()
        }).toArray();
    };

    /**
     * Submit additional details
     */
    const submitAdditionalDetails = () => {
        const projectDetail = $(opts.textAreaSelector, additionalDetailsModal).val().trim();
        const imageIds = getAssetIds('image');
        const fileIds = getAssetIds('file');
        const payload = {};
        const url = `buyer/bark/${opts.projectId}/details`;
        let hasAdditionalDetails = false;

        if (detailsSubmitting) {
            return;
        }

        if (projectDetail.length > 0) {
            payload.project_detail = projectDetail;
            hasAdditionalDetails = true;
        }

        if (imageIds.length > 0) {
            payload.image_ids = imageIds;
            hasAdditionalDetails = true;
        }

        if (fileIds.length > 0) {
            payload.file_ids = fileIds;
            hasAdditionalDetails = true;
        }

        if (opts.onValidate(hasAdditionalDetails, payload) === false || opts.onSubmit(hasAdditionalDetails, payload) === false) {
            return;
        }

        $('#project-detail-error', additionalDetailsModal).addClass('d-none');
        $(opts.textAreaSelector, additionalDetailsModal).removeClass('has-error');

        detailsSubmitting = true;

        Bark.api(
            url,
            payload,
            () => {
                detailsSubmitting = false;
                opts.onSubmitSuccess && opts.onSubmitSuccess();

                $('.js-additional-details-before-submitted-container').addClass('d-none');
                $('.js-additional-details-success-container').removeClass();
                $('.js-do-submit-additional-details').addClass('d-none');
                $('.js-continue-after-additional-details-success').removeClass('d-none');
            },
            () => {
                detailsSubmitting = false;
                opts.onSubmitFail && opts.onSubmitFail();
            },
            'POST',
            Bark.apiVersionHeader('v2')
        );
    };

    /**
     * Perform an action when the user has skipped additional details
     */
    const skipAdditionalDetails = () => {
        const { api, apiVersionHeader } = Bark;

        api(`buyer/bark/${opts.projectId}/details/skip`, null, null, null, 'post', apiVersionHeader('v2')).always(() => {
            opts.onSkip && opts.onSkip();
        });
    };

    /**
     * Bind events in the modal
     */
    const bindEvents = () => {
        const dropZoneContainer = $('#modalUploadFiles');

        // Set up click handlers for the submit and close buttons
        $('.js-do-submit-additional-details', additionalDetailsModal).off('click.submit').on('click.submit', () => {
            submitAdditionalDetails()
        });

        $('.close,.js-skip-additional-details', additionalDetailsModal).off('click.skip').on('click.skip', e => {
            if (opts.onBeforeSkip && opts.onBeforeSkip() === false) {
                e.preventDefault();
                return false;
            }

            skipAdditionalDetails();
        });

        $('.js-show-project-details', additionalDetailsModal).off('click.show-details').on('click.show-details', () => {
            $('.js-show-project-details', additionalDetailsModal).addClass('d-none');
            $('.js-hide-project-details', additionalDetailsModal).removeClass('d-none');
            $('.project-detail').removeClass('d-none').slideDown();
        });

        $('.js-hide-project-details', additionalDetailsModal).off('click.hide-details').on('click.hide-details', () => {
            $('.js-hide-project-details', additionalDetailsModal).addClass('d-none');
            $('.js-show-project-details', additionalDetailsModal).removeClass('d-none');
            $('.project-detail', additionalDetailsModal).slideUp();
        });

        $('.js-continue-after-additional-details-success').off('click.continue').on('click.continue', () => {
            opts.onContinueAfterSubmitted && opts.onContinueAfterSubmitted();
        });

        if (dropZoneContainer.length && !dropZoneContainer.data('dropzoned')) {
            initModalUpload(null, null, '#project-create-form');

            dropZoneContainer.data({dropzoned: true});
        }
    };

    /**
     * Relabel the modal
     */
    const relabel = () => {
        const textMap = {
            '.js-header-success-text': opts.textHeadingSuccessText,
            '.js-header-text': opts.textHeader,
            '.js-subheading-text': opts.textSubheading,
            '.js-additional-details-skip-text': opts.skipLabel,
            '.js-additional-details-submit-text': opts.submitLabel,
            '.js-quality-score-title': opts.qualityScoreTitle,
        };
        const attrMap = {
            '.js-additional-details-textarea': {type: 'placeholder', value: opts.placeholderText}
        };
        let x;
        let y;
        let cur;

        for (x in textMap) {
            $(x, additionalDetailsModal).html(textMap[x]);
        }

        for (y in attrMap) {
            cur = attrMap[y];
            $(y, additionalDetailsModal).attr(cur.type, cur.value);
        }
    };

    /**
     * Toggle the elements that are visible
     */
    const toggleVisibleElements = () => {
        $('.js-additional-details-file-section-container')[opts.allowFiles ? 'removeClass' : 'addClass']('d-none');
        $('.js-quality-score-section')[opts.showProgress ? 'removeClass' : 'addClass']('d-none');
        $('.js-quality-score-section .emojis')[opts.showProgressSmile ? 'removeClass' : 'addClass']('d-none');
    };

    /**
     * Initialise
     */
    const init = () => {
        $('.js-show-project-details', additionalDetailsModal).removeClass('d-none');
        $('.js-hide-project-details', additionalDetailsModal).addClass('d-none');

        $('#project-detail-error', additionalDetailsModal).addClass('d-none');
        $(opts.textAreaSelector, additionalDetailsModal).removeClass('has-error');

        $('.modal').modal('hide');

        relabel();
        toggleVisibleElements();

        additionalDetailsModal.modal('show').addClass('open');
        bindEvents();
        opts.onInit && opts.onInit();
    };

    init();
};
