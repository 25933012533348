export const PubSub = {
	on: function (ev, callback) {
		if (Array.isArray(ev)) {
			var pubsub = this;
			ev.forEach(function (e) {
				pubsub.on(e, callback);
			});
		} else {
			var calls = this._callbacks || (this._callbacks = {});
			(this._callbacks[ev] || (this._callbacks[ev] = [])).push(callback);
		}
		return this;
	},

	fireEvent: function () {
		var args = Array.prototype.slice.call(arguments, 0);

		var ev = args.shift();

		if (typeof window.verboseEv !== 'undefined' && window.verboseEv === true) {
			console.log(ev + ' happened');
		}

		var list, calls, i, l;

		if (!(calls = this._callbacks)) return this;
		if (!(list = this._callbacks[ev])) return this;

		for (i = 0, l = list.length; i < l; i++) list[i].apply(this, args);
		return this;
	},
};
