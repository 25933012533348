import {postBulkResponseAction, postResponseAction} from "../../components/apis/buyer-api";

export const registerResponseAction = (scope, {type, source, sellerProfileId, projectId, bpvsid}) => {
    postResponseAction(
        {type, source, sellerProfileId, projectId, bpvsid},
        (res) => {
            $(scope).trigger('actionSuccess', {type, sellerProfileId, res});
        }
    );
};

export const registerBulkResponseAction = (scope, {type, source, sellerProfileIdArray, projectId, bpvid, log_event}) => {
    postBulkResponseAction(
        {type, source, sellerProfileIdArray, projectId, bpvid, log_event},
        (res) => {
            $(scope).trigger('actionSuccess', {type, sellerProfileIdArray, res});
        },
        (error) => {
            window.bugsnagClient.notify(new Error('Failed bulk action'), {metaData: error});
            $(scope).trigger('actionFailure');
        }
    )
};

