export default function QuestionPrefills() {
    const QP = {}

    QP.checkForQuestionPrefill = (queryString, {name: questionId, type: questionType}, pageSkippedData = {}) => {
        const preselect = QP.getAnswersToBeSelected(queryString, questionId, 'confirm');
        const skipQuery = QP.getAnswersToBeSelected(queryString, questionId, 'skip');
        const skipPage = pageSkippedData[questionId]?.value || [];
        const textPrefillAnswerConfirm = QP.getTextAnswer(queryString, questionId,'confirm', questionType);
        const textPrefillAnswerSkip    = QP.getTextAnswer(queryString, questionId,'skip', questionType, pageSkippedData);
        const skip = skipQuery.concat(skipPage);

        return {
            preSelectIds: [...preselect, ...skip],
            skipQuestion: skip.length > 0 || textPrefillAnswerSkip,
            textPrefillAnswerConfirm,
            textPrefillAnswerSkip
        }
    }

    QP.getTextAnswer = (queryString, questionId, prefillType, questionType, pageSkippedData = {}) => {
        if (questionType !== 'text')
            return false;

        let answer;
        const isPageSkippedDataValue = pageSkippedData[questionId]?.value;
        const isPageSkippedDataText = isPageSkippedDataValue 
            && typeof isPageSkippedDataValue === 'string';

        // Delete the else once we drop ie11 support
        if (typeof URLSearchParams !== 'undefined') {
            const urlParams = new URLSearchParams(queryString);
            const queryPrefix = prefillType === 'skip' ? 'bmfps' : 'bmfpc'

            answer  = urlParams.get(`${queryPrefix}.${questionId}`);
        } else if(isPageSkippedDataText) {
            answer = isPageSkippedDataValue;
        } else {
            const queryPrefix = prefillType === 'skip' ? 'bmfps' : 'bmfpc'
            answer = QP.ie11GetUrlParam(`${queryPrefix}.${questionId}`);
        }

        return answer ? answer : false
    }

    QP.getAnswersToBeSelected = (queryString, questionId, prefillType) => {
        const queryPrefix = prefillType === 'skip' ? 'bmfps' : 'bmfpc'
        let selectedIds = []
        let answer;
        let answers = [];

        if (typeof URLSearchParams !== 'undefined') {
            const urlParams = new URLSearchParams(queryString);
            answer  = urlParams.get(`${queryPrefix}.${questionId}`);
            answers = urlParams.getAll(`${queryPrefix}.${questionId}[]`);
        } else {
            answer = QP.ie11GetUrlParam(`${queryPrefix}.${questionId}`);
        }

        if (answer) {
            answers = [...answers, answer]
        }

        answers.forEach((answer) => {
            selectedIds.push(answer);
        })

        return selectedIds
    }

    QP.createPayloadFromIds = (questionId, optionsChosenIds, textPrefillAnswer, questionType, otherText = '' ) => {
        let payload = {}

        if (textPrefillAnswer) {
            return {[questionId]: textPrefillAnswer};
        }

        if (optionsChosenIds.length === 1 ) {
            if (questionType === 'photocheck' || questionType === 'checkbox') {
                payload = QP.formatPhotoCheckPayload(questionId, optionsChosenIds)
            } else {
                payload = {[questionId]: optionsChosenIds[0]}
            }

        } else if (optionsChosenIds.length > 1) {
            let options = {}

            optionsChosenIds.forEach((id) => {
                options = {...options, [id]: true}
            })

            payload = {[questionId]: options}
        }
        if(otherText) {
            payload[questionId +'_other'] = otherText;
        }

        return payload
    }

    QP.formatPhotoCheckPayload = (questionId, optionsChosenIds) => {
        return {[questionId]: {[optionsChosenIds[0]]: true}}
    }

    QP.ie11GetUrlParam = (name) => {
        let results;
        try {
            results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
            if (results != null) {
                return decodeURI(results[1]) || 0;
            }
        } catch (err) {
            bugsnagClient && bugsnagClient.notify(err)
            results = null
        }

        return results;
    }

    window.QuestionPrefills = QP;

}
