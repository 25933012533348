import {_t} from "../../libs/i18next";

export default function CameraUtil() {
    let cameraVideo,
        cameraVideoStream,
        cameraContext,
        config;

    const {modalManager, Handlebars} = window;

    const cameraTemplate = Handlebars.compile(document.getElementById("camera-template").innerHTML);

    const setConfig = ({endpoint, endpointVersion, onSuccess, onError}) => {
        config = {endpoint, endpointVersion, onSuccess, onError}
    }

    const showCameraModal = () => {
        const {endpoint, endpointVersion, onSuccess, onError} = config;

        modalManager.set(
            _t('buyer_camera-modal:title'),
            "",
            cameraTemplate({}),
            [
                modalManager.cancelLinkHTML(),
                modalManager.submitButtonHTML( _t('buyer_camera-modal:add-photo-button'), true)
            ],
            ['modal-sm']
        );
        modalManager.setEvent(
            'click',
            ".camera-save",
            {},
            function (e) {
                cameraContext.drawImage(cameraVideo,
                    Math.min(0, ((cameraVideo.videoWidth - 300) / 2) * -1) * -1,
                    Math.min(0, ((cameraVideo.videoHeight - 300) / 2) * -1) * -1,
                    Math.min(300, cameraVideo.videoWidth),
                    Math.min(300, cameraVideo.videoHeight),
                    Math.min(0, ((cameraVideo.videoWidth - 300) / 2)) * -1,
                    Math.min(0, ((cameraVideo.videoHeight - 300) / 2)) * -1,
                    Math.min(300, cameraVideo.videoWidth),
                    Math.min(300, cameraVideo.videoHeight)
                );
                $('.camera-video').hide();
                $('.camera-photo').show();
                $('.camera-save-container').hide();
                $('.camera-retake-container').show();
                $('.modal-submit').prop('disabled', false);
            }
        );
        modalManager.setEvent(
            'click',
            ".camera-retake",
            {},
            function (e) {
                $('.camera-photo').hide();
                $('.camera-video').show();
                $('.camera-retake-container').hide();
                $('.camera-save-container').show();
                $('.modal-submit').prop('disabled', true);
            }
        );

        modalManager.setEvent(
            'hide.bs.modal',
            null,
            {},
            function (e) {
                cameraVideoStream.getTracks().forEach(function (track) {
                    track.stop();
                });
                cameraVideo.srcObject = null;
            }
        );
        modalManager.setEvent(
            'click',
            '.modal-submit',
            {},
            function (e) {
                let camera_picture = document.getElementById('camera-photo');
                $('#profile_picture')
                    .show()
                    .attr('src', camera_picture.toDataURL());


                cameraVideoStream.getTracks().forEach(function (track) {
                    track.stop();
                });
                cameraVideo.srcObject = null;

                modalManager.destroy();
                let formData = new FormData();
                let imageFile = (fetch($('.profile_picture').attr('src'))
                        .then(function (res) {
                            return res.arrayBuffer();
                        })
                        .then(function (buf) {
                            return new File([buf], 'image.png', {type: 'image/png'});
                        })
                ).then(function (file) {
                    formData.append('image', file);
                    formData.append('type', 'chat-avatar');
                    Bark.api(
                        endpoint,
                        formData,
                        function (data) {
                            onSuccess(data)
                        },
                        function (error) {
                            onError(error)
                        },
                        'POST',
                        endpointVersion,
                        false,
                        false,
                        false
                    );
                });
            }
        );
    }

    const showNoCameraModal = () => {
        modalManager.set(
            _t('buyer_camera-modal:no-webcam-detected'),
            '',
            // "Please check your settings and try again",
            `<div class="d-flex justify-content-center align-items-center  text-grey-600 my-0"><p class="mb-0"> ${_t('buyer_camera-modal:error.check-settings')}</p></div>`
            ,
            [
                '<div class="mx-auto">' + modalManager.submitButtonHTML(_t('buyer_camera-modal:ok-button'), false) + '</div>'
            ],
            ['modal-sm']
        );
        modalManager.setEvent(
            'click',
            '.modal-submit',
            {},
            function (e) {
                modalManager.destroy();
            }
        );
    }

    const onOpenCameraClick = () => {
        try {
            if (navigator.mediaDevices === undefined) {
                navigator.mediaDevices = {};
            }
            if (navigator.mediaDevices.getUserMedia === undefined) {
                navigator.mediaDevices.getUserMedia = function (constraints) {

                    // First get ahold of the legacy getUserMedia, if present
                    var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

                    // Some browsers just don't implement it - return a rejected promise with an error
                    // to keep a consistent interface
                    if (!getUserMedia) {
                        return Promise.reject(new Error(_t('buyer_camera-modal:error.getting-user-media')));
                    }

                    // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
                    return new Promise(function (resolve, reject) {
                        getUserMedia.call(navigator, constraints, resolve, reject);
                    });
                }
            }
            navigator.mediaDevices.getUserMedia({video: {width: 300, height: 300}})
                .then(function (stream) {
                    if (Bark.isMobile()) {
                        showNoCameraModal();
                    }
                    cameraVideoStream = stream;
                    //video.src = window.URL.createObjectURL(stream);
                    showCameraModal();
                    // Elements for taking the snapshot
                    let canvas = document.getElementById('camera-photo');
                    cameraContext = canvas.getContext('2d');
                    cameraVideo = document.getElementById('camera-video');
                    try {
                        if ("srcObject" in cameraVideo) {
                            cameraVideo.srcObject = stream;
                        } else {
                            cameraVideo.src = window.URL.createObjectURL(stream);
                        }
                    } catch (err) {
                        cameraVideo.src = stream;
                    }
                    cameraVideo.play();
                }).catch(function (err) {
                showNoCameraModal();
            });
        } catch (err) {
            showNoCameraModal();
        }
    }

    return {
        showCameraModal,
        showNoCameraModal,
        onOpenCameraClick,
        setConfig
    }
}
