import { getCreateBark } from '../../helpers/general';
import { tracking } from "../../../analytics";
import { useNamespace, _t } from '../../../../libs/i18next';

export const verifyExistingBuyerIfNeeded = async (data) => {
    const pid = data.project_id_hash;
    const cb = getCreateBark();

    await useNamespace(['buyer_login-returning']);

    determineContactVerificationStatus('phone', pid)
        .then(performSmsVerification => {
            if (performSmsVerification) {
                smsExistingBuyerVerificationCodeAfterBark(pid)
            } else {
                cb.next(true);
            }
        })
        .catch(() => {
            cb.next(true);
        });
};

const smsExistingBuyerVerificationCodeAfterBark = function (projectId) {
    const cb = getCreateBark();
    let showSmsModal = true;
    const modalIdentifier = getModalIdentifier();

    triggerContactVerification(projectId)
        .then(data => {
            if (!data.status) {
                cb.next(true);
                return;
            }
            const verificationCodeIdentifier = getVerificationCodeIdentifier();
            $(modalIdentifier + ' .sms-verification-continue').text(_t("buyer_login-returning:phone-verification.confirm-btn"));
            smsVerificationShow('sms-form');
            $(modalIdentifier + ' .sms-errors').text('').toggleClass('d-none', true);
            if (window.Bark.isMobile()) {
                $(modalIdentifier + ' .sms-verification-form .code-input').addClass('d-none');
                $(modalIdentifier + ' '+verificationCodeIdentifier).removeClass('d-none').focus();
            } else {
                $(modalIdentifier + ' '+verificationCodeIdentifier + '-0').focus();
            }
            $(modalIdentifier + ' .number-to-verify').text(data.last_four_digits);
            bindModalEvents(projectId);
            $(modalIdentifier + ' .sms-verification-buttons .sms-verification-btn-back').html('');
        })
        .catch(() => {
            submitContactVerification(projectId, '1', false);

            cb.next(true);

            showSmsModal = false;
        })
        .finally(() => {
            $('#inlineBarkModal').modal('hide');

            if (showSmsModal) {
                $(modalIdentifier).modal('show');

                tracking(
                    "Experiment",
                    "sms_login - Sms Login Modal View",
                    {
                        variant: cb.categoryData?.experiments?.sms_login?.variant,
                        category_id: $('#category_id').val(),
                        pre_bark_id: cb.preBarkId,
                        projectId: projectId
                    }
                );
            }
        });
}

const determineContactVerificationStatus = (contactType, pid) => {
    const payload = {
        identifierType: 'pid',
        identifier: pid,
        scope: 'buyer',
        contactMethodType: contactType
    };

    return new Promise((resolve, reject) => {
        Bark.api(
            'contact-verification/determine',
            payload,
            function(data) {
                if (data.status) {
                    resolve(data.perform_sms_verification);
                    return;
                }

                reject();
            },
            function() {
                reject();
            },
            'POST',
            Bark.apiVersionHeader('v2')
        );
    });
}

/**
 * Trigger contact verification
 * @param {string} projectId The hashed project ID
 * @returns {Promise}
 */
const triggerContactVerification = projectId => {
    return new Promise((resolve, reject) => {
        const payload = {
            identifierType: 'pid',
            identifier: projectId,
            scope: 'buyer',
            contactMethodType: 'phone'
        };

        Bark.api(
            'contact-verification/send-verification',
            payload,
            function(data) {
                if (data.status) {
                    resolve(data);
                } else {
                    reject(data);
                }
            },
            function(error) {
                reject(error);
            },
            'POST',
            Bark.apiVersionHeader('v2')
        );
    });
}

/**
 *
 * @param {string} projectId
 * @param {string} code
 * @param {boolean} skip
 * @param {function} successCallback
 * @param {function} failCallback
 */
const submitContactVerification = (projectId, code, skip, successCallback = null, failCallback = null) => {
    const payload = {
        identifierType: 'pid',
        identifier: projectId,
        scope: 'buyer',
        code: code,
        contactMethodType: 'phone',
        loginIfSuccessful: true
    };

    if (skip) {
        payload['skipping'] = true;
    }

    const cb = getCreateBark();

    cb.tagManager('bark-modal:tel-verify:submit');

    Bark.api(
        'contact-verification/verify/',
        payload,
        function(data) {
            if (data.status) {
                cb.tagManager('bark-modal:tel-verify:success');
                tracking(
                    "Experiment",
                    "sms_login - Sms Login Verification - Success",
                    {
                        variant: cb.categoryData?.experiments?.sms_login?.variant,
                        category_id: $('#category_id').val(),
                        pre_bark_id: cb.preBarkId,
                        projectId: projectId
                    }
                );
                successCallback && successCallback(data);
            } else {
                cb.tagManager('bark-modal:tel-verify:error');
                tracking(
                    "Experiment",
                    "sms_login - Sms Login Verification - Fail",
                    {
                        variant: cb.categoryData?.experiments?.sms_login?.variant,
                        category_id: $('#category_id').val(),
                        pre_bark_id: cb.preBarkId,
                        projectId: projectId
                    }
                );
                failCallback && failCallback(data);
            }
        },
        function(error) {
            cb.tagManager('bark-modal:tel-verify:error');

            tracking(
                "Experiment",
                "sms_login - Sms Login Verification - Fail",
                {
                    variant: cb.categoryData?.experiments?.sms_login?.variant,
                    category_id: $('#category_id').val(),
                    pre_bark_id: cb.preBarkId,
                    projectId: projectId
                }
            );
            failCallback && failCallback(error);
        },
        'POST',
        Bark.apiVersionHeader('v2')
    );
}

const smsPostBarkVerificationFailureCallback = (data) => {
    smsVerificationShow('sms-form');
    let errorText = _t("buyer_login-returning:phone-verification.error-incorrect-code-resend");
    if (data.error == 'Max attempts') {
        errorText = _t("buyer_login-returning:phone-verification.error-incorrect-code-login-manually");
    }
    $('.sms-errors').text(errorText).removeClass('d-none');
}

const smsVerificationShow = showPart => {
    const elem = $('.inline-bark-sms-phone-verification')
        .removeClass('sms-form')
        .removeClass('verify')
        .removeClass('sending')
        .removeClass('sent');

    if (showPart) {
        elem.addClass(showPart);
    }

    showPart === 'verify' ?
        $('.login-by-phone-header').addClass('d-none') : $('.login-by-phone-header').removeClass('d-none');
}

const smsPostBarkVerificationCallback = (data, projectId) => {
    smsVerificationShow(false);

    if (data.status && !data.error) {
        $(getModalIdentifier()).modal('hide');
        Bark.ENV.JWT = data.auth;
        let { blt, bli } = data;
        const projectDashboardPage = `/buyers/bark/${projectId}/?blt=${blt}&bli=${bli}`;
        window.location.replace(projectDashboardPage);
    } else {
        let errorText = _t("buyer_login-returning:phone-verification.error-incorrect-code-resend");
        smsVerificationShow('sms-form');
        $('.sms-errors').text(errorText).removeClass('d-none');
    }
}

/**
 *
 * @param successCallback
 * @param failureCallback
 * @param projectId
 * @returns {boolean}
 */
const smsVerificationSubmit = (successCallback, failureCallback, projectId) => {
    const errorsElem = $('.sms-errors');
    const codeElem = $(getVerificationCodeIdentifier());
    const validCode = codeElem.val().match(/[0-9]{4}/);
    const cb = getCreateBark();
    let code;

    errorsElem.toggleClass('d-none', true);

    if (validCode == null) {
        errorsElem.text(_t("buyer_login-returning:phone-verification.please-enter-full-code")).removeClass('d-none');
        tracking(
            "Experiment",
            "sms_login - Sms Login Verification - Fail",
            {
                variant: cb.categoryData?.experiments?.sms_login?.variant,
                category_id: $('#category_id').val(),
                pre_bark_id: cb.preBarkId,
                projectId: projectId
            }
        );
        return false;
    }

    code = codeElem.val();
    smsVerificationShow('verify');

    submitContactVerification(projectId, code, false, successCallback, failureCallback);
}

const getModalIdentifier = () => {
    return '#login-by-phone-verification-modal';
}

const getVerificationCodeIdentifier = () => {
    return '#lbp-verification-code';
}

/**
 * @param projectId
 */
const bindModalEvents = (projectId) => {
    const verificationElem = $(getVerificationCodeIdentifier());
    const modalIdentifier = getModalIdentifier();
    const cb = getCreateBark();

    verificationElem.on(
        "input keydown keyup mousedown mouseup contextmenu drop",
        function() {
            let input = $(this).val().replace( /\D+/g, '').substring(0, 4);
            $(this).val(input);
        }
    );

    verificationElem.on('keyup', function(e) {
        if (e.keyCode === 13) {
            $(modalIdentifier +' .sms-verification-continue').click();
        }
    });

    verificationElem.on('change', function() {
        let code = $(this).val().split('');

        for (let i = 0; i < code.length; i++) {
            let codeElem = $(getVerificationCodeIdentifier() + '-' + i);
            if (codeElem.val() !== code[i]) {
                codeElem.val(code[i]);
            }
        }

        if (code[3] !== 'undefined') {
            $(modalIdentifier +' .sms-verification-continue').click();
        }
    });

    $(modalIdentifier + ' .direct-login').on('click', function() {
        tracking(
            "Experiment",
            "sms_login -  Modal Login Hyperlink Click",
            {
                variant: cb.categoryData?.experiments?.sms_login?.variant,
                category_id: $('#category_id').val(),
                pre_bark_id: cb.preBarkId
            }
        );
    });

    $(modalIdentifier + ' .close-modal-cross').on('click', function() {

        $(getModalIdentifier()).modal('hide');
        tracking(
            "Experiment",
            "sms_login - Modal Cross Click",
            {
                variant: cb.categoryData?.experiments?.sms_login?.variant,
                category_id: $('#category_id').val(),
                pre_bark_id: cb.preBarkId
            }
        );
        window.location.reload();
    });

    $(modalIdentifier + ' .sms-verification-form .code-input').on(
        "keydown contextmenu drop",
        function(e) {
            let input = $(this);
            let inputIndex = $(this).data('index');

            if (!e.key) {
                return;
            }

            function updateCode() {
                let codeArray = verificationElem.val().split('');
                for (let i = 0; i < 4; i++) {
                    codeArray[i] = $(getVerificationCodeIdentifier() + '-' + i).val();
                    if (codeArray[i] === '') {
                        codeArray[i] = ' ';
                    }
                }
                verificationElem.val(codeArray.join(''));
            }

            function prevInput(inputIndex) {
                let firstInputIndex = 0;
                if (inputIndex !== firstInputIndex) {
                    setTimeout(function() {
                        input.prev().focus();
                        input.prev().select();
                    }, 0);
                }
            }

            function nextInput(inputIndex) {
                let lastInputIndex = 3;
                if (inputIndex !== lastInputIndex) {
                    setTimeout(function() {
                        input.next().focus();
                        input.next().select();
                    }, 0);
                } else {
                    $(modalIdentifier +' .sms-verification-continue').click();
                }
            }

            let arrowLeft = 37;
            if (e.keyCode === arrowLeft) {
                return prevInput(inputIndex);
            }
            let arrowRight = 39;
            if (e.keyCode === arrowRight) {
                return nextInput(inputIndex);
            }

            let enter = 13;
            if (e.keyCode === enter) {
                $(modalIdentifier + ' .sms-verification-continue').click();
                return;
            }

            let inputKey = e.key;
            if (isNaN(Number(inputKey)) || e.key === null || e.key === ' ') {
                inputKey = '';
            }

            if (e.key === '') {
                return;
            }

            $(this).val(inputKey);
            updateCode();
            if(inputKey && inputKey.length > 0) {
                nextInput(inputIndex);
            }
        }
    );

    $(modalIdentifier + ' .sms-verification-continue').off('click.verificationContinue').on('click.verificationContinue', function() {
        smsVerificationSubmit(
            function(data) { smsPostBarkVerificationCallback(data, projectId); },
            function(data) { smsPostBarkVerificationFailureCallback(data); },
            projectId
        );
    });

    $('.js-resend-code').off('click').on('click', function() {
        $('.sms-errors').text('').addClass('d-none');
        smsVerificationShow('sending');

        triggerContactVerification(projectId)
            .then(() => {
                smsVerificationShow('sms-form');
            })
            .catch(() => {
                smsVerificationShow('sms-form');
            });
    });

};

