export default function Consts() {
	window.Bark = window.Bark || {};

	/**
	 * Global JS constants
	 */
	window.Bark.consts = {
		/**
		 * Failed to get combined updates
		 * @see Bark Seller Notifications
		 */
		ERR_COMBINED_UPDATES: 'BSNE1',
		/**
		 * Failed to apply notifications
		 * @see Bark Seller Notifications
		 */
		ERR_APPLY_NOTIFICATIONS: 'BSNE2',
		/**
		 * The screen width at which to assume that the display is in mobile mode
		 */
		MOBILE_WIDTH_THRESHOLD: 1024,
		/**
		 * The screen width at which to assume that the display is in mobile mode
		 */
		MOBILE_MODAL_THRESHOLD: 640,
		/**
		 * What 1em translates to
		 */
		ONE_EM_IN_PX: 14,
		/**
		 * Project has been closed
		 */
		PROJECT_STATUS_CLOSED: 2,
		/**
		 * The seller is on a free plan
		 */
		SELLER_PLAN_TYPE_FREE: 3,
		/**
		 * The onkeypress keycode for the enter key
		 */
		KEY_CODE_ENTER: 13,
		/**
		 * The error code for when a project has too many responses
		 */
		EX_PROJECT_TOO_MANY_RESPONSES: 90101,
		/**
		 * Exception code for exceptions for when a stripe customer already exists
		 */
		EX_USER_CUSTOMER_EXISTS: 90301,
		/**
		 * Exception code for when a user is created but the email address already exists
		 */
		EX_EMAIL_EXISTS_IN_USERS: 90302,

		/**
		 * Exception code for when a user is created but the email address already exists
		 */
		EX_EMAIL_EXISTS_IN_BUYERS: 90303,

		/**
		 * Exception code for when a user is created but the email address already exists
		 */
		EX_EMAIL_EXISTS_IN_SELLERS: 90304,

		/**
		 * Exception code for exceptions for when a payment gets declined
		 */
		EX_PAYMENT_DECLINED: 90201,

		/**
		 * Exception code for exceptions for when a payment of a credit pack returns less credits
		 * than the Bark is worth
		 */
		EX_CREDITS_PURCHASE_LOW: 90202,

		/**
		 * Add a comment to this line
		 * WebSocket Chat server URL
		 */
		WS_URL: window.Bark?.consts?.WS_URL ?? 'https://ws.bark.com',

		/**
		 * Definition of S3 buckets and their distros
		 */
		S3_BUCKET_LOCATIONS: [],

		/**
		 * Google api - client id
		 */
		GOOGLE_CLIENTID:
			'574843737653-nnmb3nkvuuh9cfdiknh65fquo4fr4g0j.apps.googleusercontent.com',
	};
}
