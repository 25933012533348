export default class Select {
	constructor(containerId, elementKey, hbTemplate, selectOptions, eventEmitter) {
		this.containerId = containerId;
		this.elementKey = elementKey;
		this.hbTemplate = hbTemplate;
		this.selectOptions = selectOptions;
		this.eventEmitter = eventEmitter;
	}

	draw() {
		$(`#${this.containerId}`).empty();
		let selectHtml = this.hbTemplate({
			key: this.elementKey,
			options: this.selectOptions,
		});
		$(`#${this.containerId}`).append(selectHtml);
		this.init();
		this.bindListeners();
	}

	init() {
		let selectedOption = $(`#select_items_${this.elementKey}`).find(`.selected`);
		if (!selectedOption.length) {
			selectedOption = $(`#select_items_${this.elementKey} .select-option`).first();
		}
		this.setSelected(selectedOption);
	}

	bindListeners() {
		let that = this;

		$(`#select_selected_${this.elementKey}`)
			.off('click')
			.on('click', function (e) {
				e.preventDefault();
				e.stopPropagation();
				$(`#select_items_${that.elementKey}`).addClass('d-block');
				that.eventEmitter.emit(`select.${that.elementKey}.clicked`);
			});

		$(`#select_items_${this.elementKey} .select-option`)
			.off('click')
			.on('click', function (e) {
				e.preventDefault();
				that.setSelected($(this));
				that.eventEmitter.emit(`select.${that.elementKey}.changed`, {
					currentValue: that.getSelectedValue(),
				});
			});

		$(document).click(function () {
			$(`#select_items_${that.elementKey}`).removeClass('d-block');
		});
	}

	setSelected($option) {
		$(`#select_items_${this.elementKey}`).find(`.selected`).removeClass('selected');
		$option.addClass('selected');

		let selectText = $option.text();
		$(`#select_selected_${this.elementKey}`).empty().append(selectText);
	}

	getSelectedValue() {
		return $(`#select_items_${this.elementKey}`).find(`.selected`).data('optionValue');
	}
}
