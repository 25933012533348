import { finishNewRequestFlow } from './new-request-flow';

export function startBarkV2({
	locationData,
	category_id,
	country_id,
	campaign,
	user,
	bark_mode,
	matchedSellerHashes,
	experiments,
}) {
	const { remove, startNewRequestFlowV2 } = window.BarkRequestFlow;

	if (locationData !== null && typeof locationData === 'object') {
		if ([-1, '-1'].includes(locationData.id)) {
			locationData = null;
		}
	}
	
	return startNewRequestFlowV2({
		locationData,
		category_id,
		country_id,
		campaign,
		origin,
		bark_mode,
		matchedSellerHashes,
		hasModal: true,
		location: '#new-request-flow-widget',
		onFinish: (newRequestOutput, createBarkResponse) => {
			finishNewRequestFlow(newRequestOutput, createBarkResponse);
			remove({ location: '#new-request-flow-widget' });
		},
		onExit: () => remove({ location: '#new-request-flow-widget' }),
		barkInfo: {
			timeZone: window.Bark.ENV.tz,
		},
		siteInfo: {
			locale: window.Bark.ENV.locale,
			lang: window.Bark.ENV.lang,
			JWT: window.Bark.ENV.JWT,
		},
		experiments,
		userData: user,
	});
}
