import { sendPasswordChange } from './helpers/api';
import { validatePasswords } from './helpers/validate';
import { showAlertPassword, clearAllAlerts } from './helpers/alert';
import { showLoading, hideLoading } from './helpers/loading';
import {tracking} from "../analytics";
import { useNamespace, _t } from '../../libs/i18next';

export const changePasswordModal = () => {
	let state = {};

	/**
	 * If you want to set custom options, note that setState accepts an object of options as a parameter :)
	 * What you do with those options is up to you....           (seriously; it's up to you - I've only coded for my options)
	 * @type {{loginMethod: null, interactiveElements: {newPassInput: null, confirmPassInput: null, btnChangePassword: null, btnCloseModal: null}, shouldOnlyShowModalOnce: boolean, containerId: string, debugMode: boolean}}
	 */
	const defaultOptions = {
		debugMode: false,
		containerId: 'create-secure-password-modal',
		shouldOnlyShowModalOnce: true,
		loginMethod: null,
		interactiveElements: {
			newPassInput: null,
			confirmPassInput: null,
			btnChangePassword: null,
			btnCloseModal: null,
		},
		trackingGroup: null,
	};

	/**
	 * Initialises the modal with some (optional) options. This must be called first
	 * @param options
	 */
	const setState = (options = {}) => {
		state = { ...defaultOptions, ...options };
		debug(
			'The starting state of ChangePasswordModal [before attaching interactive elements] is: ',
			state,
		);
	};

	/**
	 * Sets and gets the state of whether the user has seen the modal before; using a delicious cookie 🍪
	 */
	const setUserHasSkippedModal = () => {
		window.Bark.set_cookie('skippedcspmodal', 1, 0, '/');
		tracking(state.trackingGroup, 'create-password-modal-skip');
	};

	const setUserHasFinishedModal = () => {
		window.Bark.set_cookie('finishedcspmodal', 1, 0, '/');
	};

	/**
	 * This is a javascript function which causes the modal to be shown on the user's screen
	 */
	const showModal = () => {
		$(`#${state.containerId}`).modal('show');
		tracking(state.trackingGroup, 'create-password-modal-open');
	};

	/**
	 * This function is another corker; it ... wait for it ... *hides* the modal!
	 */
	const hideModal = () => {
		$(`#${state.containerId}`).modal('hide');
	};

	/**
	 * defineInteractiveElements - just lets us only have to do selectors in one place.
	 * Bails if any of the selectors don't result in a jquery object with a non-zero length
	 * @returns {boolean}
	 */
	const defineInteractiveElements = () => {
		const elements = state.interactiveElements;
		elements.btnChangePassword = $(
			`#${state.containerId} .js-create-secure-password-submit`,
		);
		elements.btnCloseModal = $(
			`#${state.containerId} .js-dismiss-create-secure-password-modal`,
		);
		elements.newPassInput = $(`#${state.containerId} .js-new-password`);
		elements.confirmPassInput = $(`#${state.containerId} .js-confirm-new-password`);

		// Check they all have a length, if not, something has fucked up and we need to bail.
		let basicExistenceCheckSuccess = true;

		for (const element in elements) {
			if (element.length < 1) {
				debug(
					'Tried to find ' + element.toString() + " but jQuery said it wasn't there :(  ",
					element,
				);
				basicExistenceCheckSuccess = false;
			}
		}
		return basicExistenceCheckSuccess;
	};

	/**
	 * Binds click handlers to the submit and close buttons.
	 * Handling functions are sold separately
	 */
	const bindClickHandlers = () => {
		const $el = state.interactiveElements;
		$el.btnChangePassword.off('click.changepass').on('click.changepass', async (e) => {
			e.preventDefault();
			tracking(state.trackingGroup, 'create-password-modal-submit');
			await submitApiPasswordChangeRequest();
		});

		$el.btnCloseModal.off('click.close').on('click.close', (e) => {
			debug('ChangePasswordModal close clicked, about to hide and set skipped');
			hideModal();
			setUserHasSkippedModal();
		});
	};

	/**
	 * Binds change handlers to the new pass and confirm pass inputs
	 * You might think that we use these on the frontend to do validation, but you'd be wrong
	 * This is because the API does validation, and we don't wanna have two sets of rules in play
	 * Think: we change the API to need 12 char passwords, but the frontend still thinks 8 char passwords are fine.
	 */
	const bindChangeHandlers = () => {
		const $el = state.interactiveElements;
		$el.newPassInput.off('change.changepass').on('change.changepass', function () {
			tracking(state.trackingGroup, 'create-password-modal-enter-password', {
				inputField: 'Choose Password',
			});
		});

		$el.confirmPassInput.off('change.changepass').on('change.changepass', function () {
			tracking(state.trackingGroup, 'create-password-modal-enter-password', {
				inputField: 'Confirm Password',
			});
		});
	};

	/**
	 * something something promise.resolve
	 * The actual API code will be dealt with elsewhere, leveraging our barkapi module.
	 * @returns {Promise<void>}
	 */
	const submitApiPasswordChangeRequest = async () => {
		const passwordElement = state.interactiveElements.newPassInput;
		const confirmPasswordElement = state.interactiveElements.confirmPassInput;
		const btnSubmitElement = state.interactiveElements.btnChangePassword;
		clearAllAlerts([passwordElement, confirmPasswordElement]);
		const password = passwordElement.val();
		const confirmPassword = confirmPasswordElement.val();
		const error = await validatePasswords(password, confirmPassword);
		if (!error) {
			showLoading(btnSubmitElement);
			const response = await sendPasswordChange(password);
			if (response?.status) {
				tracking(state.trackingGroup, 'create-password-modal-success');
				setUserHasFinishedModal();
				hideModal();
				hideLoading(btnSubmitElement);
			} else {
				const apiError = await getErrorMessageFromApiResponse(response);
				showAlertPassword(passwordElement, apiError);
				hideLoading(btnSubmitElement);
			}
		} else {
			showAlertPassword(
				error.field === 'confirm-password' ? confirmPasswordElement : passwordElement,
				error.error,
			);
		}
	};

	// I might use you but will probably not, enjoy living in this repo while it lasts
	const setRequestData = (request) => {
		state.request = request;
	};

	const getErrorMessageFromApiResponse = async (response) => {
		await useNamespace('common_change-password');
		const genericErrorMessage = _t('common_change-password:errors.generic');

		if (response?.error?.errors) {
			return Object.values(response.error.errors)[0] ?? genericErrorMessage;
		}

		if (response?.errors) {
			return response?.errors[0]?.msg ?? genericErrorMessage;
		}

		return genericErrorMessage;
	};

	/**
	 * A mini wrapper around console.debug which you can sprinkle liberally through this function;
	 * however it will only write to the log if state.debugMode is true.
	 * @param message
	 * @param data
	 * @returns {boolean}
	 */
	const debug = (message = '', data = {}) => {
		if (state.debugMode) {
			console.debug(message, data);
		}
		return false;
	};

	return {
		setState,
		defineInteractiveElements,
		showModal,
		setRequestData,
		bindClickHandlers,
		bindChangeHandlers,
	};
};

